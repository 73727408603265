import React, { useCallback, useEffect, useState } from "react";
import { StyleSheet } from "../../../StyleSheet";
import { useDispatch, useSelector } from "react-redux";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { setAccount } from "../../../store/slices/account";
import _ from "lodash";
import { cancelSchedulersTransactions, getAccounts } from "../../../methods/axiosMethods";
import { AccountResponse, CancelTransferRequest, DispersionData, GenericResponse, Institution, SchedulerResponse, Status, TableParams } from "../../../types/types";
import { AxiosResponse } from "axios";
import { Col, Row, Table, Typography } from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import ModalConfirmation from "../../../components/ModalConfirmation";
import TransferModalImport from "../immediate/TransferModalImport";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { getJsDateFromExcel } from "../../../functions/Functions";
import { subMenusOfTransfers } from "../../../commons/subMenus";
import SubDefaultLayout from "../../../components/SubDefaultLayout";
import CardDashboard from "../../../components/CardDashboard";
import { image } from "../../../commons/ImageRoutes";
import Buttons from "../../../components/Buttons";
import Searcher from "../components/Searcher";
import ChangeAccountCard from "./components/ChangeAccountCard";
import GenericModal from "../../../components/GenericModal";
import SelectInput from "../../../components/SelectInput";
import ModalNotification from "../../../components/ModalNotification";

const Text = Typography;

const SchedulerMain = () => {
    const MXNCurrency = new Intl.NumberFormat('es-MX', { style: "currency", currency: "MXN" });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const accountsInfo = useSelector((state: any) => state.account.accountsInfo);
    const [ownAccountsList, setOwnAccountsList] = React.useState<Array<AccountResponse>>([]);
    const [currentAccount, setCurrentAccount] = useState<AccountResponse>();
    const [gettingAccounts, setGettingAccounts] = useState<number>(0);
    const [gettingSchedulersDispersions, setGettingSchedulersDispersions] = useState<number>(0);
    const [message, setMessage] = useState<string>("");
    const [notificationType, setNotificationType] = useState<"INFO" | "SUCCESS" | "ERROR">("SUCCESS");
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [openModalImport, setOpenModalImport] = React.useState<boolean>(false);
    const [tryDelete, setTryDelete] = useState<boolean>(false);
    const [accountNumber, setAccountNumber] = useState<string>("");
    const [accountNumberTmp, setAccountNumberTmp] = useState<string>("");
    const [schedulersDispersions, /*setSchedulersDispersions*/] = useState<Array<SchedulerResponse>>([]);
    const [schedulersDispersionsTmp, setSchedulersDispersionsTmp] = useState<Array<SchedulerResponse>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showChangeAccountModal, setShowChangeAccountModal] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
            showSizeChanger: false,
        },
    });

    const columns: ColumnsType<SchedulerResponse> = [
        {
            title: "Acciones",
            dataIndex: "operation",
            key: "operation",
            width: "15%",
            className: 'custom-header',
            align: "center",
            render: (event, record: SchedulerResponse) => (
                <div>
                    <Buttons
                        type="primary"
                        color="#3d3dff"
                        icon={<EditOutlined />}
                        action={() => navigate(`/transfers/scheduled/${record.id}`)}
                        title="Editar"
                    />
                </div>
            ),
        },
        {
            title: "Cuenta Origen",
            dataIndex: "dispersionData",
            key: "dispersionData",
            align: "center",
            className: 'custom-header',
            render: (data: DispersionData) => data.accountNumber
        },
        {
            title: "Cuenta Destino",
            dataIndex: "dispersionData",
            key: "dispersionData",
            align: "center",
            className: 'custom-header',
            render: (data: DispersionData) => data.foreignAccountNumber
        },
        {
            title: 'Concepto',
            dataIndex: "dispersionData",
            key: "dispersionData",
            className: 'custom-header',
            render: (data: DispersionData) => data.paymentConcept
        },
        {
            title: "Importe sin IVA",
            dataIndex: "dispersionData",
            key: "dispersionData",
            className: 'custom-header',
            render: (data: DispersionData) => MXNCurrency.format(data.balance)
        },
        {
            title: "IVA",
            dataIndex: "dispersionData",
            key: "dispersionData",
            className: 'custom-header',
            render: (data: DispersionData) => MXNCurrency.format(data.iva)
        },
        {
            title: "Fecha y Hora",
            align: "center",
            dataIndex: "dispersionData",
            key: "dispersionData",
            className: 'custom-header',
            render: (data: DispersionData) => moment(data.date).format("DD/MM/YYYY HH:mm")
        },
    ];

    const headerSearcher = (text: string) => {
        const filteredResults = schedulersDispersions.filter(
            (data: SchedulerResponse) =>
                data.dispersionData.accountNumber.toLowerCase().includes(text.toLowerCase()) ||
                data.dispersionData.paymentConcept.toLowerCase().includes(text.toLowerCase())
        );
        setSchedulersDispersionsTmp(filteredResults);
    };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleTableChange: TableProps<SchedulerResponse>["onChange"] = (
        pagination,
    ) => {
        setTableParams({
            pagination,
        });
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setSchedulersDispersionsTmp([]);
        }
    };

    const cancelSchedulers = () => {
        setTryDelete(false);

        if (_.isEmpty(selectedRowKeys)) {
            setMessage("Antes de cancelar una o múltiples transferencias se deben de seleccionar.");
            setNotificationType("INFO");
            setShowNotification(true);
            return;
        }

        let request: CancelTransferRequest = {
            idsTransfersToDelete: []
        };

        selectedRowKeys.forEach((key) => {
            request.idsTransfersToDelete.push(key.toString());
        });
        setSelectedRowKeys([]);
        cancelSchedulersTransactions(
            request
        ).then((response: GenericResponse<void>) => {
            setNotificationType((response.status === Status.DELETED) ? "SUCCESS" : "ERROR");
            setMessage(response.message);
            setShowNotification(true);
            schedulersTransactions(accountNumber);
        }).catch(() => {
            setMessage("Ocurrió un error inesperado al intentar cancelar las transferencias.");
            setNotificationType("ERROR");
            setShowNotification(true);
        });
    };

    const getAccountsFromUser = useCallback(async () => {
        setGettingAccounts(0);
        await getAccounts()
            .then((response: AxiosResponse<GenericResponse<Array<AccountResponse>>>) => {
                if (response.data.data && response.data.status === Status.OK &&
                    !_.isEmpty(response.data.data)) {
                    setOwnAccountsList(response.data.data);
                    dispatch(setAccount({ accountsInfo: response.data.data }));
                    setGettingAccounts(1);
                }
                else {
                    setMessage("No hay cuentas disponibles para mostrar.");
                    setGettingAccounts(2);
                }
            }).catch(() => {
                setGettingAccounts(2);
                setMessage("Ocurrió un error inesperado al intentar obtener los números de cuentas asociadas.");
            });
    }, [dispatch])

    const schedulersTransactions = async (account: string) => {
        setGettingSchedulersDispersions(0);
        if (account !== "") {
            // TODO: Validar una vez exista el metodo
            // await getSchedulersTransactions(
            //     account, ShceduledDispersionStatus.SCHEDULED
            // ).then((response: GenericResponse<Array<SchedulerResponse>>) => {
            //     if (response.status === Status.OK) {
            //         setSchedulersDispersions(response.data);
            //         setSchedulersDispersionsTmp(response.data);
            //         setGettingSchedulersDispersions(1);
            //     }
            //     else {
            //         setMessage(response.message);
            //         setGettingSchedulersDispersions(2);
            //     }
            // }).catch(() => {
            //     setMessage(
            //         "Ocurrió un error inesperado al momento de obtener la lista de transferencias programadas."
            //     );
            //     setGettingSchedulersDispersions(2);
            // });
            // TODO: Esto se remueve tras haber validado lo anterior...
            setGettingSchedulersDispersions(1);
        }
    };

    const validateData = (objectToImport: any) => {
        let transferList: any[] = [];
        let messageErrors = "";
        const numbersOnlyRegex = /^\d+$/;
        if (_.isEmpty(objectToImport)) {
            messageErrors = "La plantilla no contiene datos de transferencias.";
        }
        else {
            objectToImport.forEach((elem: any, index: number) => {
                let hasError = false;
                if (
                    elem.date == null || elem.date === undefined || elem.date === "" ||
                    elem.hour == null || elem.hour === undefined || elem.hour === "" ||
                    elem.accNumberOrigin == null || elem.accNumberDest == null ||
                    elem.accTypeDest == null || elem.amountDest == null ||
                    elem.bankNameDest == null ||
                    elem.nameDest == null
                ) {
                    hasError = true;
                }
                let dateFromInteger = getJsDateFromExcel(elem.date);
                let dateFormated = dateFromInteger;
                const hours = Math.floor(elem.hour * 24); // Obtiene las horas (parte entera)
                const minutes = Math.round((elem.hour * 24 - hours) * 60); // Obtiene los minutos
                // Formatea la hora y los minutos en una cadena
                const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
                elem.date = dateFormated;
                const momentObject = moment(formattedTime, 'HH:mm');
                // Convertir el objeto dayjs a un objeto Date
                elem.hour = momentObject.toDate();
                // Verificar otros campos obligatorios
                if (elem.referenceDest && elem.referenceDest !== null) {
                    if (elem.referenceDest.toString().length > 7) {
                        hasError = true;
                    }
                    if (!numbersOnlyRegex.test(elem.referenceDest)) {
                        hasError = true;
                    }
                }
                if (hasError) {
                    messageErrors = `El registro ${index + 1} tiene campos obligatorios inválidos.\n`;
                }
                else {
                    let objImported = {
                        key: index.toString(),
                        accNumberOrigin: elem.accNumberOrigin,
                        accNumberDest: elem.accNumberDest,
                        accTypeDest: elem.accTypeDest === "Tarjeta de Debito" ?
                            "DEBIT" : elem.accTypeDest === "Teléfono celular" ?
                                "CEL" : "CLABE",
                        nameDest: elem.nameDest.toString(),
                        rfcDest: elem.rfcDest ? elem.rfcDest.toString() : "",
                        bankNameDest: Institution[elem.bankNameDest as keyof typeof Institution],
                        conceptDest: elem.conceptDest.toString(),
                        referenceDest: elem.referenceDest,
                        amountDest: elem.amountDest.toString(),
                        amountIva: elem.amountIva.toString(),
                        date: elem.date,
                        hour: elem.hour
                    }
                    transferList.push(objImported)
                }
            });
        }
        if (!_.isEmpty(messageErrors)) {
            setNotificationType('ERROR');
            setMessage(messageErrors);
            setShowNotification(true);
        }
        else {
            navigate("/transfers/scheduled-imported", { state: transferList })
        }
    };

    useEffect(() => {
        if (_.isEmpty(accountsInfo)) {
            getAccountsFromUser();
        }
        else {
            setGettingAccounts(1);
            setOwnAccountsList(accountsInfo);
        }
    }, [getAccountsFromUser, accountsInfo]);

    useEffect(() => {
        switch (gettingAccounts) {
            case 1:
                if (accountsInfo.length > 0) {
                    setAccountNumber(accountsInfo[0].accountNumber);
                    schedulersTransactions(accountsInfo[0].accountNumber);
                    setCurrentAccount(accountsInfo[0]);
                }
                else {
                    setGettingSchedulersDispersions(1);
                }
                break;
            case 2:
                setGettingSchedulersDispersions(1);
                setIsLoading(false);
                setNotificationType("ERROR");
                setShowNotification(true);
                break;
            default: setIsLoading(true); break;
        }
    }, [gettingAccounts, accountsInfo]);

    useEffect(() => {
        switch (gettingSchedulersDispersions) {
            case 1:
                setIsLoading(false);
                break;
            case 2:
                setIsLoading(false);
                setNotificationType("ERROR");
                setShowNotification(true);
                break;
            default:
                setIsLoading(true);
                break;
        }
    }, [gettingSchedulersDispersions]);

    return (
        <SubDefaultLayout childrenSubMenu={subMenusOfTransfers} title="Transferencias Programadas">
            <Row gutter={[15, 25]}>
                <Col span={24}>
                    <Row gutter={[10, 10]}>
                        <Col xs={24} xl={10} xxl={8} style={{ marginTop: 10 }}>
                            <ChangeAccountCard
                                loading={isLoading}
                                accountName={currentAccount?.name}
                                accountInfo={`STP | ${currentAccount?.accountNumber}`}
                                onClickChange={() => setShowChangeAccountModal(true)}
                            />
                        </Col>
                        <Col xs={12} xl={4}>
                            <CardDashboard
                                title={"Programar Transferencia"}
                                icon={image.transferSchIcon}
                                action={`/transfers/scheduled/new`}
                                height={130}
                            />
                        </Col>
                        <Col xs={12} xl={4}>
                            <CardDashboard
                                title={"Subir Plantilla de Transferencias"}
                                icon={image.transferTemplateIcon}
                                isFunction={() => setOpenModalImport(true)}
                                height={130}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Text style={styles.textStyle}>
                        Transferencias Programadas
                    </Text>
                </Col>
                <Col span={24}>
                    <Row style={styles.mainTableContainer} gutter={[0, 30]}>
                        <Col span={24}>
                            <Row>
                                <Col xs={14} sm={16} md={14} xl={10}>
                                    <Searcher
                                        placeholder="Buscar"
                                        onSearch={(value) => headerSearcher(value)}
                                    />
                                </Col>
                                <Col xs={4} sm={2} md={6} xl={12} />
                                <Col xs={6} sm={6} md={4} xl={2}>
                                    <Row justify={"end"}>
                                        <Buttons
                                            title="Borrar"
                                            type="primary"
                                            color="#765CE9"
                                            isDisabled={_.isEmpty(selectedRowKeys) ? true : false}
                                            icon={<CloseOutlined />}
                                            action={() => setTryDelete(true)}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Table
                                bordered
                                rowSelection={rowSelection}
                                rowKey={(record) => record.id}
                                dataSource={schedulersDispersionsTmp}
                                pagination={tableParams.pagination}
                                columns={columns}
                                onChange={handleTableChange}
                                scroll={{ y: 400, x: 700 }}
                                size="small"
                                locale={{ emptyText: "No hay datos disponibles" }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <ModalConfirmation
                open={tryDelete}
                handleConfirm={cancelSchedulers}
                handleClose={() => setTryDelete(false)}
                message={"¿Estas seguro que deseas eliminar las transferencias programadas seleccionadas?"}
            />
            <TransferModalImport
                isScheduler={true}
                openModal={openModalImport}
                handleClose={() => setOpenModalImport(false)}
                handleImportTransfer={validateData}
                isLoadingSpin={isLoading}
            />
            <GenericModal
                showActionButtons
                show={showChangeAccountModal}
                title="Cambiar de Cuenta"
                onConfirm={() => {
                    setAccountNumber(accountNumberTmp);
                    setCurrentAccount(ownAccountsList[
                        ownAccountsList.findIndex(elem => elem.accountNumber === accountNumberTmp)
                    ]);
                    schedulersTransactions(accountNumberTmp);
                    setShowChangeAccountModal(false);
                }}
                onClose={() => setShowChangeAccountModal(false)}
            >
                <Row style={{ padding: 20 }}>
                    <Col span={24}>
                        <SelectInput
                            label="Seleccione una cuenta"
                            value={accountNumberTmp}
                            options={ownAccountsList.map(data => ({
                                value: data.accountNumber,
                                label: (data.alias + " | " + data.name + " | " + data.accountNumber)
                            }))}
                            onChange={(value) => setAccountNumberTmp(value)}
                        />
                    </Col>
                </Row>
            </GenericModal>
            <ModalNotification
                show={showNotification}
                alertType={notificationType}
                title="Transferencias Programadas"
                message={message}
                onClose={() => setShowNotification(false)}
            />
        </SubDefaultLayout>
    );
};

const styles = StyleSheet.create({
    mainTableContainer: {
        padding: 30,
        borderRadius: 10,
        boxShadow: "0px 0px 8px #00000029",
        backgroundColor: "#ffffff",
    },
    textStyle: {
        marginLeft: "20px",
        fontSize: 18,
        color: "#000089",
        fontWeight: "bold"
    },
});

export default SchedulerMain;
