import { Row, Col, Typography, Table, Tag } from "antd";
import { StyleSheet } from "../../../StyleSheet";
import { BankTransaction, SchedulerResponse, ShceduledDispersionStatus } from "../../../types/types";
import 'moment/locale/es';
import { useEffect } from "react";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import dayjs from "dayjs";
const { Text } = Typography;

interface Props {
    listTransfer: SchedulerResponse[];
}

const formatAmount = Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    useGrouping: true,
    minimumFractionDigits: 2,
    notation: 'standard'
});

const SchedulerListResume = (props: Props) => {
    const { listTransfer } = props;
    const [transferListResume, setTransferListResume] = React.useState<Array<BankTransaction>>([]);
    const banksList = useSelector((state: any) => state.institutions.institutionsInfo);

    const defaultColumns: (ColumnsType<BankTransaction>[number])[] = [
        {
            title: "Estatus",
            dataIndex: "status",
            key: "status",
            render: (transferStatus: string) => (
                <Tag
                    color={
                        transferStatus === ShceduledDispersionStatus.SCHEDULED ? "green" :
                            transferStatus === ShceduledDispersionStatus.DONE ? "blue" :
                                transferStatus === ShceduledDispersionStatus.REJECTED ? "red" : "yellow"
                    }
                >
                    {
                        transferStatus === ShceduledDispersionStatus.SCHEDULED ? "Programada" :
                            transferStatus === ShceduledDispersionStatus.DONE ? "Realizada" :
                                transferStatus === ShceduledDispersionStatus.REJECTED ? "Rechazada" : "Eliminada"
                    }
                </Tag>
            )
        },
        {
            title: 'Fecha de aplicación',
            dataIndex: 'schedulerDate',
            render: (text) => dayjs(text).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: 'Cuenta Origen',
            dataIndex: 'accountNumber',
        },
        {
            title: 'Cuenta Destino',
            dataIndex: 'foreignAccountNumber',
        },
        {
            title: 'Tipo',
            dataIndex: 'accountType',
        },
        {
            title: 'Nombre',
            dataIndex: 'name',
        },
        {
            title: 'Entidad',
            dataIndex: 'foreignBankId',
        },
        {
            title: 'Concepto',
            dataIndex: 'paymentConcept',
        },
        {
            title: 'Referencia Numérica',
            dataIndex: 'reference',
        },
        {
            title: 'Importe sin Iva',
            dataIndex: 'balance',
        },
        {
            title: 'IVA',
            dataIndex: 'iva',
        },
        {
            title: 'Información',
            dataIndex: 'details',
            render: (detail: string) => (
                detail ? detail : "Programada correctamente."
            )
        }
    ];

    useEffect(() => {
        let resumeTransferList: any[] = [];
        if (listTransfer && banksList) {
            listTransfer.forEach((value) => {
                let bankObj = _.find(banksList, { bankCode: value.dispersionData.foreignBankId });

                let transferData = {
                    accountNumber: value.dispersionData.accountNumber,
                    foreignAccountNumber: value.dispersionData.foreignAccountNumber,
                    accountType: value.dispersionData.accountType === 40 ? "CLABE" :
                        value.dispersionData.accountType === 3 ? "Tarjeta de Debito" : "Teléfono celular",
                    name: value.dispersionData.name,
                    foreignBankId: bankObj.bankName,
                    paymentConcept: value.dispersionData.paymentConcept,
                    reference: value.dispersionData.reference,
                    balance: formatAmount.format(value.dispersionData.balance),
                    iva: formatAmount.format(value.dispersionData.iva),
                    schedulerDate: value.dispersionData.schedulerDate,
                    status: value.status,
                    details: value.errorDescription
                }
                resumeTransferList.push(transferData);
            });
            setTransferListResume(resumeTransferList);
        }
    }, [listTransfer, banksList]);

    return (
        <>
            <div style={styles.confirmationRowRoot}>
                <div style={styles.cardRootView}>
                    <div style={styles.cardFirstText}>
                        <Text style={styles.cardRootText}>Transferencias programadas</Text>
                    </div>
                </div>
            </div>
            <Row gutter={[16, 12]}>
                <Col xs={24} style={{ marginTop: 10 }}>
                    <Table
                        locale={{ emptyText: "No hay datos disponibles" }}
                        rowKey={(record) => record.trackingKey}
                        bordered
                        dataSource={transferListResume}
                        columns={defaultColumns}
                        scroll={{ y: 400, x: 2300 }}
                        size="small"
                    />
                </Col>
            </Row>
        </>
    );
};



const styles = StyleSheet.create({
    confirmationRowRoot: {
        backgroundColor: '#f4e5fc',
        borderRadius: 10,
        marginBottom: 15
    },
    cardRootView: {
        display: 'flex',
        flexDirection: 'row',
        height: "60px",
        alignItems: 'center',
    },
    cardFirstText: {
        width: "50%",
        marginLeft: 20
    },
    cardRootText: {
        color: '#7c28a2',
        fontWeight: 'bold',
        fontSize: 14
    },
});

export default SchedulerListResume;
