import { Row, Col, Typography, Table, Tag } from "antd";
import { StyleSheet } from "../../../StyleSheet";
import { AccountType, MultipleDispersionResponse, State, TransactionResume, TransactionType } from "../../../types/types";
import 'moment/locale/es';
import { useEffect } from "react";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { PrinterOutlined } from "@ant-design/icons";
import Buttons from "../../../components/Buttons";
const { Text } = Typography;

interface Props {
    listTransfer: MultipleDispersionResponse | undefined;
    printReceipt: (transaction: TransactionResume) => void;
}

const formatAmount = Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    useGrouping: true,
    minimumFractionDigits: 2,
    notation: 'standard'
});

const TransferListResume = (props: Props) => {
    const { listTransfer, printReceipt } = props;
    const [transferListResume, setTransferListResume] = React.useState<Array<TransactionResume>>([]);
    const [transactionDate, setTransactionDate] = React.useState<Date>();
    const banksList = useSelector((state: any) => state.institutions.institutionsInfo);

    const defaultColumns: (ColumnsType<TransactionResume>[number])[] = [
        {
            title: "Acciones",
            dataIndex: "operation",
            key: "operation",
            className: 'custom-header',
            render: (event, record) => {
                return (record.status === State.DONE || record.status === State.PROCESSING) ? (
                    < Row gutter={[16, 12]} >
                        <Col >
                            <Buttons
                                type="default"
                                title="Imprimir"
                                color="#fcba50"
                                icon={<PrinterOutlined />}
                                action={() => printReceipt(record)}
                            />
                        </Col>
                    </Row >
                ) : (
                    <Text>NO HAY ACCIONES DISPONIBLES</Text>
                )
            },
        },
        {
            title: "Estatus",
            dataIndex: "status",
            key: "status",
            className: 'custom-header',
            render: (status: string) => (
                <Tag
                    color={status === "DONE" || status === "PROCESSING" ? "green" : "red"}
                >
                    {status === "DONE" || status === "PROCESSING" ? "En curso" : "Rechazada"}
                </Tag>
            )
        },
        {
            title: 'Clave de Rastreo',
            className: 'custom-header',
            render: (record: TransactionResume) => record.trackingKey
        },
        {
            title: 'Cuenta Origen',
            dataIndex: 'sourceAccountNumber',
            className: 'custom-header',
        },
        {
            title: 'Cuenta Destino',
            dataIndex: 'receiverAccountNumber',
            className: 'custom-header',
        },
        {
            title: 'Tipo',
            dataIndex: 'accountType',
            className: 'custom-header',
            render: (record: AccountType) => (
                <Text>
                    {labelChipAccountType(record)}
                </Text>
            )
        },
        {
            title: 'Nombre',
            dataIndex: 'receiverName',
            className: 'custom-header',
        },
        {
            title: 'RFC',
            dataIndex: 'rfcOrCurp',
            className: 'custom-header',
        },
        {
            title: 'Entidad',
            dataIndex: 'receiverBank',
            className: 'custom-header',
        },
        {
            title: 'Concepto',
            dataIndex: 'paymentConcept',
            className: 'custom-header',
        },
        {
            title: 'Referencia Numérica',
            dataIndex: 'reference',
            className: 'custom-header',
        },
        {
            title: 'Importe sin Iva',
            className: 'custom-header',
            render: (record: TransactionResume) => record.transactionType === TransactionType.INCOMING ?
                formatAmount.format(record.incoming) : formatAmount.format(record.outcoming)
        },
        {
            title: 'IVA',
            className: 'custom-header',
            render: (record: TransactionResume) => formatAmount.format(record.iva)
        },
        {
            title: 'Detalles',
            dataIndex: 'description',
            className: 'custom-header',
        },
    ];

    const labelChipAccountType = (element: string | undefined) => {
        switch (element) {
            case "CLABE":
                return "CLABE";
            case 'DEBIT':
                return "Tarjeta de Debito";
            case 'CEL':
                return "Teléfono celular";
            default: {
                return '';
            }
        }
    };

    useEffect(() => {
        let resumeTransferList: any[] = [];
        if (listTransfer) {
            if (listTransfer.dispersionsWithError) {
                listTransfer.dispersionsWithError.forEach((value) => {
                    let bankObj = _.find(banksList, { bankCode: value.dispersionDataFailed.foreignBankId });
                    let transferFailed: TransactionResume = {
                        accountType: value.dispersionDataFailed.accountType === 40 ? AccountType.CLABE :
                            value.dispersionDataFailed.accountType === 3 ? AccountType.DEBIT : AccountType.CEL,
                        date: value.dispersionDataFailed.date,
                        sourceBank: "",
                        sourceAccountNumber: value.dispersionDataFailed.accountNumber,
                        sourceName: "",
                        paymentConcept: value.dispersionDataFailed.paymentConcept,
                        receiverBank: bankObj.bankName,
                        receiverAccountNumber: value.dispersionDataFailed.foreignAccountNumber,
                        receiverName: value.dispersionDataFailed.name,
                        rfcOrCurp: value.dispersionDataFailed.rfcOrCurp ? value.dispersionDataFailed.rfcOrCurp : "",
                        trackingKey: "",
                        transactionType: TransactionType.OUTCOMING,
                        outcoming: value.dispersionDataFailed.balance,
                        incoming: 0,
                        iva: value.dispersionDataFailed.iva,
                        status: "",
                        reference: value.dispersionDataFailed.reference.toString(),
                        description: value.cause.message,
                        username: "",
                        formatted: ""
                    }
                    setTransactionDate(value.dispersionDataFailed.date);
                    resumeTransferList.push(transferFailed);
                });
            }
            if (listTransfer.successDispersions) {
                listTransfer.successDispersions.forEach((value) => {
                    resumeTransferList.push(value.transactionResume);
                    setTransactionDate(value.transactionResume.date);
                });
            }
            setTransferListResume(resumeTransferList);
        }
    }, [banksList, listTransfer]);

    const anyTransactionInProgress = transferListResume.some(
        (transaction) => transaction.status === 'DONE' || transaction.status === 'PROCESSING'
    );

    const anyTransactionRejected = transferListResume.some(
        (transaction) => transaction.status !== 'DONE' && transaction.status !== 'PROCESSING'
    );

    return (
        <>
            <div style={{
                ...styles.confirmationRowRoot,
                backgroundColor: (anyTransactionInProgress ? '#f4e5fc' : anyTransactionRejected ? '#fff1f0' : '#f4e5fc')
            }}
            >
                <Row style={styles.cardRootView}>
                    <Col xs={24} xl={15} style={styles.cardFirstText}>
                        <Text style={{
                            ...styles.cardRootText,
                            color: (anyTransactionInProgress ? '#7c28a2' : anyTransactionRejected ? '#df4c57' : '#7c28a2')
                        }}>
                            {anyTransactionInProgress
                                ? 'Transferencias en curso'
                                : anyTransactionRejected
                                    ? 'Transferencias rechazadas'
                                    : 'Transferencias realizadas en curso'}
                        </Text>
                        <br />
                        <Text style={{
                            ...styles.cardRootText,
                            color: (anyTransactionInProgress ? '#7c28a2' : anyTransactionRejected ? '#df4c57' : '#7c28a2')
                        }}>
                            {`Fecha y Hora: ${transactionDate}`}
                        </Text>
                    </Col>
                </Row>
            </div>
            <Row gutter={[16, 12]}>
                <Col xs={24} style={{ marginTop: 10 }}>
                    <Table
                        locale={{ emptyText: "No hay datos disponibles" }}
                        rowKey={(record) => record.trackingKey}
                        bordered
                        dataSource={transferListResume}
                        columns={defaultColumns}
                        scroll={{ y: 400, x: 2300 }}
                        size="small"
                    />
                </Col>
            </Row>
        </>
    );

};

const styles = StyleSheet.create({
    confirmationRowRoot: {
        borderRadius: 10,
        marginBottom: 15
    },
    cardRootView: {
        display: 'flex',
        flexDirection: 'row',
        height: "60px",
        alignItems: 'center',
    },
    cardFirstText: {
        width: "50%",
        marginLeft: 20
    },
    cardRootText: {
        fontWeight: 'bold',
        fontSize: 14
    },
});

export default TransferListResume;