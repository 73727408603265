import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./types/routes";
import React, { useEffect } from 'react';
import IdleTimer from './utils/IdleTimer';
import keycloak from './context/keycloak';
import AlertDialog from "./components/AlertDialog";
import DefaultLayout from "./components/DefaultLayout";

const App = () => {
  const [isTimeout, setIsTimeout] = React.useState(false);

  useEffect(() => {
    const timer = new IdleTimer({
      // Tiempo en segundos para deslogueo por timeout
      timeout: 300,
      onTimeout: () => {
        setIsTimeout(true);
      },
      onExpired: () => {
        setIsTimeout(true);
      }
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  useEffect(() => {
    if (isTimeout) {
      keycloak.logout();
    }
  }, [isTimeout]);

  return (
    <BrowserRouter>
      <DefaultLayout currentPage={""}>
        <Routes>
          {routes.map((item) => (
            <Route path={item.path} element={item.element} />
          ))}
        </Routes>
      </DefaultLayout>
      <AlertDialog />
    </BrowserRouter>
  );
};

export default App;
