//---------------------------------------------------------------------------------------------------------------------- 
import { Col, Row, Spin, Table, TableProps } from "antd";
import { StyleSheet } from "../../../StyleSheet";
import { useCallback, useEffect, useState } from "react";
import { AccountData, AccountResponse, Status, TableParams, UpdateMultipleAccount } from "../../../types/types";
import { CopyOutlined, EditOutlined, FileSearchOutlined, LoadingOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import { getAccounts, updateAccountList } from "../../../methods/axiosMethods";
import { useDispatch, useSelector } from "react-redux";
import { clearAccount, setAccount } from "../../../store/slices/account";
import _ from "lodash";
import SubDefaultLayout from "../../../components/SubDefaultLayout";
import { subMenusOfAccounts } from "../../../commons/subMenus";
import Buttons from "../../../components/Buttons";
import { image } from "../../../commons/ImageRoutes";
import ModalNotification, { AlertTypeNew } from "../../../components/ModalNotification";
import keycloak from "../../../context/keycloak";

//---------------------------------------------------------------------------------------------------------------------- Constants
const formatAmount = Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    useGrouping: true,
    minimumFractionDigits: 2,
    notation: 'standard'
});

//---------------------------------------------------------------------------------------------------------------------- Components
const RenderTag = ({ accountType }: { accountType: "CLABE" | "CEL" | "DEBIT" }) => {
    const type = (accountType === "CLABE") ? ("CLABE") : (
        (accountType === "CEL") ? ("CELULAR") : ("TARJETA")
    );
    const stl = StyleSheet.create({
        mainContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 5,
            padding: 5,
            backgroundColor: (accountType === "CLABE") ? ("#e6ffed") : ((accountType === "CEL") ? ("#e0c6fa") : ("#deeaff")),
        },
        iconContainer: {
            marginRight: "5px",
        },
        colorStyle: {
            color: (accountType === "CLABE") ? ("#0fe248") : ((accountType === "CEL") ? ("#ad62f8") : ("#3d84ff")),
        },
    });

    return (
        <div style={stl.mainContainer}>
            <span style={stl.colorStyle}>
                {type}
            </span>
        </div>
    );
}

//---------------------------------------------------------------------------------------------------------------------- View
const MyAccounts = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [accountsList, setAccountsList] = useState<Array<AccountResponse>>([]);
    const [accountsListTmp, setAccountsListTmp] = useState<Array<AccountResponse>>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const hasSelected = selectedRowKeys.length > 0;
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [notificationType, setNotificationType] = useState<AlertTypeNew>("SUCCESS");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const accList = useSelector((state: any) => state.account.accountsInfo);
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            // Se define que inicia en la pag 1 y el tamaño de la pagina será de 10
            current: 1,
            pageSize: 10,
            showSizeChanger: false,
        },
    });

    const columns: ColumnsType<AccountResponse> = [
        {
            title: "Estatus",
            dataIndex: "enabled",
            key: "enabled",
            align: "center",
            width: "10%",
            className: 'custom-header',
            render: (enabled: boolean) => (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <div
                        style={{
                            width: 10,
                            height: 10,
                            borderRadius: "50%",
                            margin: 5,
                            backgroundColor: (enabled) ? "#0fe248" : "#ff3d6b",
                        }}
                    />
                    <label
                        style={{
                            color: (enabled) ? "#0fe248" : "#ff3d6b",
                        }}
                    >
                        {enabled ? "Activa" : "Inactiva"}
                    </label>
                </div>
            ),
        },
        {
            title: "Acciones",
            dataIndex: "operation",
            key: "operation",
            align: "center",
            width: "30%",
            className: 'custom-header',
            render: (event, record: AccountResponse) => (
                <Row gutter={[16, 12]} justify={"center"}>
                    <Col>
                        <Buttons
                            type="primary"
                            title="Transferir"
                            color="#3d3dff"
                            action={() => navigate("/transfers/immediate", { state: record })}
                            imageIcon={image.transferImmIconSelected}
                            isDisabled={!record.enabled}
                        />
                    </Col>
                    <Col>
                        <Buttons
                            type="default"
                            title="Editar"
                            color="#ffb13d"
                            action={() => navigate(`/admin/accounts/${record.accountNumber}`)}
                            icon={<EditOutlined />}
                        />
                    </Col>
                    <Col>
                        <Buttons
                            type="default"
                            title="Consultar"
                            color="#3d84ff"
                            action={() => navigate(`/admin/accounts/details/${record.accountNumber}`)}
                            icon={<FileSearchOutlined />}
                        />
                    </Col>
                </Row>
            ),
        },
        {
            title: "Alias",
            dataIndex: "alias",
            key: "alias",
            className: 'custom-header',
            width: "10%",
        },
        {
            title: "Cuenta",
            dataIndex: "accountNumber",
            key: "accountNumber",
            align: "center",
            className: 'custom-header',
            render: (accountNumber: string) => (
                <div>
                    <span>{accountNumber}</span>
                    <span style={{ marginLeft: "5px" }}>
                        <CopyOutlined onClick={() => handleCopy(accountNumber)} />
                    </span>
                </div>
            ),
        },
        {
            title: "Tipo de Cuenta",
            dataIndex: "accountType",
            key: "accountType",
            align: "center",
            className: 'custom-header',
            render: (accountType) => <RenderTag accountType={accountType} />,
        },
        {
            title: "Fondos",
            dataIndex: "balance",
            key: "balance",
            align: "center",
            className: 'custom-header',
            render: (event, record: AccountResponse) => (
                <span style={{ color: '#00abb1', fontWeight: 'bold' }}>
                    {record.balance.balance && record.balance.balance !== 0 ? formatAmount.format(record.balance.balance) : '$0.00'}
                </span >
            ),
        },
        
    ];

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleCopy = (item: string) => {
        navigator.clipboard.writeText(item);
    };

    const handleTableChange: TableProps<AccountResponse>["onChange"] = (
        pagination,
    ) => {
        setTableParams({
            pagination,
        });
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setAccountsList([]);
        }
    };

    const getAccountsList = useCallback(() => {
        setIsLoading(true);
        getAccounts()
            .then((response) => {
                if (response.data && response.data.data) {
                    if (response.data.status === Status.OK && !_.isEmpty(response.data.data)) {
                        dispatch(setAccount({ accountsInfo: response.data.data }))
                        setAccountsList(response.data.data)
                        setAccountsListTmp(accList);
                    }
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setNotificationType("ERROR");
                setNotificationMessage("No fue posible obtener la lista de Cuentas, intente más tarde.");
                setShowNotification(true);
            })
    }, [accList, dispatch]);

    const headerSearcher = (text: string) => {
        const filteredResults = accList.filter(
            (data: AccountResponse) =>
                data.alias.toLowerCase().includes(text.toLowerCase()) ||
                data.accountNumber.toLowerCase().includes(text.toLowerCase())
        );
        setAccountsListTmp(filteredResults);
        setSelectedRowKeys([])
    };

    const enableOrDisableAccounts = (isEnable: boolean) => {
        const data: UpdateMultipleAccount = { accounts: [] };
        selectedRowKeys.forEach((element) => {
            accList.forEach((account: AccountResponse) => {
                if (element.valueOf()) {
                    if (account.accountNumber === element) {
                        let accountData: AccountData = {
                            accountId: account.accountNumber,
                            alias: account.alias,
                            enable: isEnable
                        };
                        data.accounts.push(accountData)
                    }
                }
            })
        });
        setIsLoading(true);
        updateAccountList(data)
            .then((response) => {
                if (response.data && response.data.status) {
                    setShowNotification(true);
                    setNotificationMessage(response.data.message);
                    if (response.data && response.data.status &&
                        response.data.data &&
                        response.data.status === Status.UPDATED) {
                        dispatch(clearAccount());
                        setNotificationType("SUCCESS");
                        setSelectedRowKeys([])
                    } else {
                        setNotificationType("ERROR");
                    }
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setShowNotification(true);
                setNotificationType("ERROR");
                setNotificationMessage("Hubo un error al actulizar lo(s) registro(s)")
            })
    };

    useEffect(() => {
        if (_.isEmpty(accList)) {
            getAccountsList();
        } else {
            setAccountsList(accList);
            setAccountsListTmp(accList);
        }
    }, [accList, getAccountsList]);

    return (
        <SubDefaultLayout childrenSubMenu={subMenusOfAccounts} title="Cuentas">
            <Row style={styles.rowRoot}>
                <div style={styles.primaryContainer}>
                    <Row gutter={[16, 12]} style={styles.rowContainer}>
                        <Col xs={24} md={8} xxl={5} >
                            <Search
                                onSearch={(value: string) => headerSearcher(value)}
                                onChange={(value) =>
                                    _.isEmpty(value.target.value) ?
                                        setAccountsListTmp(accountsList)
                                        : null}
                                placeholder="Buscar por alias o cuenta"
                            />
                        </Col>
                        <Col xs={0} sm={0} md={5} xxl={12} />
                        <Col xs={24} md={11} lg={11} xxl={7}
                                style={{ display: 'flex', justifyContent: 'space-evenly' }}
                            >
                            <Buttons
                                type="primary"
                                title="Habilitar"
                                color="#40CD9D"
                                action={() => enableOrDisableAccounts(true)}
                                icon={<PlusOutlined />}
                                isDisabled={!hasSelected}
                                iconLeft
                            />
                            <Buttons
                                type="primary"
                                title="Inhabilitar"
                                color="#FF3D6B"
                                action={() => enableOrDisableAccounts(false)}
                                icon={<MinusOutlined />}
                                isDisabled={!hasSelected}
                                iconLeft
                            />
                        </Col>
                    </Row>
                    <Col xs={24}>
                        <Table
                            rowSelection={rowSelection}
                            rowKey={(record) => record.accountNumber}
                            dataSource={accountsListTmp}
                            columns={columns}
                            onChange={handleTableChange}
                            scroll={{ y: 400, x: 1300 }}
                            size="small"
                            locale={{ emptyText: "No hay datos disponibles" }}
                            pagination={tableParams.pagination}
                            bordered
                            style={{ borderRadius: "none" }}
                            loading={{
                                spinning: isLoading,
                                indicator: <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />,
                            }}
                        />
                    </Col>
                </div>
            </Row>
            <ModalNotification
                title={"Mis Cuentas"}
                message={notificationMessage}
                alertType={notificationType}
                show={showNotification}
                onClose={() =>
                    notificationMessage === "No fue posible obtener la lista de Cuentas, intente más tarde." ?
                        keycloak.logout()
                        :
                        setShowNotification(false)
                }
            />
        </SubDefaultLayout>
    );
}

const styles = StyleSheet.create({
    rowRoot: {
        height: "100%",
        width: "100%",
    },
    primaryContainer: {
        width: "95%",
        margin: "20px auto",
        padding: "20px",
        borderRadius: 10,
        backgroundColor: 'white',
        boxShadow: "rgba(177, 179, 185, 0.51) 5px 8px 24px 5px",
    },
    title: {
        paddingLeft: "8px",
        fontSize: 18,
    },
    rowContainer: {
        marginBottom: '20px',
    },
});

export default MyAccounts;
