import axios, { AxiosError, AxiosHeaders, AxiosResponse } from "axios";
import {
    AccountResponse,
    AccountThirdData,
    CancelTransferRequest,
    CatalogBankResponse,
    CreateAccountGroupRequest,
    CreateScheadulerRequest,
    DispersionData,
    CreateMultipleAccountThirdRequest,
    CreateUserRequest,
    DeleteGroupsRequest,
    DisableMultipleAccountThirdRequest,
    GenericResponse,
    GetTransactionResponse,
    GroupResponse,
    SchedulerResponse,
    ShceduledDispersionStatus,
    TransactionPeriodResponse,
    UpdateAccountGroupRequest,
    UpdateAccountRequest,
    UpdateAccountThirdRequest,
    UpdateMultipleAccount,
    UpdateMultipleUsersRequest,
    UpdateUserRequest,
    UserResponse,
    MultipleDispersionRequest,
    MultipleDispersionResponse,
    NotificationResponse,
    NotificationRequest,
    DeleteAccountThirdRequest,
    ComprobanteRequest,
    TransactionResume,
} from "../types/types";
import vars from "../vars";
import { getToken } from "../context/keycloak";


const baseURL = vars.baseUrl;


const instance = axios.create({
    baseURL: `${baseURL}/api/v2`,
    timeout: 50000,
    headers: {
        'Content-Type': 'application/json'
    }
});

instance.interceptors.request.use((config) => {
    if (config.headers) {
        const headers = new AxiosHeaders();
        headers.setAuthorization(`Bearer ${getToken() ? getToken() : ''}`);
        config.headers = headers;
    }
    return config;
});

//--------------------------------------------------------------------------------------------------

export const getUsers = async (): Promise<AxiosResponse<GenericResponse<Array<UserResponse>>>> => {
    return new Promise<AxiosResponse<GenericResponse<Array<UserResponse>>>>((resolve, reject) => {
        const path = "/users";
        instance.get(path)
            .then(
                (response: AxiosResponse<GenericResponse<Array<UserResponse>>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const getUserById = async (
    userId: string
): Promise<AxiosResponse<GenericResponse<UserResponse>>> => {
    return new Promise<AxiosResponse<GenericResponse<UserResponse>>>((resolve, reject) => {
        const path = "/users/" + userId;
        instance.get(path)
            .then(
                (response: AxiosResponse<GenericResponse<UserResponse>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const resetPasswordByUserId = async (
    userId: string
): Promise<AxiosResponse<GenericResponse<UserResponse>>> => {
    return new Promise<AxiosResponse<GenericResponse<UserResponse>>>((resolve, reject) => {
        const path = "/users/" + userId + "/reset";
        instance.post(path)
            .then(
                (response: AxiosResponse<GenericResponse<UserResponse>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const addNewUser = async (
    body: CreateUserRequest
): Promise<AxiosResponse<GenericResponse<UserResponse>>> => {

    return new Promise<AxiosResponse<GenericResponse<UserResponse>>>((resolve, reject) => {
        const path = "/users";
        instance.post(path, body)
            .then(
                (response: AxiosResponse<GenericResponse<UserResponse>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const modifyUser = async (
    userId: string, body: UpdateUserRequest
): Promise<AxiosResponse<GenericResponse<UserResponse>>> => {

    return new Promise<AxiosResponse<GenericResponse<UserResponse>>>((resolve, reject) => {
        const path = "/users/" + userId;
        instance.put(path, body)
            .then(
                (response: AxiosResponse<GenericResponse<UserResponse>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const enabledOrDisabledUserList = async (
    body: UpdateMultipleUsersRequest
): Promise<AxiosResponse<GenericResponse<UserResponse>>> => {

    return new Promise<AxiosResponse<GenericResponse<UserResponse>>>((resolve, reject) => {
        const path = "/users";
        instance.put(path, body)
            .then(
                (response: AxiosResponse<GenericResponse<UserResponse>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const generateToken = async (
    userId: string
): Promise<AxiosResponse<GenericResponse<any>>> => {

    return new Promise<AxiosResponse<GenericResponse<any>>>((resolve, reject) => {
        const path = "/token/" + userId;
        instance.post(path)
            .then(
                (response: AxiosResponse<GenericResponse<any>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const tokenActive = async (
    userId: string
): Promise<AxiosResponse<GenericResponse<any>>> => {
    return new Promise<AxiosResponse<GenericResponse<any>>>((resolve, reject) => {
        const path = "/token/" + userId;
        instance.get(path)
            .then(
                (response: AxiosResponse<GenericResponse<any>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const desactivateToken = async (
    userId: string
): Promise<AxiosResponse<GenericResponse<any>>> => {
    return new Promise<AxiosResponse<GenericResponse<any>>>((resolve, reject) => {
        const path = "/token/" + userId;
        instance.put(path)
            .then(
                (response: AxiosResponse<GenericResponse<any>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const getAccounts = async (): Promise<AxiosResponse<GenericResponse<Array<AccountResponse>>>> => {
    return new Promise<AxiosResponse<GenericResponse<Array<AccountResponse>>>>((resolve, reject) => {
        const path = "/accounts";
        instance.get(path)
            .then(
                (response: AxiosResponse<GenericResponse<Array<AccountResponse>>>) => resolve(response)
            )
            .catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const getAccountsByCompany = async (): Promise<AxiosResponse<GenericResponse<Array<AccountResponse>>>> => {
    return new Promise<AxiosResponse<GenericResponse<Array<AccountResponse>>>>((resolve, reject) => {
        const path = "/company/accounts";
        instance.get(path)
            .then(
                (response: AxiosResponse<GenericResponse<Array<AccountResponse>>>) => resolve(response)
            )
            .catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const updateAccountList = async (body: UpdateMultipleAccount): Promise<AxiosResponse<GenericResponse<Array<AccountResponse>>>> => {
    return new Promise<AxiosResponse<GenericResponse<Array<AccountResponse>>>>((resolve, reject) => {
        const path = "/accounts";
        instance.put(path, body)
            .then(
                (response: AxiosResponse<GenericResponse<Array<AccountResponse>>>) => resolve(response)
            )
            .catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------

export const getAccountById = async (
    accountNumber: string
): Promise<AxiosResponse<GenericResponse<AccountResponse>>> => {
    return new Promise<AxiosResponse<GenericResponse<AccountResponse>>>((resolve, reject) => {
        const path = "/accounts/" + accountNumber;
        instance.get(path)
            .then(
                (response: AxiosResponse<GenericResponse<AccountResponse>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------

export const modifyAccount = async (
    accountNumber: string, body: UpdateAccountRequest
): Promise<AxiosResponse<GenericResponse<AccountResponse>>> => {

    return new Promise<AxiosResponse<GenericResponse<AccountResponse>>>((resolve, reject) => {
        const path = "/accounts/" + accountNumber;
        instance.put(path, body)
            .then(
                (response: AxiosResponse<GenericResponse<AccountResponse>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const getAccountsThird = async (): Promise<AxiosResponse<GenericResponse<Array<AccountThirdData>>>> => {
    return new Promise<AxiosResponse<GenericResponse<Array<AccountThirdData>>>>((resolve, reject) => {
        const path = "/thirds";
        instance.get(path)
            .then(
                (response: AxiosResponse<GenericResponse<Array<AccountThirdData>>>) => resolve(response)
            )
            .catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const addAccountThird = async (body: CreateMultipleAccountThirdRequest): Promise<AxiosResponse<GenericResponse<Array<AccountThirdData>>>> => {
    return new Promise<AxiosResponse<GenericResponse<Array<AccountThirdData>>>>((resolve, reject) => {
        const path = "/thirds";
        instance.post(path, body)
            .then(
                (response: AxiosResponse<GenericResponse<Array<AccountThirdData>>>) => resolve(response)
            )
            .catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const updateAccountThird = async (body: UpdateAccountThirdRequest, accountThirdId: string,): Promise<AxiosResponse<GenericResponse<AccountThirdData>>> => {
    return new Promise<AxiosResponse<GenericResponse<AccountThirdData>>>((resolve, reject) => {
        const path = "/thirds/" + accountThirdId;
        instance.put(path, body)
            .then(
                (response: AxiosResponse<GenericResponse<AccountThirdData>>) => resolve(response)
            )
            .catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const getAccountThirdById = async (
    accountThirdId: string
): Promise<AxiosResponse<GenericResponse<AccountThirdData>>> => {
    return new Promise<AxiosResponse<GenericResponse<AccountThirdData>>>((resolve, reject) => {
        const path = "/thirds/" + accountThirdId;
        instance.get(path)
            .then(
                (response: AxiosResponse<GenericResponse<AccountThirdData>>) => resolve(response)
            )
            .catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const disableAccountThirdList = async (body: DisableMultipleAccountThirdRequest): Promise<AxiosResponse<GenericResponse<Array<AccountThirdData>>>> => {
    return new Promise<AxiosResponse<GenericResponse<Array<AccountThirdData>>>>((resolve, reject) => {
        const path = "/thirds";
        instance.put(path, body)
            .then(
                (response: AxiosResponse<GenericResponse<Array<AccountThirdData>>>) => resolve(response)
            )
            .catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const deleteAccountThird = async (
    body: DeleteAccountThirdRequest
): Promise<AxiosResponse<GenericResponse<any>>> => {
    return new Promise<AxiosResponse<GenericResponse<any>>>((resolve, reject) => {
        const path = "/thirds";
        instance.delete(path, { data: body })
            .then(
                (response: AxiosResponse<GenericResponse<any>>) => resolve(response)
            )
            .catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const getBanks = async (): Promise<AxiosResponse<GenericResponse<Array<CatalogBankResponse>>>> => {
    return new Promise<AxiosResponse<GenericResponse<Array<CatalogBankResponse>>>>((resolve, reject) => {
        const path = "/catalogs/banks";
        instance.get(path)
            .then(
                (response: AxiosResponse<GenericResponse<Array<CatalogBankResponse>>>) => resolve(response)
            )
            .catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------

export const getAccountGroups = async (): Promise<AxiosResponse<GenericResponse<Array<GroupResponse>>>> => {
    return new Promise<AxiosResponse<GenericResponse<Array<GroupResponse>>>>((resolve, reject) => {
        const path = "/accounts-group";
        instance.get(path)
            .then(
                (response: AxiosResponse<GenericResponse<Array<GroupResponse>>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------

export const getAccountGroupById = async (
    groupId: string
): Promise<AxiosResponse<GenericResponse<GroupResponse>>> => {
    return new Promise<AxiosResponse<GenericResponse<GroupResponse>>>((resolve, reject) => {
        const path = "/accounts-group/" + groupId;
        instance.get(path)
            .then(
                (response: AxiosResponse<GenericResponse<GroupResponse>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const addAccountGroup = async (
    body: CreateAccountGroupRequest
): Promise<AxiosResponse<GenericResponse<any>>> => {

    return new Promise<AxiosResponse<GenericResponse<any>>>((resolve, reject) => {
        const path = "/accounts-group";
        instance.post(path, body)
            .then(
                (response: AxiosResponse<GenericResponse<any>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------

export const updateAccountGroup = async (
    body: UpdateAccountGroupRequest,
    groupId: string
): Promise<AxiosResponse<GenericResponse<GroupResponse>>> => {
    return new Promise<AxiosResponse<GenericResponse<GroupResponse>>>((resolve, reject) => {
        const path = "/accounts-group/" + groupId;
        instance.put(path, body)
            .then(
                (response: AxiosResponse<GenericResponse<GroupResponse>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const deleteAccountGroupList = async (
    body: DeleteGroupsRequest
): Promise<AxiosResponse<GenericResponse<GroupResponse>>> => {

    return new Promise<AxiosResponse<GenericResponse<GroupResponse>>>((resolve, reject) => {
        const path = "/accounts-group";
        instance.delete(path, { data: body })
            .then(
                (response: AxiosResponse<GenericResponse<GroupResponse>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const getPeriodsList = async (
    accountId: string
): Promise<AxiosResponse<GenericResponse<TransactionPeriodResponse>>> => {
    return new Promise<AxiosResponse<GenericResponse<TransactionPeriodResponse>>>((resolve, reject) => {
        const path = "/transactions/" + accountId + "/periods";
        instance.get(path)
            .then(
                (response: AxiosResponse<GenericResponse<TransactionPeriodResponse>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const getAllPeriodList = async (): Promise<AxiosResponse<GenericResponse<TransactionPeriodResponse>>> => {
    return new Promise<AxiosResponse<GenericResponse<TransactionPeriodResponse>>>((resolve, reject) => {
        const path = "/transactions/periods";
        instance.get(path)
            .then(
                (response: AxiosResponse<GenericResponse<TransactionPeriodResponse>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const getTransactionsByAccountAndPeriod = async (
    accountNumber: string,
    period: string
): Promise<AxiosResponse<GenericResponse<GetTransactionResponse>>> => {
    return new Promise<AxiosResponse<GenericResponse<GetTransactionResponse>>>((resolve, reject) => {
        const path = "/transactions/" + accountNumber + "/" + period;
        instance.get(path)
            .then(
                (response: AxiosResponse<GenericResponse<GetTransactionResponse>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//-------------------------------------------------------------------------------------------------- Create transaction
export const postTransaction = async (
    request: MultipleDispersionRequest
): Promise<GenericResponse<MultipleDispersionResponse>> => {
    return new Promise<GenericResponse<MultipleDispersionResponse>>((resolve, reject) => {
        const path = "/transactions/payment";
        instance.post(
            path, request
        ).then((response: AxiosResponse<GenericResponse<MultipleDispersionResponse>>) => {
            resolve(response.data);
        }).catch(
            (error: AxiosError) => reject(error)
        );
    });
};

export const getLinkReceiptment = (
    request: ComprobanteRequest
): Promise<string> => {
    return new Promise<string>(
        (resolve, reject) => {
            const path = `/transactions/receipt`;
            instance.post(
                // Indica responseType para obtener una respuesta en formato binario (PDF)
                path, request, { responseType: 'arraybuffer' }
            ).then(
                (response: AxiosResponse) => {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    // Crear una URL del Blob
                    const url = window.URL.createObjectURL(blob);
                    return resolve(url);
                }
            ).catch(
                (error: AxiosError) => reject(error)
            );
        }
    );
};

//--------------------------------------------------------------------------------------------------
export const printReceiptment = (
    request: ComprobanteRequest
): Promise<AxiosResponse> => {
    return new Promise<AxiosResponse<GenericResponse<String>>>(
        (resolve, reject) => {
            const path = `/transactions/receipt`;
            instance.post(
                // Indica responseType para obtener una respuesta en formato binario (PDF)
                path, request, { responseType: 'arraybuffer' }
            ).then(
                (response: AxiosResponse) => {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    // Crear una URL del Blob
                    const url = window.URL.createObjectURL(blob);
                    // Descarga el PDF automáticamente utilizando un hipervínculo oculto
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = `comprobante_electronico_${request.trackingKey}.pdf`;
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    return resolve(response);
                }
            ).catch(
                (error: AxiosError) => reject(error)
            );
        }
    );
};

//--------------------------------------------------------------------------------------------------
export const printStatement = async (
    accountNumber: string,
    period: string
): Promise<AxiosResponse<any>> => {
    return new Promise<AxiosResponse<GenericResponse<String>>>((resolve, reject) => {
        const path = `/transactions/${accountNumber}/invoice/${period}`;
        instance.get(path, { responseType: 'arraybuffer', timeout: 5000 })
            .then(
                (response: AxiosResponse) => {
                    const blob = new Blob([response.data], { type: 'application/pdf' });
                    // Crear una URL del Blob
                    const url = window.URL.createObjectURL(blob);
                    // Descarga el PDF automáticamente utilizando un hipervínculo oculto
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = `estado_cuenta_${accountNumber}-${period}.pdf`;
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    return resolve(response);
                }
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//--------------------------------------------------------------------------------------------------
export const getAllTransactions = async (
    period: string
): Promise<AxiosResponse<GenericResponse<Array<TransactionResume>>>> => {
    return new Promise<AxiosResponse<GenericResponse<Array<TransactionResume>>>>((resolve, reject) => {
        const path = "/transactions/" + period;
        instance.get(path)
            .then(
                (response: AxiosResponse<GenericResponse<Array<TransactionResume>>>) => resolve(response)
            ).catch(
                (error: AxiosError) => reject(error)
            );
    });
};

//-------------------------------------------------------------------------------------------------- Schedulers get transfers
export const getSchedulersTransactions = async (
    accountNumber: string, schedulerType: ShceduledDispersionStatus
): Promise<GenericResponse<Array<SchedulerResponse>>> => {
    return new Promise<GenericResponse<Array<SchedulerResponse>>>((resolve, reject) => {
        const path = "/scheduler/" + accountNumber;

        instance.get(
            path, { params: { "status": schedulerType.toString() } }
        ).then((response: AxiosResponse<GenericResponse<Array<SchedulerResponse>>>) => {
            resolve(response.data);
        }).catch(
            (error: AxiosError) => reject(error)
        );
    });
};

//-------------------------------------------------------------------------------------------------- Schedulers get transfers
export const getSchedulerById = async (
    transferId: string
): Promise<GenericResponse<SchedulerResponse>> => {
    return new Promise<GenericResponse<SchedulerResponse>>((resolve, reject) => {
        const path = "/scheduler/transaction/" + transferId;

        instance.get(
            path
        ).then((response: AxiosResponse<GenericResponse<SchedulerResponse>>) => {
            resolve(response.data);
        }).catch(
            (error: AxiosError) => reject(error)
        );
    });
};

//-------------------------------------------------------------------------------------------------- Create schedulers transactions
export const createSchedulersTransactions = async (
    request: CreateScheadulerRequest
): Promise<GenericResponse<Array<SchedulerResponse>>> => {
    return new Promise<GenericResponse<Array<SchedulerResponse>>>((resolve, reject) => {
        const path = "/scheduler";

        instance.post(
            path, request
        ).then((response: AxiosResponse<GenericResponse<Array<SchedulerResponse>>>) => {
            resolve(response.data);
        }).catch(
            (error: AxiosError) => reject(error)
        );
    });
};

//-------------------------------------------------------------------------------------------------- Cancel schedulers transactions
export const cancelSchedulersTransactions = async (
    request: CancelTransferRequest
): Promise<GenericResponse<void>> => {
    return new Promise<GenericResponse<void>>((resolve, reject) => {
        const path = "/scheduler";

        instance.put(
            path, request
        ).then((response: AxiosResponse<GenericResponse<void>>) => {
            resolve(response.data);
        }).catch(
            (error: AxiosError) => reject(error)
        );
    });
};

//-------------------------------------------------------------------------------------------------- Edit schedulers transactions
export const editSchedulersTransactions = async (
    transferId: string, request: DispersionData
): Promise<GenericResponse<SchedulerResponse>> => {
    return new Promise<GenericResponse<SchedulerResponse>>((resolve, reject) => {
        const path = "/scheduler/" + transferId;

        instance.put(
            path, request
        ).then((response: AxiosResponse<GenericResponse<SchedulerResponse>>) => {
            resolve(response.data);
        }).catch(
            (error: AxiosError) => reject(error)
        );
    });
};

// TODO: Probablemente se cambie por EventSource
//-------------------------------------------------------------------------------------------------- Get notifications 
export const getNotifications = async (
    userId: string
): Promise<GenericResponse<Array<NotificationResponse>>> => (
    new Promise<GenericResponse<Array<NotificationResponse>>>((resolve, reject) => {
        const path = "/notification";

        instance.get(path)
            .then((response: AxiosResponse<GenericResponse<Array<NotificationResponse>>>) => {
                resolve(response.data);
            }).catch((error: AxiosError) => reject(error));
    }
    ));

//-------------------------------------------------------------------------------------------------- Set read notifications 
export const setReadNotifications = async (
    request: NotificationRequest
): Promise<GenericResponse<void>> => (
    new Promise<GenericResponse<void>>((resolve, reject) => {
        const path = "/notification";

        instance.put(path, request)
            .then((response: AxiosResponse<GenericResponse<void>>) => {
                resolve(response.data);
            }).catch((error: AxiosError) => reject(error));
    }
    ));

//-------------------------------------------------------------------------------------------------- Delete notifications 
export const deleteNotifications = async (
    request: NotificationRequest
): Promise<GenericResponse<void>> => (
    new Promise<GenericResponse<void>>((resolve, reject) => {
        const path = "/notification";

        instance.delete(path, { data: request })
            .then((response: AxiosResponse<GenericResponse<void>>) => {
                resolve(response.data);
            }).catch((error: AxiosError) => reject(error));
    }
    ));
