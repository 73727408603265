import React, { useCallback, useEffect, useRef, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Col, DatePicker, Divider, Radio, RadioChangeEvent, Row, Spin, Steps, TimePicker } from "antd";
import Input from "../../../components/Input";
import { StyleSheet } from "../../../StyleSheet";
import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined, CloudUploadOutlined, LoadingOutlined } from "@ant-design/icons";
import { addAccountThird, createSchedulersTransactions, getAccounts, getAccountsThird, getBanks, postTransaction, printReceiptment } from "../../../methods/axiosMethods";
import { AccountDest, AccountOrigin, AccountResponse, AccountThirdData, AccountThirdDataRequest, AccountType, CatalogBankResponse, ComprobanteRequest, CreateMultipleAccountThirdRequest, CreateScheadulerRequest, DispersionData, Institution, MultipleDispersionRequest, SchedulerDispersionDataRequest, Status, TransactionResume, TransferData, TransferObj } from "../../../types/types";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { clearAccountThird, setAccountThird } from "../../../store/slices/accountsThird";
import { useLocation, useNavigate } from "react-router-dom";
import { setAccount } from "../../../store/slices/account";
import { setInstitution } from "../../../store/slices/institutions";
import TransferConfirmation from "./TransferConfirmation";
import TransferModalImport from "./TransferModalImport";
import { convertFromDayjsDateAndHour, disabledDate, formatAmount, formatAmountNumber, isOutOfTime, isValidCel, isValidClabe, isValidDebit, validateRfc } from "../../../functions/Functions";
import { subMenusOfTransfers } from "../../../commons/subMenus";
import SubDefaultLayout from "../../../components/SubDefaultLayout";
import "./css/TransferMain.css";
import InfoCard from "../../../components/InfoCard";
import Buttons from "../../../components/Buttons";
import CardDataTransfer from "../../../components/CardDataTransfer";
import { image } from "../../../commons/ImageRoutes";
import SelectInput, { SelectOption } from "../../../components/SelectInput";
import GenericModal from "../../../components/GenericModal";
import TransferResume from "./TransferResume";
import ModalNotification, { AlertTypeNew } from "../../../components/ModalNotification";
import 'dayjs/locale/es';
import locale from 'antd/es/date-picker/locale/es_ES';
import InputAmount from "../../../components/InputAmount";
dayjs.locale('es');

const bankTypes = [
    { value: 'CLABE', label: 'CLABE' },
    { value: 'DEBIT', label: 'Tarjeta de Debito' },
    { value: 'CEL', label: 'Teléfono celular' }
];

const transferObjEmpty: TransferObj = {
    conceptDest: "",
    radioOpt: 0,
    numRefDest: null,
    amountDest: null,
    ivaDest: null,
}

const accOriginEmpty: AccountOrigin = {
    accountName: "",
    accountNumber: ""
}

const accDestEmpty: AccountDest = {
    accountName: "",
    accountNumber: "",
    bankDataDest: undefined,
    accType: undefined,
    radioOpt: 0
}

const TransferMain = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()
    const [loading, setLoading] = React.useState(false);
    const accThirdList = useSelector((state: any) => state.accountThird.accountsThirdInfo);
    const ownAccList = useSelector((state: any) => state.account.accountsInfo);
    const banksList = useSelector((state: any) => state.institutions.institutionsInfo);
    const [bankList, setBankList] = React.useState<CatalogBankResponse[]>([]);
    const [accountsThirdList, setAccountsThirdList] = React.useState<Array<AccountThirdData>>([]);
    const [ownAccountsListOpt, setOwnAccountsListOpt] = React.useState<Array<AccountResponse>>([]);
    const [ownAccountsList, setOwnAccountsList] = React.useState<Array<AccountResponse>>([]);
    const [objTransferResp, setObjTransferResp] = React.useState<TransactionResume>();
    const [notificationType, setNotificationType] = useState<AlertTypeNew>("SUCCESS");
    const [showNotification, setShowNotification] = React.useState(false);
    const [shouldNavigate, setShouldNavigate] = React.useState(false);
    const [missingData, setHasMissingData] = React.useState(false);
    const [transferStatus, setTransferStatus] = React.useState("");
    const [transferFinished, setTransferFinished] = React.useState(false);
    const [isSchedulerTransfer, setIsSchedulerTransfer] = React.useState(false);
    const [trackingKey, setTrackingKey] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [nameForm, setNameForm] = React.useState("");
    const [rfcForm, setRfcForm] = React.useState('');
    const [accNumDest, setAccNumDest] = React.useState("");
    const [bankForm, setBankForm] = React.useState("");
    const [typeForm, setTypeForm] = React.useState("");
    const [conceptForm, setConceptForm] = React.useState("");
    const [numRefForm, setNumRefForm] = React.useState("");
    const [amountForm, setAmountForm] = React.useState("");
    const [ivaForm, setIvaForm] = React.useState("0.00");
    const [dateForm, setDateForm] = React.useState<Dayjs>();
    const [hourForm, setHourForm] = React.useState<Dayjs>();
    const [currentStep, setCurrentStep] = React.useState(0);
    const [radioOpt, setRadioOpt] = React.useState(1);
    const [accOriginData, setAccOriginData] = React.useState<AccountOrigin>(accOriginEmpty);
    const [accDestData, setAccDestData] = React.useState<AccountDest>(accDestEmpty);
    const [accDestDataForm, setAccDestDataForm] = React.useState<TransferObj>(transferObjEmpty);
    const [tokenForm, setTokenForm] = React.useState("");
    const [openModalImport, setOpenModalImport] = React.useState(false);
    const [thirdAccAdded, setThirdAccAdded] = React.useState(false);
    const [changeOriginAcc, setChangeOriginAcc] = React.useState(false);
    const [changeDestAcc, setChangeDestAcc] = React.useState(false);
    const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
    const [accOriginTmp, setAccOriginTmp] = React.useState("");
    const [accDestTmp, setAccDestTmp] = React.useState("");
    const [currentOriginAccIndex, setCurrentOriginAccIndex] = React.useState(0);
    const [currentDestOwnAccIndex, setCurrentDestOwnAccIndex] = React.useState(0);
    const inputRefAmount = useRef<HTMLInputElement>(null);
    const inputRefIva = useRef<HTMLInputElement>(null);
    const inputRefAmount2 = useRef<HTMLInputElement>(null);
    const inputRefIva2 = useRef<HTMLInputElement>(null);

    const radioOptions = [
        { label: 'Cuentas Propias', value: 1 },
        { label: 'Cuentas de Terceros', value: 2 },
        { label: 'Otras Cuentas', value: 3 },
    ];

    const navigateNotification = () => {
        navigate("/transfers/immediate");
    };

    useEffect(() => {
        if (location.state && location.state === "openModalImport") {
            setOpenModalImport(true);
        }
    }, [location.state])

    useEffect(() => {
        switch (radioOpt) {
            case 1:
                if (_.isEmpty(accDestData.accountNumber) && !_.isEmpty(ownAccountsListOpt)) {
                    const codeType: Array<CatalogBankResponse> = _.filter(bankList, { bankCode: ownAccountsListOpt[0].bank });
                    setAccDestData({
                        accountName: ownAccountsListOpt[0].alias,
                        accountNumber: ownAccountsListOpt[0].accountNumber,
                        bankDataDest: !_.isEmpty(codeType) ? codeType[0] : undefined,
                        accType: ownAccountsListOpt[0].accountType,
                        radioOpt: radioOpt,
                    });
                }
                break;
            case 2:
                if (_.isEmpty(accDestData.accountNumber) && !_.isEmpty(accountsThirdList)) {
                    const codeType: Array<CatalogBankResponse> = _.filter(bankList, { bankCode: accountsThirdList[0].bankName });
                    setAccDestData({
                        accountName: accountsThirdList[0].alias,
                        accountNumber: accountsThirdList[0].bankAccountNumber,
                        bankDataDest: !_.isEmpty(codeType) ? codeType[0] : undefined,
                        accType: accountsThirdList[0].accountType,
                        radioOpt: radioOpt,
                        rfc: accountsThirdList[0].rfc
                    });
                }
                break;
            case 3:
                let codeType;
                if (!_.isEmpty(bankForm)) {
                    const code: Array<CatalogBankResponse> = _.filter(bankList, { bankCode: bankForm });
                    if (!_.isEmpty(code)) {
                        codeType = code[0];
                    }
                } else {
                    codeType = bankList[0]
                }
                setAccDestData({
                    accountName: nameForm,
                    accountNumber: accNumDest,
                    bankDataDest: codeType,
                    accType: typeForm as AccountType,
                    radioOpt: radioOpt,
                    rfc: rfcForm
                });
                break;
        }

        let squeduledDateTime: Date;
        if (dateForm && hourForm) {
            squeduledDateTime = convertFromDayjsDateAndHour(dateForm, hourForm);
        }
        setAccDestDataForm(data => ({
            ...data,
            amountDest: amountForm,
            conceptDest: conceptForm,
            numRefDest: numRefForm,
            ivaDest: ivaForm,
            dateTimeDest: squeduledDateTime
        }));
    }, [
        accountsThirdList, amountForm, bankList, conceptForm, ivaForm,
        numRefForm, ownAccountsListOpt, radioOpt, rfcForm,
        dateForm, hourForm, currentDestOwnAccIndex, accDestData.accountNumber,
        accNumDest, bankForm, nameForm, typeForm
    ])

    const onChangeRadioButton = ({ target: { value } }: RadioChangeEvent) => {
        setHasMissingData(false);
        setRadioOpt(value);
        setAccDestData(accDestEmpty)
    };

    const getAccountsThirdList = useCallback(() => {
        setLoading(true);
        getAccountsThird()
            .then((response) => {
                if (response.data && response.data.data &&
                    response.data.status === Status.OK &&
                    !_.isEmpty(response.data.data)) {
                    // para mostrar solo las cuentas habilitadas
                    let thirdAccListEnable = response.data.data.filter((data: AccountThirdData) => data.enabled === true);
                    setAccountsThirdList(thirdAccListEnable);
                    dispatch(setAccountThird({ accountsThirdInfo: response.data.data }))
                } else if (response.data && response.data.data &&
                    response.data.status === Status.OK) {
                    setAccountsThirdList(response.data.data);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setNotificationType('ERROR');
                setMessage("No fue posible obtener la lista de cuentas de terceros, intente más tarde.");
                setShowNotification(true);
            })
    }, [dispatch])

    useEffect(() => {
        if (banksList && _.isEmpty(banksList)) {
            setLoading(true);
            getBanks()
                .then((response) => {
                    if (response.data.status === Status.OK && response.data.data && !_.isEmpty(response.data.data)) {
                        dispatch(setInstitution({ institutionsInfo: response.data.data }))
                    }
                    setLoading(false);
                })
                .catch(() => {
                    setNotificationType('ERROR');
                    setMessage("No fue posible obtener las instituciones bancarias en este momento, intente más tarde.");
                    setShouldNavigate(true);
                    setShowNotification(true);
                    setLoading(false);
                });
        } else {
            setBankList(banksList);
        }
    }, [banksList, dispatch]);

    const getAccountsList = useCallback(async () => {
        setLoading(true);
        getAccounts()
            .then((response) => {
                if (response.data && response.data.data) {
                    if (response.data.status === Status.OK && !_.isEmpty(response.data.data)) {
                        // para mostrar solo las cuentas habilitadas
                        let ownAcctListEnable = response.data.data.filter((data: AccountResponse) => data.enabled === true);
                        setOwnAccountsList(ownAcctListEnable)
                        dispatch(setAccount({
                            accountsInfo: response.data.data
                        }));
                    } else {
                        setOwnAccountsList(response.data.data)
                    }
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setNotificationType('ERROR');
                setMessage("No fue posible obtener la lista de cuentas, intente más tarde.");
                setShowNotification(true);
            })
    }, [dispatch])


    useEffect(() => {
        if (_.isEmpty(accThirdList)) {
            getAccountsThirdList();
        } else {
            // para mostrar solo las cuentas habilitadas
            let thirdAccListEnable = accThirdList.filter((data: AccountThirdData) => data.enabled === true);
            setAccountsThirdList(thirdAccListEnable);
        }
    }, [getAccountsThirdList, accThirdList]);

    useEffect(() => {
        if (_.isEmpty(ownAccList)) {
            getAccountsList();
        } else {
            let ownAcctListEnable = ownAccList.filter((data: AccountResponse) => data.enabled === true);
            setOwnAccountsList(ownAcctListEnable);
        }
    }, [getAccountsList, ownAccList])

    const onChangeStep = (value: number) => {
        if (accOriginData.accountNumber === accDestData.accountNumber && (!_.isEmpty(accOriginData.accountNumber) && !_.isEmpty(accDestData.accountNumber))) {
            setNotificationType('INFO');
            setMessage("No se permite elegir la misma cuenta de origen y de destino.");
            setShouldNavigate(false);
            setShowNotification(true);
            // Si es el caso de transfer entre ctas propias o terceros
        }
        else if (radioOpt === 1 || radioOpt === 2) {
            let hasError = false;
            // si hay campos vacios de transfer inmediata o de terceros
            if (_.isEmpty(accDestData.accountNumber)) {
                hasError = true;
                setMessage("No se ha elegido una cuenta de destino.");
            } else if (!_.isEmpty(accOriginData.accountNumber) && !_.isEmpty(accDestDataForm.conceptDest)
                && !_.isEmpty(accDestDataForm.amountDest) && !_.isEmpty(accDestDataForm.ivaDest)) {
                // si es transfer programada, revisa esos campos extras
                if (isSchedulerTransfer) {
                    if (!dateForm || !hourForm) {
                        hasError = true;
                        setMessage("Hay campos obligatorios incompletos.");
                    }
                }
                if (value === 3) {
                    if (tokenForm.length < 6) {
                        hasError = true;
                        setMessage("Hay campos obligatorios incompletos.");
                    }
                }
            } else {
                hasError = true;
            }
            if (hasError) {
                setNotificationType('INFO');
                setHasMissingData(true)
                setShouldNavigate(false);
                setShowNotification(true);
            } else {
                setCurrentStep(value);
            }
        } else {
            let hasError = false;
            // si la cuenta de destino es menor a 10 digitos o mayor a 18
            if (accDestData.accountNumber && (accDestData.accountNumber.toString().length < 10 || accDestData.accountNumber.toString().length > 18)) {
                setNotificationType('INFO');
                setMessage("El número de cuenta no puede ser menor a 10 dígitos o mayor a 18 dígitos.");
                setHasMissingData(true)
                setShouldNavigate(false);
                setShowNotification(true);
            }
            // si es transfer inmediata
            else if (!_.isEmpty(accOriginData.accountNumber) && !_.isEmpty(accDestData.accountName) && !_.isEmpty(accDestData.accountNumber)
                && !_.isEmpty(accDestData.bankDataDest) && !_.isEmpty(accDestData.accType) && !_.isEmpty(accDestDataForm.conceptDest)
                && !_.isEmpty(accDestDataForm.amountDest) && !_.isEmpty(accDestDataForm.ivaDest)) {
                // si es transfer programada, revisa esos campos extras

                let isValidAcc = false;
                switch (typeForm) {
                    case "CLABE":
                        isValidAcc = isValidClabe(accDestData.accountNumber)
                        break;
                    case "DEBIT":
                        isValidAcc = isValidDebit(accDestData.accountNumber)
                        break;
                    case "CEL":
                        isValidAcc = isValidCel(accDestData.accountNumber)
                        break;
                }
                if (!isValidAcc) {
                    hasError = true;
                    setMessage("El número de cuenta y/o el tipo de cuenta no son validos.");
                }
                if (isSchedulerTransfer) {
                    if (!dateForm || !hourForm) {
                        hasError = true;
                        setMessage("Hay campos obligatorios incompletos.");
                    }
                }
            } else {
                setNotificationType('INFO');
                setMessage("Hay campos obligatorios incompletos.");
                setHasMissingData(true);
                setShouldNavigate(false);
                setShowNotification(true);
            }
            if (hasError) {
                setNotificationType('INFO');
                setShouldNavigate(false);
                setShowNotification(true);
                setHasMissingData(true);
            } else {
                setCurrentStep(value);

            }
        }
        if (value === 2) {
            setTokenForm("");
        }
        if (value === 3 && tokenForm.length === 6) {
            doTransfer();
        }
    };

    const handleSaveButton = () => {
        let hasError = false;
        switch (currentStep) {
            case 0:
                // cuando eligen la misma cuenta de origen que de destino
                if (accOriginData.accountNumber === accDestData.accountNumber && (!_.isEmpty(accOriginData.accountNumber) && !_.isEmpty(accDestData.accountNumber))) {
                    setNotificationType('INFO');
                    setMessage("No se permite elegir la misma cuenta de origen y de destino.");
                    setShouldNavigate(false);
                    setShowNotification(true);
                    hasError = true;
                }
                else if (radioOpt === 1 || radioOpt === 2) {
                    // si hay campos vacios de transfer inmediata o de terceros
                    if (_.isEmpty(accDestData.accountNumber)) {
                        hasError = true;
                        setMessage("No se ha elegido una cuenta de destino.");
                    } else
                        if (!_.isEmpty(accOriginData.accountNumber)
                            && !_.isEmpty(accDestDataForm.conceptDest)
                            && !_.isEmpty(accDestDataForm.amountDest) && !_.isEmpty(accDestDataForm.ivaDest)) {
                            // si es transfer programada, revisa este campo extra
                            if (isSchedulerTransfer) {
                                if (!dateForm || !hourForm) {
                                    hasError = true;
                                    setMessage("Hay campos obligatorios incompletos.");
                                }
                            }
                        } else {
                            setMessage("Hay campos obligatorios incompletos.");
                            hasError = true;
                        }
                    if (hasError) {
                        setNotificationType('INFO');
                        setHasMissingData(true);
                        setShouldNavigate(false);
                        setShowNotification(true);
                    } else {
                        setCurrentStep(1);
                    }
                } else {
                    let hasError = false;
                    // si la cuenta de destino es menor a 10 digitos o mayor a 18
                    if (accDestData.accountNumber && (accDestData.accountNumber.toString().length < 10 || accDestData.accountNumber.toString().length > 18)) {
                        setNotificationType('INFO');
                        setMessage("El número de cuenta no puede ser menor a 10 dígitos o mayor a 18 dígitos.");
                        setHasMissingData(true)
                        setShouldNavigate(false);
                        setShowNotification(true);
                        hasError = true;
                    }
                    // si es transfer inmediata
                    else if (!_.isEmpty(accOriginData.accountNumber) && !_.isEmpty(accDestData.accountName) && !_.isEmpty(accNumDest)
                        && !_.isEmpty(accDestData.bankDataDest) && !_.isEmpty(accDestData.accType) && !_.isEmpty(accDestDataForm.conceptDest)
                        && !_.isEmpty(accDestDataForm.amountDest) && !_.isEmpty(accDestDataForm.ivaDest)) {

                        // si es transfer programada, revisa esos campos extras
                        if (isSchedulerTransfer) {
                            if (!dateForm || !hourForm) {
                                hasError = true;
                                setMessage("Hay campos obligatorios incompletos.");
                            }
                        }
                        let isValidAcc = false;
                        switch (typeForm) {
                            case "CLABE":
                                isValidAcc = isValidClabe(accDestData.accountNumber)
                                break;
                            case "DEBIT":
                                isValidAcc = isValidDebit(accDestData.accountNumber)
                                break;
                            case "CEL":
                                isValidAcc = isValidCel(accDestData.accountNumber)
                                break;
                        }
                        if (!isValidAcc) {
                            setNotificationType('INFO');
                            setMessage("El número de cuenta y/o el tipo de cuenta no son validos.");
                            setShouldNavigate(false);
                            setShowNotification(true);
                            hasError = true;
                        }
                    } else {
                        hasError = true;
                        setMessage("Hay campos obligatorios incompletos.");
                    }
                    if (hasError) {
                        setNotificationType('INFO');
                        setHasMissingData(true)
                        setShouldNavigate(false);
                        setShowNotification(true);
                    } else {
                        setCurrentStep(1);
                    }
                }
                break;
            case 1:
                setCurrentStep(2);
                setTokenForm("");
                break;
            // Paso donde se pide el token
            case 2:
                if (isSchedulerTransfer) {
                    if (accDestDataForm.dateTimeDest) {
                        // si la fecha y hora elegida es menor a la fecha y hora actual
                        if (accDestDataForm.dateTimeDest && accDestDataForm.dateTimeDest < new Date()) {
                            setMessage("La fecha y hora elegida ya pasa de la fecha y hora actual, por favor elija una fecha y hora valida.");
                            setNotificationType('INFO');
                            setShowNotification(true);
                            setCurrentStep(0);
                        } else {
                            doSqueduledTransfer()
                        }
                    }

                } else {
                    doTransfer();
                }
                break;
        }

    };

    const goBack = () => {
        navigate('/transfers/history');
    };

    const goBackStepButton = () => {
        setCurrentStep(currentStep - 1);
        setTokenForm("");
    };

    const doTransfer = async () => {
        let objTransferReq: DispersionData = {
            accountNumber: accOriginData.accountNumber,
            foreignAccountNumber: accDestData.accountNumber,
            foreignBankId: accDestData.bankDataDest?.bankCode ? accDestData.bankDataDest?.bankCode : "",
            reference: Number(accDestDataForm.numRefDest),
            name: accDestData.accountName,
            paymentConcept: accDestDataForm.conceptDest,
            accountType: accDestData.accType === "CLABE" ? 40 :
                accDestData.accType === "DEBIT" ? 3 : 10,
            paymentType: accDestDataForm.radioOpt === 1 ? 0 : 1,
            rfcOrCurp: accDestData.rfc,
            balance: Number(formatAmountNumber.format(Number(accDestDataForm.amountDest))),
            iva: Number(formatAmountNumber.format(Number(accDestDataForm.ivaDest))),
            date: new Date()
        }
        const request = {
            token: tokenForm.toString(),
            dispersions: [objTransferReq]
        } as MultipleDispersionRequest;
        setLoading(true);
        postTransaction(request)
            .then((response) => {
                if (response) {
                    if (response.status === Status.CREATED
                        && !_.isEmpty(response.data)
                        && !_.isEmpty(response.data.successDispersions)) {
                        setNotificationType('SUCCESS');
                        setShouldNavigate(false);
                        setMessage(response.message);
                        setCurrentStep(3);
                        setTransferFinished(true);
                        setTrackingKey(response.data.successDispersions[0].trackingKey);
                        setObjTransferResp(response.data.successDispersions[0].transactionResume);
                        setTransferStatus(response.data.successDispersions[0].transactionResume.status)
                    } else {
                        setNotificationType('ERROR');
                        setShouldNavigate(false);
                        setMessage(response.error.message);
                        setCurrentStep(1);
                    }
                    setShowNotification(true);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setCurrentStep(1);
                setShowNotification(true);
                setNotificationType('ERROR');
                setShouldNavigate(false);
                setMessage("Hubo un error al realizar la transferencia");
            })
    };

    const doSqueduledTransfer = () => {
        let objTransferReq: SchedulerDispersionDataRequest = {
            accountNumber: accOriginData.accountNumber,
            foreignAccountNumber: accDestData.accountNumber,
            foreignBankId: accDestData.bankDataDest?.bankCode ? accDestData.bankDataDest?.bankCode : "",
            reference: Number(accDestDataForm.numRefDest),
            name: accDestData.accountName,
            paymentConcept: accDestDataForm.conceptDest,
            accountType: accDestData.accType === "CLABE" ? 40 :
                accDestData.accType === "DEBIT" ? 3 : 10,
            paymentType: accDestDataForm.radioOpt === 1 ? 0 : 1,
            rfcOrCurp: accDestData.rfc ? accDestData.rfc : "",
            balance: Number(accDestDataForm.amountDest),
            iva: Number(accDestDataForm.ivaDest),
            scheduledDateTime: accDestDataForm.dateTimeDest ? accDestDataForm.dateTimeDest : new Date(),
        }
        const request = {
            token: tokenForm,
            dispersions: [objTransferReq]
        } as CreateScheadulerRequest;

        setLoading(true);
        createSchedulersTransactions(request)
            .then((response) => {
                if (response) {
                    if (response.status === Status.CREATED
                        && !_.isEmpty(response.data)
                    ) {
                        setNotificationType('SUCCESS');
                        setShouldNavigate(false);
                        setMessage(response.message);
                        setCurrentStep(3);
                        setTransferFinished(true);
                    } else {
                        setNotificationType('ERROR');
                        setShouldNavigate(false);
                        setMessage(response.error.message);
                        setCurrentStep(1);
                    }
                    setShowNotification(true);
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setCurrentStep(1);
                setShowNotification(true);
                setNotificationType('ERROR');
                setShouldNavigate(false);
                setMessage("Hubo un error al realizar la transferencia");
            })
    };

    const handleChangeToken = (inputValue: string) => {
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        setTokenForm(numericValue)
    };

    const handleChangeBank = (element: string) => {
        const values = Object.values(Institution)
        values.forEach((value) => {
            if (value === element) {
                setBankForm(value);
            }
        });
    };

    const handleChangeTypeForm = (element: string) => {
        setTypeForm(element)
    };

    const validateData = (objectToImport: TransferData[]) => {
        let transferList: TransferData[] = [];
        const numbersOnlyRegex = /^\d+$/;
        let messageErrors = "";
        // revisa si hay campos vacios en campos requeridos
        objectToImport.forEach((elem: TransferData, index: number) => {
            let hasError = false;
            // campos obligatorios en general
            if (elem.accNumberOrigin == null || elem.accNumberDest == null ||
                elem.accTypeDest == null || elem.nameDest == null ||
                elem.bankNameDest == null || elem.amountDest == null ||
                elem.amountIva == null) {
                hasError = true;
            }
            if (elem.referenceDest && elem.referenceDest !== null) {
                if (elem.referenceDest.toString().length > 7) {
                    hasError = true;
                }
                if (!numbersOnlyRegex.test(elem.referenceDest)) {
                    hasError = true;
                }
            }
            // fin campos obligatorios en general
            if (hasError) {
                const itemMessage = `El registro ${index + 2} tiene campos obligatorios inválidos o la referencia es invalida.\n`;
                messageErrors += itemMessage;
            } else {
                let objImported = {
                    'key': index.toString(),
                    'accNumberOrigin': elem.accNumberOrigin,
                    'accNumberDest': elem.accNumberDest,
                    'accTypeDest': elem.accTypeDest === "Tarjeta de Debito" ?
                        "DEBIT" : elem.accTypeDest === "Teléfono celular" ?
                            "CEL" : "CLABE",
                    'nameDest': elem.nameDest,
                    'rfcDest': elem.rfcDest,
                    'bankNameDest': Institution[elem.bankNameDest as keyof typeof Institution],
                    'conceptDest': elem.conceptDest,
                    'referenceDest': elem.referenceDest,
                    'amountDest': elem.amountDest.toString(),
                    'amountIva': elem.amountIva.toString()
                }
                transferList.push(objImported)
            }
        });
        if (!_.isEmpty(messageErrors)) {
            setShouldNavigate(false);
            setNotificationType('ERROR');
            setMessage(messageErrors);
            setShowNotification(true);
        } else {
            if (location.pathname === "/transfers/scheduled/new") {
                navigate("/transfers/scheduled-imported", { state: transferList });
            }
            else {
                navigate("/transfers/immediate-imported", { state: transferList });
            }
        }
    };

    const saveThirdAccount = (aliasForm: string) => {
        // guardar cta tercero
        let requestAccountThird: AccountThirdDataRequest[] = [];
        if (objTransferResp) {
            let bankObj = _.find(bankList, { bankName: objTransferResp.receiverBank });
            const bodyData = {
                name: objTransferResp.receiverName,
                typeAccount: objTransferResp.accountType,
                divisa: 'MXN',
                alias: aliasForm,
                bankAccountNumber: objTransferResp.receiverAccountNumber,
                bankName: bankObj?.bankCode as Institution,
                enabled: true,
                rfc: objTransferResp.rfcOrCurp,
                street: "",
                streetNumber: "",
                zip: "",
                state: "",
                municipality: "",
                country: 'Mexico',
            };
            requestAccountThird.push(bodyData)
            let accountArray: CreateMultipleAccountThirdRequest = {
                accountsThirds: requestAccountThird
            }
            setLoading(true);
            addAccountThird(accountArray)
                .then((response) => {
                    if (response.data.status === Status.CREATED) {
                        dispatch(clearAccountThird());
                        setNotificationType('SUCCESS');
                        setThirdAccAdded(true);
                    } else {
                        setNotificationType('ERROR');
                    }
                    setMessage(response.data.message);
                    setShouldNavigate(false);
                    setLoading(false);
                    setShowNotification(true);
                })
                .catch(() => {
                    setLoading(false);
                    setNotificationType('ERROR');
                    setMessage("No fue posible crear la cuenta de tercero en este momento, intente más tarde.");
                    setShouldNavigate(false);
                    setShowNotification(true);
                })
        }
    };

    const handleChangeAccNumber = (inputValue: string) => {
        let type = "CLABE";
        let bankCode = "";
        setAccNumDest(inputValue.replace(/[^0-9]/g, ''));

        if (isValidCel(inputValue)) {
            type = "CEL";
        }
        if (isValidDebit(inputValue)) {
            type = "DEBIT";
        }
        if (isValidClabe(inputValue)) {
            const codeType: Array<CatalogBankResponse> = _.filter(bankList, { code: inputValue.slice(0, 3) });
            if (!_.isEmpty(codeType)) {
                bankCode = codeType[0].bankCode;
                type = "CLABE"
            }
        }
        setTypeForm(type);
        setBankForm(bankCode);
    };

    const printReceipt = () => {
        let result = "";
        if (objTransferResp) {
            let receiptReq: ComprobanteRequest = {
                trackingKey: objTransferResp.trackingKey,
                accountNumberReceiver: objTransferResp.receiverAccountNumber,
                accountNumberSource: objTransferResp.sourceAccountNumber
            }
            printReceiptment(receiptReq)
                .then((response) => {
                })
                .catch(() => {
                    setNotificationType('ERROR');
                    setMessage("Hubo un error al imprimir el Comprobante de Pago, intente más tarde..");
                    setShouldNavigate(false);
                    setShowNotification(true);
                    result = "";
                })
        }
        return result;
    };

    useEffect(() => {
        if (!_.isEmpty(ownAccountsList)) {
            setAccOriginData({
                accountName: ownAccountsList[currentOriginAccIndex].alias,
                accountNumber: ownAccountsList[currentOriginAccIndex].accountNumber
            });
            let ownAccountOpt = ownAccountsList.filter((account: any) => !ownAccountsList[currentOriginAccIndex].accountNumber.includes(account.accountNumber));
            setOwnAccountsListOpt(ownAccountOpt);
        }
    }, [ownAccountsList, currentOriginAccIndex, location.state]);

    const handleChangeAccDest = (type: "ownAcc" | "thirdAcc") => {
        if (type === "ownAcc") {
            if (!_.isEmpty(ownAccountsListOpt)) {
                let index = 0;
                // si es la unica cuenta disponible o se dejó seleccionada la defecto, 
                // se asigna la posicion 0
                if (ownAccountsListOpt.findIndex((element) => element.accountNumber === accDestTmp) > 0) {
                    index = ownAccountsListOpt.findIndex((element) => element.accountNumber === accDestTmp);
                }
                setCurrentDestOwnAccIndex(index)
                const codeType: Array<CatalogBankResponse> = _.filter(bankList, { bankCode: ownAccountsListOpt[index].bank });
                setAccDestData({
                    accountName: ownAccountsListOpt[index].alias,
                    accountNumber: ownAccountsListOpt[index].accountNumber,
                    bankDataDest: !_.isEmpty(codeType) ? codeType[0] : undefined,
                    accType: ownAccountsListOpt[index].accountType,
                    radioOpt: 1,
                });
            }
        } else {
            if (!_.isEmpty(accountsThirdList)) {
                let index = 0;
                // si es la unica cuenta disponible o se dejó seleccionada la defecto, 
                // se asigna la posicion 0
                if (accountsThirdList.findIndex((element) => element.id === accDestTmp) > 0) {
                    index = accountsThirdList.findIndex((element) => element.id === accDestTmp)
                }
                const codeType: Array<CatalogBankResponse> = _.filter(bankList, { bankCode: accountsThirdList[index].bankName });
                setAccDestData({
                    accountName: accountsThirdList[index].alias,
                    accountNumber: accountsThirdList[index].bankAccountNumber,
                    bankDataDest: !_.isEmpty(codeType) ? codeType[0] : undefined,
                    accType: accountsThirdList[index].accountType,
                    radioOpt: 2,
                    rfc: accountsThirdList[index].rfc
                });

            }
        }
        setChangeDestAcc(false);
    }

    useEffect(() => {
        // si es new-squeduled es que es programada
        if (location && location.pathname === "/transfers/scheduled/new") {
            setIsSchedulerTransfer(true);
        } else {
            setIsSchedulerTransfer(false);
            if (location.state !== null) {
                // si el state incluye accountNumber es de cuentas propias
                // si incluye bankAccountNumber es de cuentas de terceros
                if (location.state.accountNumber) {
                    setAccOriginTmp(location.state.accountNumber);
                    setAccOriginData({
                        accountName: location.state.alias,
                        accountNumber: location.state.accountNumber,
                    });
                    if (!_.isEmpty(ownAccountsList)) {
                        setCurrentOriginAccIndex(
                            ownAccountsList.findIndex((element) => element.accountNumber === location.state.accountNumber) > 0 ?
                                ownAccountsList.findIndex((element) => element.accountNumber === location.state.accountNumber) : 0
                        )
                    }
                } else {
                    setAccDestTmp(location.state.id);
                    setRadioOpt(2);
                    const codeType: Array<CatalogBankResponse> = _.filter(bankList, { bankCode: location.state.bankName });
                    setAccDestData({
                        accountName: location.state.alias,
                        accountNumber: location.state.bankAccountNumber,
                        bankDataDest: !_.isEmpty(codeType) ? codeType[0] : undefined,
                        accType: location.state.accountType,
                        radioOpt: 2,
                        rfc: location.state.rfc
                    });
                }

            }
        }
    }, [location, ownAccountsList, accountsThirdList, bankList])

    return (
        <SubDefaultLayout childrenSubMenu={subMenusOfTransfers} title="Transferencias Inmediatas">
            {
                loading ?
                    <div style={styles.contentView}>
                        <Spin
                            indicator={antIcon}
                            tip="Cargando..."
                            style={styles.loading}
                        />
                    </div>
                    :
                    <>
                        <Row gutter={[12, 12]}>
                            <Col xs={24} md={15} lg={6}>
                                <Steps
                                    percent={100}
                                    direction="vertical"
                                    current={currentStep}
                                    onChange={!transferFinished ? onChangeStep : undefined}
                                    items={[
                                        {
                                            title: 'Datos de la Transferencia',
                                            description: "Emisor-Receptor",
                                        },
                                        {
                                            title: 'Confirmación',
                                            description: 'Confirmación de Datos',
                                        },
                                        {
                                            title: 'Token',
                                            description: "Validación de Token",
                                        },
                                        {
                                            title: 'Recibo',
                                            description: "Recibo de Transferencia",
                                        },
                                    ]}
                                />
                            </Col>
                            <Col xs={24} lg={18}>

                                <div style={styles.rowRoot}>
                                    {
                                        isOutOfTime() ?
                                            <div style={{ marginTop: -15, marginBottom: 15 }}>
                                                <InfoCard
                                                    title="¡Importante!"
                                                    description="Si estás realizando esta operación fuera de horario hábil (lunes a viernes de 6:00 am a 11:0pm) la operación se
                hará efectiva el siguiente día hábil."
                                                    type="primary"
                                                />
                                            </div>
                                            : null
                                    }
                                    <div style={styles.divContainer}>
                                        <Row gutter={[12, 12]} justify={"end"} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                            {
                                                !transferFinished ?
                                                    <>
                                                        {
                                                            currentStep !== 0 ?
                                                                <Col sm={12} md={6} xl={4}>
                                                                    <Buttons
                                                                        type={"primary"}
                                                                        title={"Anterior"}
                                                                        action={goBackStepButton}
                                                                        iconLeft icon={<ArrowLeftOutlined />}
                                                                        color="#0101bc" />
                                                                </Col>
                                                                : null
                                                        }
                                                        <Col sm={12} md={6} xl={4}>
                                                            <Buttons
                                                                type={"primary"}
                                                                title={"Siguiente"}
                                                                action={handleSaveButton}
                                                                icon={<ArrowRightOutlined />}
                                                                color="#3d3dff"
                                                                isDisabled={currentStep === 2 && tokenForm.length < 6}
                                                            />
                                                        </Col>
                                                        <Col sm={12} md={6} xl={4}>
                                                            <Buttons
                                                                type={"default"}
                                                                title={"Cancelar"}
                                                                action={goBack}
                                                                icon={<CloseOutlined />}
                                                                color="#ffb13d" />
                                                        </Col>
                                                        {
                                                            currentStep !== 2 ?
                                                                <Col sm={12} md={6} xl={4}>
                                                                    <Buttons
                                                                        type={"default"}
                                                                        title={"Importar"}
                                                                        action={() => setOpenModalImport(true)}
                                                                        icon={<CloudUploadOutlined />}
                                                                        color="#3d84ff" />
                                                                </Col>
                                                                : null
                                                        }

                                                    </>
                                                    :
                                                    <Col sm={12} md={6} xl={4} xxl={3} style={styles.endCol}>
                                                        <Buttons
                                                            type={"primary"}
                                                            title={"Finalizar"}
                                                            action={() => isSchedulerTransfer ? navigate('/transfers/scheduled') : navigate('/transfers/history')}
                                                            icon={<ArrowRightOutlined />}
                                                            color="#3d3dff" />
                                                    </Col>
                                            }
                                        </Row>
                                        <Divider style={styles.divider} />
                                        {
                                            currentStep === 0 ?
                                                <>
                                                    <h2 style={styles.textTitle}>Datos de Transferencia</h2>
                                                    <Row gutter={[12, 12]}>
                                                        <Col xs={24} sm={11}>
                                                            <CardDataTransfer
                                                                accountName={accOriginData.accountName}
                                                                accountNumber={accOriginData.accountNumber}
                                                                radioOpt={radioOpt}
                                                                handleChangeAccount={() => setChangeOriginAcc(true)}
                                                                backgroundColor={"#eaeaff"}
                                                                fontColor={"#000089"}
                                                                cardType="origin"
                                                            />
                                                        </Col>
                                                        <Col xs={24} sm={2} style={styles.colView}>
                                                            <img
                                                                src={image.transferImmIconBlue}
                                                                alt={"transferIcon"}
                                                                style={styles.logoIcon}
                                                            />
                                                        </Col>
                                                        <Col xs={24} sm={11}>
                                                            <CardDataTransfer
                                                                accountName={accDestData.accountName}
                                                                accountNumber={accDestData.accountNumber}
                                                                accountType={accDestData.accType}
                                                                accountBankData={accDestData.bankDataDest}
                                                                radioOpt={accDestData.radioOpt}
                                                                handleChangeAccount={() => setChangeDestAcc(true)}
                                                                backgroundColor={"#fffeea"}
                                                                fontColor={"#434343"}
                                                                cardType="dest"
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <h2 style={{ color: "#434343", marginTop: 30, paddingLeft: 20 }}>Tipo de Transferencia</h2>
                                                    <Radio.Group
                                                        style={{ padding: 15 }}
                                                        options={radioOptions}
                                                        onChange={onChangeRadioButton}
                                                        value={radioOpt} />
                                                    {
                                                        radioOpt === 1 || radioOpt === 2 ?
                                                            //Transfer Entre cuentas propias o terceros
                                                            <Row gutter={[12, 12]}>
                                                                <Col xs={24} sm={12}>
                                                                    <Input
                                                                        label="Descripción de Concepto"
                                                                        required
                                                                        value={conceptForm}
                                                                        type="text"
                                                                        maxLength={128}
                                                                        onChange={(val) => setConceptForm(val)}
                                                                        errorLabel={missingData && _.isEmpty(conceptForm) ? "Este campo es obligatorio." : ""}
                                                                        withError={missingData && _.isEmpty(conceptForm)}
                                                                    />
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <Input
                                                                        maxLength={7}
                                                                        value={numRefForm}
                                                                        label="Referencia Numérica"
                                                                        onChange={(elem) => setNumRefForm(elem)}
                                                                        type="text"
                                                                        isTextNumeric
                                                                    />
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <InputAmount
                                                                        value={amountForm}
                                                                        label="Importe sin IVA"
                                                                        onChange={(elem) => setAmountForm(elem)}
                                                                        required
                                                                        errorLabel={missingData && _.isEmpty(amountForm) ? "Este campo es obligatorio." : ""}
                                                                        withError={missingData && _.isEmpty(amountForm)}
                                                                        inputRef={inputRefAmount}
                                                                    />
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <InputAmount
                                                                        value={formatAmount.format(Number(ivaForm))}
                                                                        label="IVA"
                                                                        onChange={(elem) => setIvaForm(elem)}
                                                                        required
                                                                        errorLabel={missingData && _.isEmpty(ivaForm) ? "Este campo es obligatorio." : ""}
                                                                        withError={missingData && _.isEmpty(ivaForm)}
                                                                        inputRef={inputRefIva}
                                                                    />
                                                                </Col>
                                                                {/* Si es transfer programada */}
                                                                {
                                                                    isSchedulerTransfer ?
                                                                        <>
                                                                            <Col xs={24} sm={12}>
                                                                                <div style={styles.inputContainerDateTime}>
                                                                                    <DatePicker
                                                                                        style={{ padding: 10, width: "100%", borderRadius: 10 }}
                                                                                        format={"DD/MM/YYYY"}
                                                                                        value={dateForm}
                                                                                        placeholder="* Seleccione una Fecha"
                                                                                        onChange={element => element ? setDateForm(element) : null}
                                                                                        showToday={false}
                                                                                        status={missingData && !dateForm ? "error" : ""}
                                                                                        locale={locale}
                                                                                        disabledDate={disabledDate}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col xs={24} sm={12}>
                                                                                <div style={styles.inputContainerDateTime}>
                                                                                    <TimePicker
                                                                                        style={{ padding: 10, width: "100%", borderRadius: 10 }}
                                                                                        format={"HH:mm"}
                                                                                        value={hourForm}
                                                                                        placeholder="* Seleccione una Hora"
                                                                                        showNow={false}
                                                                                        onChange={element => element ? setHourForm(element) : null}
                                                                                        status={missingData && !hourForm ? "error" : ""}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </>
                                                                        : null}
                                                            </Row>
                                                            :
                                                            //Transfer Innmediata
                                                            <Row gutter={[16, 12]}>
                                                                <Col xs={24} >
                                                                    <Input
                                                                        label="Nombre"
                                                                        value={nameForm}
                                                                        onChange={(element) => setNameForm(element)}
                                                                        required
                                                                        type={"text"}
                                                                        errorLabel={missingData && _.isEmpty(nameForm) ? "Este campo es obligatorio." : ""}
                                                                        withError={missingData && _.isEmpty(nameForm)}
                                                                    />
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <Input
                                                                        label="RFC"
                                                                        value={rfcForm}
                                                                        onChange={(element) => setRfcForm(element.toUpperCase())}
                                                                        maxLength={13}
                                                                        type={"text"}
                                                                        errorLabel="Ingrese un RFC valido"
                                                                        withError={!_.isEmpty(rfcForm) && validateRfc(rfcForm)}
                                                                    />
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <Input
                                                                        value={accNumDest}
                                                                        label="CLABE / Tarjeta / Teléfono"
                                                                        onChange={(elem) => handleChangeAccNumber(elem)}
                                                                        required
                                                                        maxLength={18}
                                                                        type="text"
                                                                        errorLabel={missingData && _.isEmpty(accNumDest) ? "Este campo es obligatorio." : ""}
                                                                        withError={missingData && _.isEmpty(accNumDest)}
                                                                        isTextNumeric
                                                                    />
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <SelectInput
                                                                        label="* Entidad"
                                                                        value={bankForm}
                                                                        options={bankList.map(data => ({
                                                                            value: data.bankCode,
                                                                            label: data.bankName
                                                                        }))}
                                                                        onChange={(e) => {
                                                                            handleChangeBank(e);
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <SelectInput
                                                                        label="* Tipo"
                                                                        value={typeForm}
                                                                        options={bankTypes.map(data => ({
                                                                            value: data.value,
                                                                            label: data.label
                                                                        }))}
                                                                        onChange={(e) => {
                                                                            handleChangeTypeForm(e);
                                                                        }}
                                                                    />
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <Input
                                                                        value={conceptForm}
                                                                        required
                                                                        label="Descripción de Concepto"
                                                                        onChange={(element) => setConceptForm(element)}
                                                                        type="text"
                                                                        errorLabel={missingData && _.isEmpty(conceptForm) ? "Este campo es obligatorio." : ""}
                                                                        withError={missingData && _.isEmpty(conceptForm)}
                                                                    />
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <Input
                                                                        maxLength={7}
                                                                        value={numRefForm}
                                                                        label="Referencia Numérica"
                                                                        onChange={(elem) => setNumRefForm(elem)}
                                                                        type="text"
                                                                        isTextNumeric
                                                                    />
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <InputAmount
                                                                        value={amountForm}
                                                                        label="Importe sin IVA"
                                                                        onChange={(elem) => setAmountForm(elem)}
                                                                        required
                                                                        errorLabel={missingData && _.isEmpty(amountForm) ? "Este campo es obligatorio." : ""}
                                                                        withError={missingData && _.isEmpty(amountForm)}
                                                                        inputRef={inputRefAmount2}
                                                                    />
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <InputAmount
                                                                        value={formatAmount.format(Number(ivaForm))}
                                                                        label="IVA"
                                                                        onChange={(elem) => setIvaForm(elem)}
                                                                        required
                                                                        errorLabel={missingData && _.isEmpty(ivaForm) ? "Este campo es obligatorio." : ""}
                                                                        withError={missingData && _.isEmpty(ivaForm)}
                                                                        inputRef={inputRefIva2}
                                                                    />
                                                                </Col>
                                                                {
                                                                    isSchedulerTransfer ?
                                                                        <>
                                                                            <Col xs={24} sm={12}>
                                                                                <div style={styles.inputContainerDateTime}>
                                                                                    <DatePicker
                                                                                        style={{ padding: 10, width: "100%", borderRadius: 10, }}
                                                                                        format={"DD/MM/YYYY"}
                                                                                        value={dateForm}
                                                                                        placeholder="* Seleccione una Fecha"
                                                                                        onChange={element => element ? setDateForm(element) : null}
                                                                                        showToday={false}
                                                                                        status={missingData && !dateForm ? "error" : ""}
                                                                                        locale={locale}
                                                                                        disabledDate={disabledDate}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            <Col xs={24} sm={12}>
                                                                                <div style={styles.inputContainerDateTime}>
                                                                                    <TimePicker
                                                                                        style={{ padding: 10, width: "100%", borderRadius: 10 }}
                                                                                        format={"HH:mm"}
                                                                                        value={hourForm}
                                                                                        placeholder="* Seleccione una Hora"
                                                                                        showNow={false}
                                                                                        onChange={element => element ? setHourForm(element) : null}
                                                                                        status={missingData && !hourForm ? "error" : ""}
                                                                                        locale={locale}
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                        </>
                                                                        : null}
                                                            </Row>
                                                    }
                                                </> :
                                                currentStep === 1 ?
                                                    <TransferConfirmation
                                                        accDestDataForm={accDestDataForm}
                                                        accDestData={accDestData}
                                                        accOriginData={accOriginData}
                                                        isSqueduled={isSchedulerTransfer}
                                                    />
                                                    :
                                                    currentStep === 2 ?
                                                        <div style={styles.divStep2}>
                                                            <div style={{ width: "80%" }}>
                                                                <InfoCard
                                                                    title="¡Importante!"
                                                                    description="Le informamos que hemos recibido su solicitud y para proceder con su solicitud de
                                                        confirmación, se ha generado un token de confirmación único y seguro, que deberá
                                                        ingresar para completar el proceso."
                                                                    type="warning"
                                                                />
                                                                <Row gutter={[12, 12]} style={{ marginTop: 20 }}>
                                                                    <Col xs={24} sm={6} style={styles.colView}>
                                                                        <img
                                                                            src={image.tokenIcon}
                                                                            alt={"secureIcon"}
                                                                            style={styles.tokenIcon}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={24} sm={16}>
                                                                        <h3 style={{ color: "#434343", paddingLeft: 10 }}>Ingrese Token</h3>
                                                                        <Input
                                                                            maxLength={6}
                                                                            value={tokenForm}
                                                                            label="Ingrese los 6 Dígitos de su Token"
                                                                            onChange={(elem) => handleChangeToken(elem)}
                                                                            type={"text"}
                                                                            isTextNumeric
                                                                            errorLabel={missingData && _.isEmpty(tokenForm) ? "Este campo es obligatorio." : ""}
                                                                            withError={missingData && _.isEmpty(tokenForm)}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                        :
                                                        <TransferResume
                                                            trackingKey={trackingKey}
                                                            saveThirdAcc={(elem: string) => saveThirdAccount(elem)}
                                                            printReceipt={printReceipt}
                                                            isSqueduled={isSchedulerTransfer}
                                                            accOriginData={accOriginData}
                                                            accDestDataForm={accDestDataForm}
                                                            accDestData={accDestData}
                                                            transferStatus={transferStatus}
                                                            thirdAccAdded={thirdAccAdded}
                                                        />
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <TransferModalImport
                            isScheduler={false}
                            openModal={openModalImport}
                            handleClose={() => setOpenModalImport(false)}
                            handleImportTransfer={validateData}
                            isLoadingSpin={loading}
                        />
                        <GenericModal
                            showActionButtons
                            show={changeOriginAcc}
                            title="Cambiar Cuenta de Origen"
                            onClose={() => setChangeOriginAcc(false)}
                            onConfirm={() => {
                                if (!_.isEmpty(ownAccountsList)) {
                                    setCurrentOriginAccIndex(
                                        ownAccountsList.findIndex((element) => element.accountNumber === accOriginTmp) > 0 ?
                                            ownAccountsList.findIndex((element) => element.accountNumber === accOriginTmp) : 0
                                    )
                                }
                                setChangeOriginAcc(false);
                            }}
                        >
                            <Row style={styles.viewRow}>
                                <Col span={24}>
                                    <SelectInput
                                        label="Cuenta Origen"
                                        value={accOriginTmp}
                                        options={
                                            ownAccountsList.map((element) => ({
                                                value: element.accountNumber,
                                                label: `${element.accountNumber} | ${element.alias}`
                                            } as SelectOption))
                                        }
                                        onChange={(val) => setAccOriginTmp(val)}
                                    />
                                </Col>
                            </Row>
                        </GenericModal>
                        <GenericModal
                            showActionButtons
                            show={changeDestAcc}
                            title="Cambiar Cuenta de Destino"
                            onClose={() => setChangeDestAcc(false)}
                            onConfirm={() =>
                                radioOpt === 1 ?
                                    handleChangeAccDest("ownAcc")
                                    :
                                    handleChangeAccDest("thirdAcc")
                            }
                        >
                            <Row style={styles.viewRow}>
                                <Col span={24}>
                                    <SelectInput
                                        label="Cuenta Destino"
                                        value={accDestTmp}
                                        options={
                                            // si es de cuentas propias
                                            radioOpt === 1 ?
                                                _.isEmpty(ownAccountsListOpt) ?
                                                    ownAccountsList.map(option => ({
                                                        value: option.accountNumber,
                                                        label: (option.alias + " | " + option.name + " | " + option.accountNumber)
                                                    }))
                                                    :
                                                    ownAccountsListOpt.map(option => ({
                                                        value: option.accountNumber,
                                                        label: (option.alias + " | " + option.name + " | " + option.accountNumber)
                                                    }))
                                                :
                                                // sino, es de ctas terceros
                                                accountsThirdList.map(option => ({
                                                    value: option.id,
                                                    label: (option.alias + " | " + option.name + " | " + option.bankAccountNumber)
                                                }))
                                        }
                                        onChange={(val) => setAccDestTmp(val)}
                                    />
                                </Col>
                            </Row>
                        </GenericModal>
                        <ModalNotification
                            title={"Transferencias"}
                            message={message}
                            alertType={notificationType}
                            show={showNotification}
                            onClose={() => shouldNavigate ? navigateNotification() : setShowNotification(false)}
                        />
                    </>
            }
        </SubDefaultLayout >
    );
};

const styles = StyleSheet.create({
    rowRoot: {
        height: "100%",
        width: "100%",
        paddingBottom: 20
    },
    divContainer: {
        width: "100%",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        padding: "15px 20px 15px 20px",
        boxShadow: "3px 3px 5px 0px rgba(0,0,0,0.10)",
        height: "100%",
        borderRadius: 10,
    },
    inputContainerDateTime: {
        padding: 10
    },
    viewRow: {
        padding: 20
    },
    logoIcon: {
        resizeMode: 'contain',
        width: 25,
    },
    tokenIcon: {
        resizeMode: 'contain',
        width: 55,
    },
    colView: {
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center'
    },
    divider: {
        borderWidth: 2,
        margin: "5px 0px 10px"
    },
    endCol: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    textTitle: {
        color: "#434343",
        marginBottom: 30,
        paddingLeft: 20
    },
    divStep2: {
        width: "100%",
        display: 'flex',
        justifyContent: 'center'
    },
    contentView: {
        width: "100%",
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    loading: {
        maxHeight: "none",
    },
});

export default TransferMain;
