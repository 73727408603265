import { Col, Row, Skeleton } from "antd";
import { StyleSheet } from "../../StyleSheet";
import SubDefaultLayout from "../../components/SubDefaultLayout";
import { subMenusOfStadistics } from "../../commons/subMenus";
import CardDashboard from "../../components/CardDashboard";
import { AccountResponse, Period, Status, TransactionResume } from "../../types/types";
import { image } from "../../commons/ImageRoutes";
import "./css/Styles.css";
import { getAccounts, getPeriodsList, getTransactionsByAccountAndPeriod } from "../../methods/axiosMethods";
import { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { PeriodInterface, labelMonth, splicePeriodsLists } from "../../functions/Functions";
import ChangeAccountCard from "../transfers/scheduler/components/ChangeAccountCard";
import GenericModal from "../../components/GenericModal";
import SelectInput, { SelectOption } from "../../components/SelectInput";
import { useDispatch } from "react-redux";
import { setAccount } from "../../store/slices/account";
import Buttons from "../../components/Buttons";
import { DotChartOutlined, RedoOutlined } from "@ant-design/icons";
import StadisticsGraph from "./components/StatisticsGraph";
import ModalNotification, { AlertTypeNew } from "../../components/ModalNotification";
import * as XLSX from 'xlsx';
import InfoCard from "../../components/InfoCard";

export interface GroupedDataInterface {
  x: string;
  y: number;
  color: string;
  y0: number;
  type: string;
};

const StadisticsHome = () => {
  const dispatch = useDispatch();
  const [ownAccountsList, setOwnAccountsList] = useState<Array<AccountResponse>>([]);
  const [isLoadingT, setIsLoadingT] = useState(false);
  const [indexOfCurrentYear, setIndexOfCurrentYear] = useState<number>(-1);
  const [indexOfCurrentMonth, setIndexOfCurrentMonth] = useState<number>(-1);
  const [dimensions, setDimensions] = useState({ height: 0, width: 0 });
  const [transactionsList, setTransactionsList] = useState<Array<GroupedDataInterface>>([]);
  const [transactionData, setTransactionData] = useState<Array<TransactionResume>>([]);
  const [showChangeAccountModal, setShowChangeAccountModal] = useState<boolean>(false);
  const [currentAccountIndex, setCurrentAccountIndex] = useState(0);
  const [balanceAccount, setBalanceAccount] = useState(0);
  const [accountsLoading, setAccountsLoading] = useState<boolean>(false);
  const [accountTmp, setAccountTmp] = useState<string>("");
  const [monthSelected, setMonthSelected] = useState<string>("");
  const [periods, setPeriods] = useState<PeriodInterface>({ periods: [] });
  const [periodsList, setPeriodsList] = useState<Array<Period>>([]);
  const [totalIncAndOut, setTotalIncAndOut] = useState<Array<{
    label: string,
    angle: number,
    total: number,
    color: string
  }>>([]);
  const [yDomain, setYDomain] = useState(0);
  const div_ref = useRef<HTMLDivElement | null>(null);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState<AlertTypeNew>("SUCCESS");
  const colorIn = "#ff3e1d";
  const colorOut = "#3d3dff";

  useEffect(() => {
    const resizeObserver = new ResizeObserver((event) => {
      setDimensions({
        height: event[0].contentBoxSize[0].blockSize,
        width: event[0].contentBoxSize[0].inlineSize,
      });
    });
    if (div_ref?.current) {
      resizeObserver.observe(div_ref.current);
    }
  }, []);

  const exportCsv = () => {
    const Heading = [
      [
        'Fecha',
        'Nombre Emisor',
        'Entidad Emisora',
        'Cuenta Emisor',
        'Emisor',
        'Concepto',
        'Nombre Receptor',
        'Entidad Receptora',
        'Cuenta Receptor',
        'Rastreo',
        'Ingreso',
        'Egreso',
        'IVA',
        'Total',
        'Estatus'
      ]
    ];
    const arrayToCsv: any = [];
    const objectOrder = {
      date: '',
      sourceName: '',
      sourceBank: '',
      sourceAccountNumber: '',
      username: '',
      paymentConcept: '',
      receiverName: '',
      receiverBank: '',
      receiverAccountNumber: '',
      trackingKey: '',
      incoming: '',
      outcoming: '',
      iva: '',
      total: '',
      status: ''
    };
    transactionData.forEach((data: any) => {
      data.sourceAccountNumber = `'${data.sourceAccountNumber}'`;
      data.receiverAccountNumber = `'${data.receiverAccountNumber}'`;
      delete data.id;
      delete data.transactionType;
      const objToOrder = Object.assign(objectOrder, data);
      arrayToCsv.push({
        date: objToOrder.date,
        sourceName: objToOrder.sourceName,
        sourceBank: objToOrder.sourceBank,
        sourceAccountNumber: objToOrder.sourceAccountNumber,
        username: objToOrder.username,
        paymentConcept: objToOrder.paymentConcept,
        receiverName: objToOrder.receiverName,
        receiverBank: objToOrder.receiverBank,
        receiverAccountNumber: objToOrder.receiverAccountNumber,
        trackingKey: objToOrder.trackingKey,
        incoming: `$${objToOrder.incoming}`,
        outcoming: `$${objToOrder.outcoming}`,
        iva: `$${objToOrder.iva}`,
        total: `$${objToOrder.incoming + objToOrder.outcoming + objToOrder.iva}`,
        status: objToOrder.status === "DONE" ? "Realizada" : "En Proceso"
      });
    });

    const ws = XLSX.utils.book_new();
    const wb = XLSX.utils.book_new();
    let period = periods.periods[indexOfCurrentYear].year + "-" + periods.periods[indexOfCurrentYear].months[indexOfCurrentMonth];
    const exportFileName = `${"Historial_de_transacciones_" + period + ".csv"}`;

    XLSX.utils.sheet_add_aoa(ws, Heading);
    XLSX.utils.sheet_add_json(ws, arrayToCsv, { origin: 'A2', skipHeader: true });
    XLSX.utils.book_append_sheet(wb, ws, 'Hoja1');
    XLSX.writeFile(wb, exportFileName);
  };

  const getTransactions = useCallback(async (yearIndex: number, monthIndex: number, accIndex: number) => {
    setIsLoadingT(true);
    if (!_.isEmpty(ownAccountsList) && accIndex >= 0) {
      if (periods && periods.periods[yearIndex] && periods.periods[yearIndex].months[monthIndex]) {
        const periodComplete = `${periods.periods[yearIndex].months[monthIndex]}-${periods.periods[yearIndex].year}`;
        setBalanceAccount(ownAccountsList[accIndex].balance.balance);
        await getTransactionsByAccountAndPeriod(ownAccountsList[accIndex].accountNumber, periodComplete)
          .then((response) => {
            if (response.data && response.data.data) {
              if (response.data.status === Status.OK) {
                const doneTransactions = response.data.data.transactions.filter(o => o.status === "DONE" || o.status === "PROCESSING");
                const orderedTransactions = _.orderBy(doneTransactions, [transaction => transaction.date], ['asc']);
                setTransactionData(orderedTransactions);

                let totalIn = 0;
                let totalOut = 0;
                const groupedData = orderedTransactions.reduce((acc, transaction) => {
                  const date = moment(transaction.date, "DD-MM-YYYY HH:mm:ss").format("DD");
                  if (transaction.transactionType === "INCOMING") {
                    const existingTransactionI = acc.find(item => item.x === date && item.type === "INCOMING");
                    if (existingTransactionI) {
                      existingTransactionI.y += transaction.incoming;
                      existingTransactionI.type = "INCOMING"
                    } else {
                      acc.push({
                        x: date,
                        y: transaction.incoming,
                        color: colorOut,
                        y0: 0,
                        type: transaction.transactionType
                      });
                    }
                  } else {
                    const existingTransactionO = acc.find(item => item.x === date && item.type === "OUTCOMING");
                    if (existingTransactionO) {
                      existingTransactionO.y -= transaction.outcoming;
                      existingTransactionO.type = "OUTCOMING"
                    } else {
                      acc.push({
                        x: date,
                        y: -transaction.outcoming,
                        color: colorIn,
                        y0: 0,
                        type: transaction.transactionType
                      });
                    }
                  }
                  // Actualizar totales
                  if (transaction.transactionType === "INCOMING") {
                    totalIn += transaction.incoming + transaction.iva;
                  } else {
                    totalOut += transaction.outcoming + transaction.iva;
                  }
                  return acc;
                }, [] as { x: string; y: number; color: string, y0: number, type: string, }[]);

                // Obtener el valor más bajo y más alto de 'y' en groupedData
                const minY = Math.min(...groupedData.map(item => item.y));
                const yDomain = Math.max(...groupedData.map(item => item.y));
                if ((Math.abs(minY) || Math.abs(yDomain)) <= 0.5) {
                  setYDomain(1);
                } else if (Math.abs(minY) < Math.abs(yDomain)) {
                  setYDomain(Math.round(Math.abs(yDomain)));
                } else {
                  setYDomain(Math.round(Math.abs(minY)))
                }
                // Cálculo de porcentajes
                let total = totalIn + totalOut;
                let totalInAndOut = [
                  {
                    label: "Ingresos",
                    angle: (totalIn / total) * 100,
                    total: totalIn,
                    color: colorOut
                  },
                  {
                    label: "Egresos",
                    angle: (totalOut / total) * 100,
                    total: totalOut,
                    color: colorIn,
                  }
                ];
                setTotalIncAndOut(totalInAndOut)
                setTransactionsList(groupedData);
              }
              setIsLoadingT(false);
            }
          })
          .catch(() => {
            setIsLoadingT(false);
            setNotificationMessage("Hubo un error al obtener la lista de transaciones, intente más tarde.")
            setShowNotification(true);
            setNotificationType("ERROR");
          })
      }
    }
  }, [periods, ownAccountsList]);

  const handleChangeYear = (value: string) => {
    let index = periods.periods.findIndex(elem => elem.year === value);
    let indexRequest = 0;
    // si el arreglo de meses del index es mayor a 1, es para que se muestre el del index  del indexOfCurrentMonth
    // sino el index que llamará será el 0 (es para cuando solo tiene enero)
    if (periods && periods.periods && index >= 0 &&
      periods.periods[index].months && periods.periods[index].months.length > 1) {
      indexRequest = indexOfCurrentMonth;
    }
    setIndexOfCurrentYear(index);
    setMonthSelected(periods.periods[index].months[indexRequest]);
    getTransactions(index, indexRequest, currentAccountIndex);
  };

  const handleChangeMonth = (value: string) => {
    setMonthSelected(value)
    let index = periods.periods[indexOfCurrentYear].months.findIndex(elem => elem === value)
    setIndexOfCurrentMonth(index);
    getTransactions(indexOfCurrentYear, index, currentAccountIndex);

  };

  const handleChangeAcc = () => {
    let accIndex = ownAccountsList.findIndex((element) => element.accountNumber === accountTmp);
    setCurrentAccountIndex(accIndex);
    setShowChangeAccountModal(false);
    getTransactions(indexOfCurrentYear, indexOfCurrentMonth, accIndex);
  }

  const getAccountsList = useCallback(async () => {
    setAccountsLoading(true);
    await getAccounts()
      .then((response) => {
        if (response.data && response.data.data) {
          if (response.data.status === Status.OK && !_.isEmpty(response.data.data)) {
            setOwnAccountsList(response.data.data)
            dispatch(setAccount({
              accountsInfo: response.data.data
            }));
          } else {
            setOwnAccountsList(response.data.data)
          }
        }
        setAccountsLoading(false);
      })
      .catch(() => {
        setAccountsLoading(false);
        setNotificationMessage("Hubo un error al obtener la lista de cuentas, intente más tarde.")
        setShowNotification(true);
        setNotificationType("ERROR");
      })
  }, [dispatch])

  const getPeriodsFromAccount = useCallback(async () => {
    if (!_.isEmpty(ownAccountsList) && currentAccountIndex >= 0) {
      await getPeriodsList(ownAccountsList[currentAccountIndex].accountNumber)
        .then((response) => {
          if (response.data && response.data.data) {
            if (response.data.status === Status.OK) {
              setPeriodsList(_.orderBy(response.data.data.periods, 'period', 'desc'));
            } else {
              setNotificationMessage(response.data.message);
              setShowNotification(true);
              setNotificationType("ERROR");
            }
          }
        })
        .catch(() => {
          setNotificationMessage("Hubo un error al obtener los periodos, intente más tarde.")
          setShowNotification(true);
          setNotificationType("ERROR");
        })
    }
  }, [currentAccountIndex, ownAccountsList]);

  useEffect(() => {
    getPeriodsFromAccount();
  }, [getPeriodsFromAccount]);

  useEffect(() => {
    if (!_.isEmpty(periodsList)) {
      setPeriods(splicePeriodsLists(periodsList));
    }
  }, [periodsList]);

  useEffect(() => {
    if (indexOfCurrentMonth < 0 && indexOfCurrentYear < 0) {
      getTransactions(0, 0, 0);
    }
  }, [indexOfCurrentMonth, indexOfCurrentYear, getTransactions]);

  useEffect(() => {
    if (!_.isEmpty(periods.periods)) {
      setIndexOfCurrentYear(0);
      setIndexOfCurrentMonth(0);
    }
  }, [periods.periods]);

  useEffect(() => {
    getAccountsList();
  }, [getAccountsList]);

  return (
    <>
      <SubDefaultLayout childrenSubMenu={subMenusOfStadistics} title="Estadisticas">
        <Row gutter={[15, 25]}>
          <Col span={24}>
            <Row gutter={[10, 10]}>
              <Col xs={24} xl={10} xxl={8} style={{ marginTop: 10 }}>
                <ChangeAccountCard
                  loading={accountsLoading}
                  accountName={ownAccountsList[currentAccountIndex]?.alias}
                  accountInfo={`STP | CLABE ${ownAccountsList[currentAccountIndex]?.accountNumber}`}
                  onClickChange={() => setShowChangeAccountModal(true)}
                />
              </Col>
              <Col xs={12} xl={4}>
                <CardDashboard
                  title={"Programar Transferencia"}
                  icon={image.transferSchIcon}
                  action={`/transfers/scheduled/new`}
                  height={130}
                />
              </Col>
              <Col xs={12} xl={4}>
                <CardDashboard
                  title={"Exportar"}
                  icon={image.exportIcon}
                  height={130}
                  isFunction={() => exportCsv()}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} xl={20}>
            <div ref={div_ref} style={styles.primaryContainer}>
              {
                isLoadingT ? (
                  <div style={styles.skeleton}>
                    <Skeleton />
                    <Skeleton.Node active={isLoadingT}>
                      <DotChartOutlined style={{ fontSize: 40, color: '#bfbfbf' }} />
                    </Skeleton.Node>
                    <Skeleton />
                  </div>
                ) : (
                  <>
                    {!_.isEmpty(transactionsList) && transactionsList.length > 0 ? (
                      <>
                        {(periods && periods.periods &&
                          indexOfCurrentYear >= 0 &&
                          periods.periods[indexOfCurrentYear])
                          && (
                            <Row gutter={[12, 12]} align={"middle"} justify={"start"} style={{ marginBottom: 20 }}>
                              <Col sm={8} md={4}>
                                <SelectInput
                                  label={"Año"}
                                  value={periods.periods[indexOfCurrentYear].year}
                                  options={periods.periods.map((elem) => ({
                                    label: elem.year,
                                    value: elem.year
                                  }))}
                                  onChange={(value) => handleChangeYear(value)}
                                />
                              </Col>
                              <Col sm={8} md={4}>
                                <SelectInput
                                  label={"Mes"}
                                  value={monthSelected}
                                  options={periods.periods[indexOfCurrentYear].months.map(elem => ({
                                    value: elem,
                                    label: labelMonth(elem).label
                                  }))}
                                  onChange={(value) => handleChangeMonth(value)}
                                />
                              </Col>
                              <Col sm={4} md={3}>
                                <Buttons
                                  type="primary"
                                  color="#3d84ff"
                                  action={() => getTransactions(indexOfCurrentYear, indexOfCurrentMonth, currentAccountIndex)}
                                  icon={<RedoOutlined />}
                                  title="Actualizar"
                                />
                              </Col>
                            </Row>
                          )}
                        <StadisticsGraph
                          isLoadingT={isLoadingT}
                          yDomain={yDomain}
                          transactionsList={transactionsList}
                          totalInAndOut={totalIncAndOut}
                          dimensions={dimensions}
                          balanceAcc={balanceAccount}
                        />
                      </>
                    ) : (
                      <InfoCard
                        title="¡Importante!"
                        description="Estimado usuario, le informamos que no hay periodos para consultar de esta cuenta."
                        type="warning"
                      />

                    )
                    }
                  </>
                )
              }
            </div>
          </Col>
        </Row >
        <ModalNotification
          title={"Estadisticas"}
          message={notificationMessage}
          alertType={notificationType}
          show={showNotification}
          onClose={() => setShowNotification(false)}
        />
      </SubDefaultLayout >
      <GenericModal
        showActionButtons
        show={showChangeAccountModal}
        title="Cambiar de cuenta"
        onClose={() => setShowChangeAccountModal(false)}
        onConfirm={() => handleChangeAcc()}
      >
        <Row style={{ padding: 30 }}>
          <Col span={24}>
            <SelectInput
              label="Seleccione una cuenta"
              value={accountTmp}
              options={
                ownAccountsList.map((element) => ({
                  value: element.accountNumber,
                  label: `${element.accountNumber} | ${element.alias}`
                } as SelectOption))
              }
              onChange={(val) => setAccountTmp(val)}
            />
          </Col>
        </Row>
      </GenericModal>
    </>
  );
};

const styles = StyleSheet.create({
  primaryContainer: {
    width: "100%",
    margin: "20px auto",
    padding: "20px",
    borderRadius: 10,
    backgroundColor: 'white',
    boxShadow: "rgba(177, 179, 185, 0.51) 5px 8px 24px 5px",
  },
  skeleton: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
  },
});

export default StadisticsHome;
