import dayjs, { Dayjs } from "dayjs";
import { Period } from "../types/types";
import { RangePickerProps } from "antd/es/date-picker";

export const disabledDate: RangePickerProps['disabledDate'] = (current) => {
	// Can not select days before today and today
	return current && current < dayjs().endOf('day');
  };

export const getJsDateFromExcel = (excelDate: number) => {
	// JavaScript dates can be constructed by passing milliseconds
	// since the Unix epoch (January 1, 1970) example: new Date(12312512312);

	// 1. Subtract number of days between Jan 1, 1900 and Jan 1, 1970, plus 1  (Google "excel leap year bug")             
	// 2. Convert to milliseconds.

	return new Date((excelDate - (25567 + 1)) * 86400 * 1000);
}

// convierte una fecha de formato aaaa-mm-dd y hora hh:mm
export const convertToDate = (date: string, hour: string) => {
	const [year, month, day] = date.split('-');
	const [hours, minutes] = hour.split(':');

	return new Date(
		+year,
		+month - 1,
		+day,
		+hours,
		+minutes
	);
};

export const convertFromDayjsDateAndHour = (date: Dayjs, hour: Dayjs) => {
	return new Date(
		+dayjs(date).year(),
		+dayjs(date).month(),
		+dayjs(date).date(),
		+dayjs(hour).hour(),
		+dayjs(hour).minute()
	);
};

export const convertFromDayjsToDate = (dateTime: Dayjs) => {
	return new Date(
		+dayjs(dateTime).year(),
		+dayjs(dateTime).month(),
		+dayjs(dateTime).date(),
		+dayjs(dateTime).hour(),
		+dayjs(dateTime).minute()
	);
};

export const isValidDate = (dateString: string) => {
	const dateParts = dateString.split('-');

	if (dateParts.length === 3) {
		// Los componentes de la fecha son válidos
		const day = parseInt(dateParts[2], 10);
		const month = parseInt(dateParts[1], 10) - 1; // Restamos 1 porque los meses en JavaScript comienzan desde 0 (enero = 0)
		const year = parseInt(dateParts[0], 10);
		const inputDate = new Date(year, month, day);
		let dateFormated = dayjs(new Date()).format("YYYY-MM-DD")
		const dateParts2 = dateFormated.split('-');
		const currentDay = parseInt(dateParts2[2], 10);
		const currentMonth = parseInt(dateParts2[1], 10) - 1; // Restamos 1 porque los meses en JavaScript comienzan desde 0 (enero = 0)
		const currentYear = parseInt(dateParts2[0], 10);
		const currentDate = new Date(currentYear, currentMonth, currentDay);
		if (inputDate < currentDate) {
			return false;
		} else {
			return true;
		}

	} else {
		// La cadena no tiene el formato adecuado
		return false;
	}
}

export const isValidHour = (hourString: string | Dayjs, dateString: string | Dayjs) => {
	const currentTime = new Date();
	let day = 0;
	let month = 0;
	let year = 0;
	let hour = 0;
	let minutes = 0;

	// si el tipo recibido es string
	if (typeof (dateString) === "string" && typeof (hourString) === "string") {
		const dateParts = dateString.split('-');
		// Los componentes de la fecha son válidos
		day = parseInt(dateParts[2], 10);
		month = parseInt(dateParts[1], 10) - 1; // Restamos 1 porque los meses en JavaScript comienzan desde 0 (enero = 0)
		year = parseInt(dateParts[0], 10);
		const hourParts = hourString.split(':');
		hour = parseInt(hourParts[0], 10);
		minutes = parseInt(hourParts[1], 10);
	} else {
		const dateParts = dayjs(dateString).toDate()
		day = dateParts.getDate();
		month = dateParts.getMonth();
		year = dateParts.getFullYear();
		hour = dayjs(hourString).hour();
		minutes = dayjs(hourString).minute();
	}

	if (!isNaN(hour) && !isNaN(minutes) &&
		hour >= 0 && hour <= 23 &&
		minutes >= 0 && minutes <= 59) {
		const enteredDate = new Date();
		enteredDate.setDate(day);
		enteredDate.setMonth(month);
		enteredDate.setFullYear(year);
		enteredDate.setHours(hour);
		enteredDate.setMinutes(minutes);
		if (enteredDate > currentTime) {
			return true; // La hora ingresada es válida
		} else {
			return false; // La hora ingresada es menor o igual a la hora actual
		}
	} else {
		return false; // Formato de hora inválido
	}



}

export const validateEmail = (value: string) => {

	if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/i.test(value)) {
		return false;
	}
	return true;
};

export const validateRfc = (inputString: string) => {
	let reg =
		inputString.length === 12
			? "^(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))"
			: "^(([A-Z]|[a-z]|s){1})(([A-Z]|[a-z]){3})([0-9]{6})((([A-Z]|[a-z]|[0-9]){3}))";
	let regRFC = new RegExp(reg);
	let result = regRFC.test(inputString);
	if (result) {
		return false;
	} else {
		return true;
	}
};

export const isOutOfTime = () => {
	const dateNow = new Date();
	const dayNow = dateNow.getDay();
	const hourNow = dateNow.getHours();
	const hourStart: number = 6;
	const hourEnd: number = 22;
	// si se cumple la conficion es porque está fuera de horario y que no sea el dia 6 o 7 que 
	// es sabado o domingo
	if (hourNow >= hourStart && hourNow <= hourEnd && dayNow !== 6 && dayNow !== 7) {
		return false;
	} else {
		return true;
	}
}

export const isValidClabe = (inputValue: string) => {
	const regexToCLABE = new RegExp('^(\\d{3})(\\d{3})(\\d{11})(\\d)');
	return regexToCLABE.test(inputValue);
}

export const isValidDebit = (inputValue: string) => {
	const regexToDebit = new RegExp('^(?:4\\d{15}?|5[1-5][0-9]{14}|3[47][0-9]{13})$');
	return regexToDebit.test(inputValue);
}

export const isValidCel = (inputValue: string) => {
	const isValidToCel = new RegExp('^\\d{10}$');
	return isValidToCel.test(inputValue);
}

export const formatAmount = Intl.NumberFormat('es-MX', {
	style: 'currency',
	currency: 'MXN',
	useGrouping: true,
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
	notation: 'standard'
});

export const formatAmountNumber = Intl.NumberFormat('es-MX', {
	useGrouping: false,
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});



export interface PeriodsProps {
	year: "string";
	months: string[];
};

export interface PeriodInterface {
	periods: PeriodsProps[];
};

export const splicePeriodsLists = (periods: Period[]): PeriodInterface => {
	let periodsList: PeriodInterface = { periods: [] };

	periods.forEach((obj: Period) => {
		let periodsSplitted = obj.period.split("-");

		let index = periodsList.periods.findIndex(elem => elem.year === periodsSplitted[1]);

		if (index > -1) {
			periodsList.periods[index].months.push(periodsSplitted[0]);
		}
		else {
			periodsList.periods.push({ year: periodsSplitted[1], months: [periodsSplitted[0]] } as PeriodsProps);
		}
	});

	periodsList.periods.sort((obj, obj2) => Number.parseInt(obj2.year) >= Number.parseInt(obj.year) ? 1 : 0);

	periodsList.periods.forEach(obj => {
		obj.months.sort((elem1, elem2) => Number.parseInt(elem2) >= Number.parseInt(elem1) ? 1 : 0)
	});

	return periodsList;
};


export const labelMonth = (element: string): { month: string, label: string } => {
    const arrayOfMonths = [
      { month: "01", label: "Enero" },
      { month: "02", label: "Febrero" },
      { month: "03", label: "Marzo" },
      { month: "04", label: "Abril" },
      { month: "05", label: "Mayo" },
      { month: "06", label: "Junio" },
      { month: "07", label: "Julio" },
      { month: "08", label: "Agosto" },
      { month: "09", label: "Septiembre" },
      { month: "10", label: "Octubre" },
      { month: "11", label: "Noviembre" },
      { month: "12", label: "Diciembre" },
    ];
    const monthNumber = parseInt(element, 10);
    if (isNaN(monthNumber) || monthNumber < 1 || monthNumber > 12) {
      // Manejar el caso en el que el número de mes no sea válido
      return { month: "0", label: "Mes no válido" };
    }
    return arrayOfMonths[monthNumber - 1];
  };