//---------------------------------------------------------------------------------------------------------------------- Imports
import { Row, Card, Typography, Col, Table, Tag, Spin } from "antd";
import { StyleSheet } from "../../../StyleSheet";
import {
    DownCircleFilled, FileDoneOutlined, HourglassOutlined, InfoCircleOutlined,
    LoadingOutlined,
    PrinterOutlined, RedoOutlined, RollbackOutlined, SwapOutlined, UpCircleFilled
} from "@ant-design/icons";
import { AccountResponse, AccountType, ComprobanteRequest, Period, Status, TableParams, TransactionResume } from "../../../types/types";
import { ColumnsType, TableProps } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import {
    getAccountById, getAccounts, getBanks, getPeriodsList, getTransactionsByAccountAndPeriod, printReceiptment, printStatement
} from "../../../methods/axiosMethods";
import { useDispatch, useSelector } from "react-redux";
import { setInstitution } from "../../../store/slices/institutions";
import ModalEdoCuenta from "./ModalEdoCuenta";
import SubDefaultLayout from "../../../components/SubDefaultLayout";
import { subMenusOfAccounts } from "../../../commons/subMenus";
import SelectInput from "../../../components/SelectInput";
import Buttons from "../../../components/Buttons";
import CardButton from "../components/CardButton";
import GenericModal from "../../../components/GenericModal";
import ModalNotification, { AlertTypeNew } from "../../../components/ModalNotification";
import { PeriodInterface, labelMonth, splicePeriodsLists } from "../../../functions/Functions";
import { setAccount } from "../../../store/slices/account";
const { Text } = Typography;

//---------------------------------------------------------------------------------------------------------------------- Constants
const formatAmount = Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    useGrouping: true,
    minimumFractionDigits: 2,
    notation: 'standard'
});

//---------------------------------------------------------------------------------------------------------------------- View
const MyAccountsDetails = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const accData = useSelector((state: any) => state.account.accountsInfo);
    const [accountNumber, setAccountNumber] = useState("");
    const [ifpe, setIfpe] = useState("");
    const [name, setName] = useState("");
    const [balance, setBalance] = useState(0);
    const banksList = useSelector((state: any) => state.institutions.institutionsInfo);
    const [allPeriodsList, setAllPeriodsList] = useState<Array<Period>>([]);
    const [periods, setPeriods] = useState<PeriodInterface>({ periods: [] });
    const [allPeriods, setAllPeriods] = useState<PeriodInterface>({ periods: [] });
    const [indexOfCurrentYear, setIndexOfCurrentYear] = useState<number>(-1);
    const [indexOfCurrentMonth, setIndexOfCurrentMonth] = useState<number>(-1);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState<TransactionResume>();
    const [openModalEdoCuenta, setOpenModalEdoCuenta] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPrint, setIsLoadingPrint] = useState(false);
    const [accountType, setAccountType] = useState<AccountType>();
    const [transactionsList, setTransactionsList] = useState<Array<TransactionResume>>([]);
    const [notificationType, setNotificationType] = useState<AlertTypeNew>("SUCCESS");
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [accountData, setAccountData] = useState<AccountResponse>();
    const [message, setMessage] = useState('');
    const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            // Se define que inicia en la pag 1 y el tamaño de la pagina será de 10
            current: 1,
            pageSize: 10,
            showSizeChanger: false,
        },
    });
    const [monthSelected, setMonthSelected] = useState<string>("");


    const columns: ColumnsType<TransactionResume> = [
        {
            title: "Acciones",
            dataIndex: "operation",
            key: "operation",
            width: "25%",
            className: 'custom-header',
            render: (event, record: TransactionResume) => (
                <Row gutter={[16, 12]} >
                    <Col>
                        <Buttons
                            type={"default"}
                            title={"Más Detalles"}
                            action={() => showDrawer(record)}
                            color="#444cf9"
                            icon={<InfoCircleOutlined />} />
                    </Col>
                    {
                        record.status === "DONE" || record.status === "PROCESSING" ? (
                            <Col >
                                <Buttons
                                    type={"default"}
                                    title={"Imprimir"}
                                    action={() => printReceipt(record)}
                                    color="#fcba50"
                                    icon={<PrinterOutlined />} />
                            </Col>
                        ) : null
                    }
                </Row>
            ),
        },
        {
            title: "Tipo",
            dataIndex: "transactionType",
            key: "transactionType",
            className: 'custom-header',
            render: (text, record) => {
                const statusInfo: any = {
                    DONE: {
                        textColor: record.incoming > 0 ? "#2ed2a7" : "#ea915f",
                        tagText: record.incoming > 0 ? "Ingreso" : "Egreso",
                        icon: record.incoming > 0 ? <DownCircleFilled style={{ color: "#2ed2a7" }} /> : <UpCircleFilled style={{ color: "#ea915f" }} />,
                    },
                    REJECTED: {
                        textColor: "#ff5780",
                        tagText: "Rechazada",
                        icon: <InfoCircleOutlined style={{ color: "red" }} />,
                    },
                    CANCELED: {
                        textColor: "#fd0061",
                        tagText: "Cancelada",
                        icon: <InfoCircleOutlined style={{ color: "#fd0061" }} />,
                    },
                    CREATED: {
                        textColor: "#ff5780",
                        tagText: "Rechazada",
                        icon: <InfoCircleOutlined style={{ color: "red" }} />,
                    },
                    PROCESSING: {
                        textColor: "blue",
                        tagText: "Procesando",
                        icon: <HourglassOutlined style={{ color: "#00abfb" }} />,
                    },
                };

                const { tagText, icon, textColor } = statusInfo[record.status] || {};

                return (
                    <>
                        {icon && icon} &nbsp;&nbsp;
                        {textColor && (
                            <Text style={{ fontSize: 14, color: textColor }}>
                                {tagText}
                            </Text>
                        )}
                    </>
                );
            },
        },
        {
            title: "Fecha",
            dataIndex: "formatted",
            key: "formatted",
            className: 'custom-header',
        },
        {
            title: "Concepto de pago",
            dataIndex: "paymentConcept",
            key: "paymentConcept",
            className: 'custom-header',
        },
        {
            title: "Entidad Receptora",
            dataIndex: "receiverBank",
            key: "receiverBank",
            className: 'custom-header',
        },
        {
            title: "Cuenta Receptor",
            dataIndex: "receiverAccountNumber",
            key: "receiverAccountNumber",
            className: 'custom-header',
        },
        {
            title: "Monto",
            dataIndex: "incoming" || "outcoming",
            key: "incoming" || "outcoming",
            className: 'custom-header',
            render: (text, record) => (
                <span style={{ color: record.incoming > 0 ? "#2ed2a7" : "#ea915f" }}>
                    {record.incoming > 0 ?
                        (record.incoming !== 0 ?
                            formatAmount.format(record.incoming + record.iva) : '$0.00')
                        : (record.outcoming !== 0 ?
                            formatAmount.format(record.outcoming + record.iva) : '$0.00')}
                </span>
            ),
        },
    ];

    const labelChipcolor = (element: String) => {
        switch (element) {
            case 'DONE':
                return 'success';
            case 'REJECTED':
            case 'CANCELED':
            case 'CREATED':
                return 'error';
            case 'PROCESSING':
            case 'ACCEPTED':
                return 'processing';
            default: {
                return '';
            }
        }
    };

    const labelDescription = (element: String) => {
        switch (element) {
            case 'ACCEPTED':
                return 'EN CURSO';
            case 'LIQUIDADO':
                return 'LIQUIDADO';
            default: {
                return '';
            }
        }
    };

    const labelChip = (element: String) => {
        switch (element) {
            case 'DONE':
                return 'Realizada';
            case 'REJECTED':
                return 'Rechazada';
            case 'CANCELED':
                return 'Cancelada';
            case 'PROCESSING':
                return 'En curso';
            case 'CREATED':
                return 'Rechazada';
            case 'ACCEPTED':
                return 'En curso';
            default: {
                return '';
            }
        }
    };

    const labelChipcolorAccountType = (element: string) => {
        switch (element) {
            case 'CLABE':
                return 'green';
            case 'CEL':
                return 'blue';
            case 'DEBIT':
                return 'magenta';
            default: {
                return '';
            }
        }
    };

    const getAllPeriodsFromAccount = useCallback(() => {
        if (params !== undefined && params.accountNumber !== undefined) {
            setIsLoading(true);
            getPeriodsList(params.accountNumber)
                .then((response) => {
                    if (response.data && response.data.data) {
                        if (response.data.status === Status.OK) {
                            setAllPeriodsList(_.orderBy(response.data.data.periods, 'period', 'desc'));
                        }
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                    setNotificationType('ERROR');
                    setMessage("Hubo un error al obtener la lista de Periodos");
                    setShowNotification(true);
                })
        }
    }, [params]);

    const printReceipt = (transaction: TransactionResume) => {
        setIsLoadingPrint(true)
        let receiptReq: ComprobanteRequest = {
            trackingKey: transaction.trackingKey,
            accountNumberReceiver: transaction.receiverAccountNumber,
            accountNumberSource: transaction.sourceAccountNumber
        }
        printReceiptment(receiptReq)
            .then((response) => {
                setIsLoadingPrint(false);
            })
            .catch(() => {
                setIsLoadingPrint(false);
                setNotificationType('ERROR');
                setMessage("Hubo un error al imprimir el Comprobante de Pago, intente más tarde..");
                setShowNotification(true);
            })
    };

    const getTransactions = useCallback((yearIndex: number, monthIndex: number) => {
        if (params !== undefined && params.accountNumber !== undefined) {
            if (periods && periods.periods[yearIndex] && periods.periods[yearIndex].months[monthIndex]) {
                const periodComplete = `${periods.periods[yearIndex].months[monthIndex]}-${periods.periods[yearIndex].year}`;
                setIsLoading(true);
                getTransactionsByAccountAndPeriod(params.accountNumber, periodComplete)
                    .then((response) => {
                        if (response.data && response.data.data) {
                            if (response.data.status === Status.OK) {
                                setTransactionsList(response.data.data.transactions)
                            }
                        }
                        setIsLoading(false);
                    })
                    .catch(() => {
                        setIsLoading(false);
                        setNotificationType('ERROR');
                        setMessage("Hubo un error al obtener la lista de Transacciones, intente más tarde...");
                        setShowNotification(true);
                    })
            }
        }
    }, [periods, params]);

    const showDrawer = (record: TransactionResume) => {
        setOpenDrawer(true);
        setSelectedRecord(record);
    };

    const handleCloseModal = () => {
        setOpenModalEdoCuenta(false)
    };

    const handleTableChange: TableProps<TransactionResume>["onChange"] = (
        pagination,
    ) => {
        setTableParams({
            pagination,
        });
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
            setTransactionsList([]);
        }
    };

    const getStatement = (period: string) => {
        setIsLoading(true);
        printStatement(accountNumber, period)
            .then((response) => {
                setIsLoading(false);
                setOpenModalEdoCuenta(false);
            })
            .catch(() => {
                setOpenModalEdoCuenta(false);
                setIsLoading(false);
                setNotificationType('ERROR');
                setMessage("Hubo un error al descargar el estado de cuenta, intente más tarde.");
                setShowNotification(true);
            })
    };

    const handleChangeYear = (value: string) => {
        let index = periods.periods.findIndex(elem => elem.year === value);
        let indexRequest = 0;
        // si el arreglo de meses del index es mayor a 1, es para que se muestre el del index  del indexOfCurrentMonth
        // sino el index que llamará será el 0 (es para cuando solo tiene enero)
        if (periods && periods.periods && index >= 0 &&
            periods.periods[index].months && periods.periods[index].months.length > 1) {
            indexRequest = indexOfCurrentMonth;
        }
        setIndexOfCurrentYear(index);
        setMonthSelected(periods.periods[index].months[indexRequest]);
        getTransactions(index, indexRequest);
    };

    const handleChangeMonth = (value: string) => {
        setMonthSelected(value)
        let index = periods.periods[indexOfCurrentYear].months.findIndex(elem => elem === value)
        setIndexOfCurrentMonth(index);
        getTransactions(indexOfCurrentYear, index);
    };

    const getAccountsList = useCallback(() => {
        setIsLoading(true);
        getAccounts()
            .then((response) => {
                if (response.data && response.data.data) {
                    if (response.data.status === Status.OK && !_.isEmpty(response.data.data)) {
                        dispatch(setAccount({
                            accountsInfo: response.data.data
                        }));
                    }
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                setNotificationType('ERROR');
                setMessage("Hubo un error al obtener la lista de cuentas, intente más tarde.");
                setShowNotification(true);
            })
    }, [dispatch]);

    useEffect(() => {
        getAccountsList();
    }, [getAccountsList]);

    useEffect(() => {
        if (banksList && _.isEmpty(banksList)) {
            getBanks()
                .then((response) => {
                    if (response.data.status === Status.OK && response.data.data && !_.isEmpty(response.data.data)) {
                        dispatch(setInstitution({ institutionsInfo: response.data.data }))
                    }
                })
                .catch(() => {
                    setNotificationType('ERROR');
                    setMessage("Hubo un error al obtener la lista Instituciones, intente más tarde...");
                    setShowNotification(true);
                });
        }
    }, [banksList, dispatch]);

    useEffect(() => {
        if (params !== undefined && params.accountNumber !== undefined
            && !_.isEmpty(accData)) {
            let group = _.find(accData, { accountNumber: params.accountNumber });
            setName(group.name);
            setAccountType(group.accountType);
            setAccountNumber(group.accountNumber);
            setIfpe(group.bank);
            setBalance(group.balance.balance)
        } else if (params !== undefined && params.accountNumber !== undefined && !_.isEmpty(banksList)) {
            setIsLoading(true);
            getAccountById(params.accountNumber)
                .then((response) => {
                    if (response.data.status === Status.OK) {
                        setName(response.data.data.name);
                        setAccountType(response.data.data.accountType);
                        setAccountNumber(response.data.data.accountNumber);
                        setBalance(response.data.data.balance.balance);
                        let bankData = _.find(banksList, { bankCode: response.data.data.bank });
                        setIfpe(bankData ? bankData.bankName : "");
                        setAccountData(response.data.data);
                    }
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                    setNotificationType('ERROR');
                    setMessage("Hubo un error al obtener el detalle de la cuenta, intente más tarde...");
                    setShowNotification(true);
                });
        }
    }, [banksList, params, accData]);

    useEffect(() => {
        getAllPeriodsFromAccount();
    }, [getAllPeriodsFromAccount]);

    useEffect(() => {
        if (!_.isEmpty(allPeriodsList)) {
            setPeriods(splicePeriodsLists(allPeriodsList));
        }
    }, [allPeriodsList]);

    useEffect(() => {
        if (!_.isEmpty(allPeriodsList)) {
            setAllPeriods(splicePeriodsLists(allPeriodsList));
        }
    }, [allPeriodsList]);

    useEffect(() => {
        if (!_.isEmpty(periods.periods)) {
            setIndexOfCurrentYear(0);
            setIndexOfCurrentMonth(0);
            getTransactions(0, 0);
        }
    }, [periods, getTransactions]);

    const ListItem = ({ title, value }: any) => (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', margin: '10px' }}>
            <span style={{ fontWeight: 'bold' }}>
                {`${title}`}
            </span>
            <span style={{ lineHeight: '25px' }}>
                {value}
            </span>
        </div>
    );

    const handleOpenStatement = () => {
        if (periods && !_.isEmpty(periods.periods)) {
            setOpenModalEdoCuenta(true);
        } else {
            setShowNotification(true);
            setMessage("No hay periodos para consultar.");
            setNotificationType('INFO');
        }
    }

    return (
        <SubDefaultLayout childrenSubMenu={subMenusOfAccounts} title="Detalles">
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} xxl={7}>
                    <Card style={styles.cardStyle}>
                        <Row>
                            <Col span={24}>
                                <Text style={styles.accountName}>
                                    {name}
                                </Text>
                            </Col>
                            <Col span={24}>
                                {`${ifpe} | ${accountType} ${accountNumber}`}
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={12} xxl={5}>
                    <Card style={styles.cardStyle}>
                        <Row>
                            <Col span={24}>
                                Saldo Total
                            </Col>
                            <Col span={24}>
                                <Text style={styles.amountTotal}>
                                    {balance && balance !== 0 ? formatAmount.format(balance) : '$0.00'}
                                </Text>
                            </Col>
                            <Col span={24}>
                                MXN
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={12} xxl={4}>
                    <CardButton
                        text={"Transferencia"}
                        color={"#f1eefc"}
                        icon={<SwapOutlined style={{ ...styles.iconStyles, color: "#765ce9" }} />}
                        action={() => navigate("/transfers/immediate", { state: accountData })}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} xxl={4}>
                    <CardButton
                        text={"Estado de Cuenta"}
                        color={"#f1eefc"}
                        icon={<FileDoneOutlined style={{ ...styles.iconStyles, color: "#165bd0" }} />}
                        action={() => handleOpenStatement()}
                    />
                </Col>
                <Col xs={24} sm={24} md={12} xxl={4}>
                    <CardButton
                        text={"Regresar a Mis Cuentas"}
                        color={"#f1eefc"}
                        icon={<RollbackOutlined style={{ ...styles.iconStyles, color: "#ff3d6b" }} />}
                        action={() => navigate("/admin/accounts")}
                    />
                </Col>
                <Col span={24}>
                    <div style={styles.primaryContainer}>
                        <Row gutter={[16, 16]} align={"middle"}>
                            {(periods && periods.periods[indexOfCurrentYear]) && (
                                <>
                                    <Col xs={24} lg={8} xxl={9}>
                                        Detalle de Movimientos
                                    </Col>
                                    <Col xs={24} lg={6} xxl={6}>
                                        <SelectInput
                                            label={"Año"}
                                            value={periods.periods[indexOfCurrentYear].year}
                                            options={periods.periods.map((elem) => ({
                                                label: elem.year,
                                                value: elem.year
                                            }))}
                                            onChange={(value) => handleChangeYear(value)}
                                        />
                                    </Col>
                                    <Col xs={24} lg={6} xxl={6}>
                                        <SelectInput
                                            label={"Mes"}
                                            value={monthSelected}
                                            options={periods.periods[indexOfCurrentYear].months.map(elem => ({
                                                value: elem,
                                                label: labelMonth(elem).label
                                            }))}
                                            onChange={(value) => handleChangeMonth(value)}
                                        />
                                    </Col>
                                    <Col xs={24} lg={4} xxl={3} style={{ display: "flex", justifyContent: "center" }}>
                                        <Buttons
                                            type="primary"
                                            color="#3d84ff"
                                            action={() => getTransactions(indexOfCurrentYear, indexOfCurrentMonth)}
                                            icon={<RedoOutlined />}
                                            title="Actualizar"
                                        />
                                    </Col>
                                </>
                            )}
                        </Row>
                        <Row style={styles.tableContainer}>
                            <Col span={24}>
                                <Table
                                    bordered
                                    dataSource={transactionsList}
                                    columns={columns}
                                    onChange={handleTableChange}
                                    scroll={{ y: 400, x: 700 }}
                                    locale={{ emptyText: "No hay datos disponibles" }}
                                    pagination={tableParams.pagination}
                                    loading={{
                                        spinning: isLoading || isLoadingPrint,
                                        indicator: <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />,
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <GenericModal
                show={openDrawer}
                title="Más detalles"
                showActionButtons={false}
                onClose={() => setOpenDrawer(false)}
            >
                {
                    isLoadingPrint ? (
                        <div style={styles.contentView}>
                            <Spin
                                indicator={antIcon}
                                tip="Cargando..."
                                style={styles.loading}
                            />
                        </div>
                    ) : (
                        <Row style={{ margin: "20px 0px 20px 0px" }}>
                            {selectedRecord &&
                                <Col span={18}>
                                    <ListItem title="Fecha y Hora" value={selectedRecord.date} />
                                    <ListItem title="IFPE Emisor" value={selectedRecord.sourceBank} />
                                    <ListItem title="Cuenta Emisor" value={selectedRecord.sourceAccountNumber} />
                                    <ListItem title="Nombre" value={selectedRecord.sourceName} />
                                    <ListItem title="R.F.C" value={selectedRecord.rfcOrCurp} />
                                    <ListItem title="Referencia" value={selectedRecord.reference} />
                                    <ListItem title="Clave de Rastreo" value={selectedRecord.trackingKey} />
                                    <ListItem title="Monto" value={formatAmount.format(selectedRecord.incoming !== 0 ? selectedRecord.incoming : selectedRecord.outcoming)} />
                                    <ListItem title="IVA" value={formatAmount.format(selectedRecord.iva)} />
                                    <ListItem title="Usuario" value={selectedRecord.username} />
                                    <div style={{ display: 'flex', flexDirection: 'row', flexFlow: 'wrap' }}>
                                        <Tag
                                            style={{ fontSize: 14 }}
                                            color={labelChipcolor(selectedRecord.status)}
                                        >
                                            {labelChip(selectedRecord.status)}
                                        </Tag>
                                        <Tag
                                            style={{ fontSize: 14 }}
                                            color={labelChipcolorAccountType(selectedRecord.accountType)}
                                        >
                                            {
                                                selectedRecord.accountType === "CLABE"
                                                    ? "CLABE"
                                                    : selectedRecord.accountType === "CEL"
                                                        ? "Teléfono celular"
                                                        : selectedRecord.accountType === "DEBIT"
                                                            ? "Tarjeta de Debito"
                                                            : ""
                                            }
                                        </Tag>
                                    </div>
                                    {selectedRecord.description !== '' ? (
                                        <ListItem title="Descripción" value={labelDescription(selectedRecord.description)} />
                                    ) : null}
                                </Col>
                            }
                            {
                                selectedRecord?.status === "DONE" || selectedRecord?.status === "PROCESSING" ?
                                    <Col span={6}>
                                        <Row justify={"center"}>
                                            <div style={styles.iconContainer}>
                                                <div
                                                    style={{ ...styles.iconButtonStyle, backgroundColor: "#ebf9fc" }}
                                                    onClick={() => selectedRecord && printReceipt(selectedRecord)}
                                                >
                                                    <PrinterOutlined style={styles.iconStyles} />
                                                </div>
                                                <Text>Imprimir</Text>
                                            </div>
                                        </Row>
                                    </Col>
                                    : null
                            }
                        </Row>
                    )
                }
            </GenericModal>
            <ModalEdoCuenta
                openModal={openModalEdoCuenta}
                handleClose={() => handleCloseModal()}
                periods={allPeriods}
                printStatement={getStatement}
                isLoading={isLoading}
            />
            <ModalNotification
                title={"Detalle"}
                message={message}
                alertType={notificationType}
                show={showNotification}
                onClose={() => setShowNotification(false)}
            />
        </SubDefaultLayout >
    );
}

//---------------------------------------------------------------------------------------------------------------------- Styles
const styles = StyleSheet.create({
    cardStyle: {
        boxShadow: "0px 0px 8px #00000029",
        borderRadius: 15,
        border: "none",
        height: "100%",
        display: "flex",
        alignItems: "center",
    },
    primaryContainer: {
        padding: "20px",
        borderRadius: 10,
        backgroundColor: 'white',
        boxShadow: "rgba(177, 179, 185, 0.51) 5px 8px 24px 5px",
    },
    tableContainer: {
        marginTop: "40px"
    },
    iconStyles: {
        fontSize: 25,
    },
    iconContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 20,
    },
    iconButtonStyle: {
        display: "flex",
        width: 70,
        height: 70,
        marginBottom: 10,
        borderRadius: "50%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#ff00ff",
        cursor: "pointer",
    },
    accountName: {
        fontSize: 18,
        fontWeight: 600,
        color: '#4242ff'
    },
    amountTotal: {
        fontSize: 28,
        fontWeight: 600,
        color: '#4242ff'
    },
    contentView: {
        width: "100%",
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    loading: {
        maxHeight: "none",
    },
});

//---------------------------------------------------------------------------------------------------------------------- Exports
export default MyAccountsDetails;
