import React, { useCallback, useEffect, useRef } from "react";
import { Col, Divider, Row, Skeleton, Spin, Steps } from "antd";
import { StyleSheet } from "../../../../StyleSheet";
import { ArrowLeftOutlined, ArrowRightOutlined, CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { editSchedulersTransactions, getAccounts, getBanks, getSchedulerById } from "../../../../methods/axiosMethods";
import { AccountDest, AccountOrigin, AccountResponse, AccountType, CatalogBankResponse, DispersionData, Institution, Status, TransferObj } from "../../../../types/types";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setAccount } from "../../../../store/slices/account";
import dayjs from "dayjs";
import { setInstitution } from "../../../../store/slices/institutions";
import { convertToDate, formatAmount, isOutOfTime, isValidCel, isValidClabe, isValidDebit } from "../../../../functions/Functions";
import SubDefaultLayout from "../../../../components/SubDefaultLayout";
import { subMenusOfTransfers } from "../../../../commons/subMenus";
import InfoCard from "../../../../components/InfoCard";
import Buttons from "../../../../components/Buttons";
import CardDataTransfer from "../../../../components/CardDataTransfer";
import { image } from "../../../../commons/ImageRoutes";
import Input from "../../../../components/Input";
import SelectInput, { SelectOption } from "../../../../components/SelectInput";
import TransferConfirmation from "../../immediate/TransferConfirmation";
import TransferResume from "../../immediate/TransferResume";
import GenericModal from "../../../../components/GenericModal";
import ModalNotification, { AlertTypeNew } from "../../../../components/ModalNotification";
import InputAmount from "../../../../components/InputAmount";

const EditTransferScheduler = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const bankTypes = [
        { value: 'CLABE', label: 'CLABE' },
        { value: 'DEBIT', label: 'Tarjeta de Debito' },
        { value: 'CEL', label: 'Teléfono celular' }
    ];

    const accOriginEmpty: AccountOrigin = {
        accountName: "",
        accountNumber: ""
    }

    const transferObjEmpty: TransferObj = {
        conceptDest: "",
        radioOpt: 0,
        numRefDest: null,
        amountDest: null,
        ivaDest: null,
    }

    const accDestEmpty: AccountDest = {
        accountName: "",
        accountNumber: "",
        bankDataDest: undefined,
        accType: undefined,
        radioOpt: 0
    }

    const [loading, setLoading] = React.useState(false);
    const ownAccList = useSelector((state: any) => state.account.accountsInfo);
    const banksList = useSelector((state: any) => state.institutions.institutionsInfo);
    const [bankList, setBankList] = React.useState<CatalogBankResponse[]>([]);
    const [ownAccountsList, setOwnAccountsList] = React.useState<Array<AccountResponse>>([]);
    const [notificationType, setNotificationType] = React.useState<AlertTypeNew>("SUCCESS");
    const [showNotification, setShowNotification] = React.useState<boolean>(false);
    const [shouldNavigate, setShouldNavigate] = React.useState<boolean>(false);
    const [missingData, setHasMissingData] = React.useState<boolean>(false);
    const [transferFinished, setTransferFinished] = React.useState<boolean>(false);
    const [isLoadingData, setIsLoadingData] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState("");
    const [transferId, setTransferId] = React.useState("");
    const [nameForm, setNameForm] = React.useState("");
    const [accNumberForm, setAccNumberForm] = React.useState("");
    const [accNumberOrigin, setAccNumberOrigin] = React.useState("");
    const [bankForm, setBankForm] = React.useState("");
    const [typeForm, setTypeForm] = React.useState("");
    const [conceptForm, setConceptForm] = React.useState("");
    const [numRefForm, setNumRefForm] = React.useState<string>("");
    const [amountForm, setAmountForm] = React.useState<string>("");
    const [ivaForm, setIvaForm] = React.useState<string>("");
    const [currentStep, setCurrentStep] = React.useState(0);
    const [tokenForm, setTokenForm] = React.useState("");
    const [changeOriginAcc, setChangeOriginAcc] = React.useState(false);
    const [changeDestAcc, setChangeDestAcc] = React.useState(false);
    const [dateForm, setDateForm] = React.useState("");
    const [hourForm, setHourForm] = React.useState("");
    const [currentOriginAccIndex, setCurrentOriginAccIndex] = React.useState(0);
    const [accOriginTmp, setAccOriginTmp] = React.useState("");
    const [accOriginData, setAccOriginData] = React.useState<AccountOrigin>(accOriginEmpty);
    const [accDestDataForm, setAccDestDataForm] = React.useState<TransferObj>(transferObjEmpty);
    const [accDestData, setAccDestData] = React.useState<AccountDest>(accDestEmpty);
    const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
    const inputRefAmount = useRef<HTMLInputElement>(null);
    const inputRefIva = useRef<HTMLInputElement>(null);

    const navigateNotification = () => {
        navigate("/transfers/scheduled")
    };

    useEffect(() => {
        if (banksList && _.isEmpty(banksList)) {
            setLoading(true);
            getBanks()
                .then((response) => {
                    if (response.data.status === Status.OK && response.data.data && !_.isEmpty(response.data.data)) {
                        dispatch(setInstitution({ institutionsInfo: response.data.data }))
                    }
                    setLoading(false);
                })
                .catch(() => {
                    setNotificationType('ERROR');
                    setMessage("No fue posible obtener las instituciones bancarias en este momento, intente más tarde.");
                    setShouldNavigate(true);
                    setShowNotification(true);
                    setLoading(false);
                });
        } else {
            setBankList(banksList);
        }
    }, [banksList, dispatch]);

    const getAccountsList = useCallback(async () => {
        setLoading(true);
        getAccounts()
            .then((response) => {
                if (response.data && response.data.data) {
                    if (response.data.status === Status.OK && !_.isEmpty(response.data.data)) {
                        // para mostrar solo las cuentas habilitadas
                        let ownAcctListEnable = response.data.data.filter((data: AccountResponse) => data.enabled === true);
                        setOwnAccountsList(ownAcctListEnable)
                        dispatch(setAccount({
                            accountsInfo: response.data.data
                        }));
                    } else {
                        setOwnAccountsList(response.data.data)
                    }
                }
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                setNotificationType('ERROR');
                setMessage("No fue posible obtener la lista de cuentas, intente más tarde.");
                setShowNotification(true);
            })
    }, [dispatch])

    useEffect(() => {
        if (_.isEmpty(ownAccList)) {
            getAccountsList();
        } else {
            let ownAcctListEnable = ownAccList.filter((data: AccountResponse) => data.enabled === true);
            setOwnAccountsList(ownAcctListEnable);
        }
    }, [getAccountsList, ownAccList])

    const getData = useCallback(async (transferId: string) => {
        setTransferId(transferId);
        setIsLoadingData(true);
        await getSchedulerById(transferId)
            .then((response) => {
                if (response.data && response.status === Status.OK) {
                    setAccNumberOrigin(response.data.dispersionData.accountNumber);
                    setNameForm(response.data.dispersionData.name);
                    setAccNumberForm(response.data.dispersionData.foreignAccountNumber);
                    setBankForm(response.data.dispersionData.foreignBankId);
                    let accType = response.data.dispersionData.accountType === 40 ? "CLABE" :
                        response.data.dispersionData.accountType === 3 ? "DEBIT" : "CEL";
                    setTypeForm(accType);
                    setConceptForm(response.data.dispersionData.paymentConcept);
                    setNumRefForm(response.data.dispersionData.reference.toString());
                    setAmountForm(response.data.dispersionData.balance.toFixed(2).toString());
                    setIvaForm(response.data.dispersionData.iva.toFixed(2).toString());
                    setDateForm(dayjs(response.data.dispersionData.schedulerDate).format("YYYY-MM-DD"))
                    setHourForm(dayjs(response.data.dispersionData.schedulerDate).format("HH:mm"))
                }
                setIsLoadingData(false);
            })
            .catch(() => {
                setIsLoadingData(false);
                setNotificationType('ERROR');
                setMessage("No fue posible obtener la información de la transferencia, intente más tarde.");
                setShouldNavigate(true);
                setShowNotification(true);
            })
    }, [])

    useEffect(() => {
        // buscamos la cuenta de origen en las cuentas propias
        let ownAccount = _.find(ownAccountsList, { accountNumber: accNumberOrigin });
        if (ownAccount) {
            setAccOriginData({
                accountName: ownAccount.alias,
                accountNumber: ownAccount.accountNumber
            })
        }
        setCurrentOriginAccIndex(ownAccountsList.findIndex((element) => element.accountNumber === accNumberOrigin))
    }, [accNumberOrigin, ownAccountsList])

    useEffect(() => {
        if (params && params.transferId) {
            getData(params.transferId)
        }
    }, [params, getData]);

    useEffect(() => {
        let bankData: CatalogBankResponse;
        const codeType: Array<CatalogBankResponse> = _.filter(bankList, { bankCode: bankForm });
        if (!_.isEmpty(codeType)) {
            bankData = codeType[0];
        }
        let squeduledDateTime = convertToDate(dateForm, hourForm);
        setAccDestDataForm(data => ({
            ...data,
            rfcDest: "",
            amountDest: amountForm,
            conceptDest: conceptForm,
            numRefDest: numRefForm,
            ivaDest: ivaForm,
            dateTimeDest: squeduledDateTime
        }));
        setAccDestData(data => ({
            ...data,
            accountName: nameForm,
            accountNumber: accNumberForm,
            accNameOrigin: accOriginData.accountName,
            accNumOrigin: accOriginData.accountNumber,
            radioOpt: 3,
            accType: typeForm as AccountType,
            bankDataDest: bankData,
        }));
    }, [accOriginData, typeForm,
        accNumberForm,
        currentOriginAccIndex, nameForm, ownAccountsList,
        amountForm, conceptForm, ivaForm, numRefForm, bankForm, bankList,
        dateForm, hourForm
    ])

    const onChangeStep = (value: number) => {
        let allIsCompleted = false;
        if (accOriginData.accountNumber === accDestData.accountNumber && (!_.isEmpty(accOriginData.accountNumber) && !_.isEmpty(accDestData.accountNumber))) {
            setNotificationType('INFO');
            setMessage("No se permite elegir la misma cuenta de origen y de destino.");
            setShouldNavigate(false);
            setShowNotification(true);
            // Si es el caso de transfer entre ctas propias o terceros
        }
        else {
            // si la cuenta de destino es menor a 10 digitos o mayor a 18
            if (accDestData.accountNumber && (accDestData.accountNumber.toString().length < 10 || accDestData.accountNumber.toString().length > 18)) {
                setNotificationType('INFO');
                setMessage("El número de cuenta no puede ser menor a 10 dígitos o mayor a 18 dígitos.");
                setHasMissingData(true)
                setShouldNavigate(false);
                setShowNotification(true);
            }
            // revisar todos los campos
            else if (!_.isEmpty(accOriginData.accountNumber) && !_.isEmpty(accDestData.accountName) && !_.isEmpty(accDestData.accountNumber)
                && !_.isEmpty(accDestData.bankDataDest) && !_.isEmpty(accDestData.accType)
                && !_.isEmpty(accDestDataForm.conceptDest)
                && !_.isEmpty(accDestDataForm.amountDest) && !_.isEmpty(accDestDataForm.ivaDest)
            ) {
                let isValidAcc = false;
                switch (typeForm) {
                    case "CLABE":
                        isValidAcc = isValidClabe(accDestData.accountNumber)
                        break;
                    case "DEBIT":
                        isValidAcc = isValidDebit(accDestData.accountNumber)
                        break;
                    case "CEL":
                        isValidAcc = isValidCel(accDestData.accountNumber)
                        break;
                }
                if (!isValidAcc) {
                    setNotificationType('INFO');
                    setMessage("El número de cuenta y/o el tipo de cuenta no son validos.");
                    setShouldNavigate(false);
                    setShowNotification(true);
                }
                else {
                    allIsCompleted = true;
                }
            } else {
                setNotificationType('INFO');
                setMessage("Hay campos obligatorios incompletos.");
                setHasMissingData(true)
                setShouldNavigate(false);
                setShowNotification(true);
            }
        }
        if (allIsCompleted) {
            if (value === 0 || value === 1) {
                setCurrentStep(value);
            }
            if (value === 2) {
                setCurrentStep(value);
            }
        }
        if (value === 3 && tokenForm.length === 6) {
            editSqueduledTransfer();
        }
        if (value === 2) {
            setTokenForm("");
        }
    };

    const handleSaveButton = () => {
        switch (currentStep) {
            case 0:
                // cuando eligen la misma cuenta de origen que de destino
                if (accOriginData.accountNumber === accDestData.accountNumber && (!_.isEmpty(accOriginData.accountNumber) && !_.isEmpty(accDestData.accountNumber))) {
                    setNotificationType('INFO');
                    setMessage("No se permite elegir la misma cuenta de origen y de destino.");
                    setShouldNavigate(false);
                    setShowNotification(true);
                } else {
                    let hasError = false;
                    // si la cuenta de destino es menor a 10 digitos o mayor a 18
                    if (accDestData.accountNumber && (accDestData.accountNumber.toString().length < 10 || accDestData.accountNumber.toString().length > 18)) {
                        setNotificationType('INFO');
                        setMessage("El número de cuenta no puede ser menor a 10 dígitos o mayor a 18 dígitos.");
                        setHasMissingData(true)
                        setShouldNavigate(false);
                        setShowNotification(true);
                    }
                    // revisar todos los campos
                    else if (!_.isEmpty(accOriginData.accountNumber) && !_.isEmpty(accDestData.accountName) && !_.isEmpty(accDestData.accountNumber)
                        && !_.isEmpty(accDestData.bankDataDest) && !_.isEmpty(accDestData.accType)
                        && !_.isEmpty(accDestDataForm.conceptDest)
                        && !_.isEmpty(accDestDataForm.amountDest) && !_.isEmpty(accDestDataForm.ivaDest)
                    ) {
                        let isValidAcc = false;
                        switch (typeForm) {
                            case "CLABE":
                                isValidAcc = isValidClabe(accDestData.accountNumber)
                                break;
                            case "DEBIT":
                                isValidAcc = isValidDebit(accDestData.accountNumber)
                                break;
                            case "CEL":
                                isValidAcc = isValidCel(accDestData.accountNumber)
                                break;
                        }
                        if (!isValidAcc) {
                            setNotificationType('INFO');
                            setMessage("El número de cuenta y/o el tipo de cuenta no son validos.");
                            setShouldNavigate(false);
                            setShowNotification(true);
                            hasError = true;
                        }
                    } else {
                        hasError = true;
                        setMessage("Hay campos obligatorios incompletos.");
                    }
                    if (hasError) {
                        setNotificationType('INFO');
                        setHasMissingData(true)
                        setShouldNavigate(false);
                        setShowNotification(true);
                    } else {
                        setCurrentStep(1);
                    }
                }
                break;
            case 1:
                setCurrentStep(2);
                setTokenForm("");
                break;
            case 2:
                editSqueduledTransfer();
                break;
        }
    };

    const goBack = () => {
        navigate('/transfers/scheduled');
    };

    const goBackStepButton = () => {
        setCurrentStep(currentStep - 1);
    };

    const editSqueduledTransfer = () => {
        let objTransferReq: DispersionData = {
            accountNumber: accOriginData.accountNumber,
            foreignAccountNumber: accDestData.accountNumber,
            foreignBankId: accDestData.bankDataDest?.bankCode ? accDestData.bankDataDest?.bankCode : "",
            reference: Number(accDestDataForm.numRefDest),
            name: accDestData.accountName,
            paymentConcept: accDestDataForm.conceptDest,
            accountType: accDestData.accType === "CLABE" ? 40 :
                accDestData.accType === "DEBIT" ? 3 : 10,
            paymentType: 1,
            rfcOrCurp: "",
            balance: Number(accDestDataForm.amountDest),
            iva: Number(accDestDataForm.ivaDest),
            schedulerDate: accDestDataForm.dateTimeDest,
            date: accDestDataForm.dateTimeDest ? accDestDataForm.dateTimeDest : new Date(),
            token: tokenForm
        }
        setLoading(true);
        editSchedulersTransactions(transferId, objTransferReq)
            .then((response) => {
                if (response) {
                    if (response.status === Status.OK
                        && !_.isEmpty(response.data)
                    ) {
                        setNotificationType('SUCCESS');
                        setShouldNavigate(false);
                        setMessage(response.message);
                        setCurrentStep(3);
                        setTransferFinished(true);
                    } else {
                        setNotificationType('ERROR');
                        setShouldNavigate(false);
                        setMessage(response.error.message);
                        setCurrentStep(1);
                    }
                    setShowNotification(true);
                }
                setLoading(false);
            })
            .catch(() => {
                setCurrentStep(1);
                setLoading(false);
                setShowNotification(true);
                setNotificationType('ERROR');
                setShouldNavigate(false);
                setMessage("Hubo un error al realizar la transferencia");
                setTokenForm("");
            })
    };

    const handleChangeToken = (inputValue: string) => {
        const regex = /^[0-9]+$/;
        // Si el valor coincide con la expresión regular o es una cadena vacía, actualiza el estado
        if (regex.test(inputValue) || inputValue === "") {
            setTokenForm(inputValue);
        }
    };

    const handleChangeBank = (element: string) => {
        const values = Object.values(Institution)
        values.forEach((value) => {
            if (value === element) {
                setBankForm(value);
            }
        });
    };

    const handleChangeTypeForm = (element: string) => {
        setTypeForm(element)
    };

    const handleChangeAccNumber = (inputValue: string) => {
        let typeForm = "CLABE";
        let bankCode = "";
        setAccNumberForm(inputValue.replace(/[^0-9]/g, ''));

        if (isValidCel(inputValue)) {
            typeForm = "CEL";
        }
        if (isValidDebit(inputValue)) {
            typeForm = "DEBIT";
        }
        if (isValidClabe(inputValue)) {
            const codeType: Array<CatalogBankResponse> = _.filter(bankList, { code: inputValue.slice(0, 3) });
            if (!_.isEmpty(codeType)) {
                bankCode = codeType[0].bankCode;
                typeForm = "CLABE"
            }
            setBankForm(bankCode);
        }
        setTypeForm(typeForm);
    };

    return (
        <>
            <SubDefaultLayout childrenSubMenu={subMenusOfTransfers} title="Transferencias Inmediatas">
                {
                    loading ?
                        <div style={styles.contentView}>
                            <Spin
                                indicator={antIcon}
                                tip="Cargando..."
                                style={styles.loading}
                            />
                        </div>
                        :
                        <>
                            <Row gutter={[12, 12]}>
                                <Col xs={24} md={15} lg={6}>
                                    <Steps
                                        percent={100}
                                        direction="vertical"
                                        current={currentStep}
                                        onChange={!transferFinished ? onChangeStep : undefined}
                                        items={[
                                            {
                                                title: 'Datos de la Transferencia',
                                                description: "Emisor-Receptor",
                                            },
                                            {
                                                title: 'Confirmación',
                                                description: 'Confirmación de Datos',
                                            },
                                            {
                                                title: 'Token',
                                                description: "Validación de Token",
                                            },
                                            {
                                                title: 'Recibo',
                                                description: "Recibo de Transferencia",
                                            },
                                        ]}
                                    />
                                </Col>
                                <Col xs={24} lg={18}>

                                    <div style={styles.rowRoot}>
                                        {
                                            isOutOfTime() ?
                                                <div style={{ marginTop: -15, marginBottom: 15 }}>
                                                    <InfoCard
                                                        title="¡Importante!"
                                                        description="Si estás realizando esta operación fuera de horario hábil (lunes a viernes de 6:00 am a 11:0pm) la operación se
                hará efectivas el siguiente día hábil."
                                                        type="primary"
                                                    />
                                                </div>
                                                : null
                                        }

                                        <div style={styles.divContainer}>
                                            <Row gutter={[12, 12]} justify={"end"} style={{ paddingTop: 10, paddingBottom: 10 }}>
                                                {
                                                    !transferFinished ?
                                                        <>
                                                            {
                                                                currentStep !== 0 ?
                                                                    <Col sm={12} md={6} xl={4}>
                                                                        <Buttons
                                                                            type={"primary"}
                                                                            title={"Anterior"}
                                                                            action={goBackStepButton}
                                                                            iconLeft icon={<ArrowLeftOutlined />}
                                                                            color="#0101bc" />
                                                                    </Col>
                                                                    : null
                                                            }
                                                            <Col sm={12} md={6} xl={4}>
                                                                <Buttons
                                                                    type={"primary"}
                                                                    title={"Siguiente"}
                                                                    action={handleSaveButton}
                                                                    icon={<ArrowRightOutlined />}
                                                                    color="#3d3dff"
                                                                    isDisabled={currentStep === 2 && tokenForm.length < 6}
                                                                />
                                                            </Col>
                                                            <Col sm={12} md={6} xl={4}>
                                                                <Buttons
                                                                    type={"default"}
                                                                    title={"Cancelar"}
                                                                    action={goBack}
                                                                    icon={<CloseOutlined />}
                                                                    color="#ffb13d" />
                                                            </Col>
                                                        </>
                                                        :
                                                        <Col sm={12} md={6} xl={4} xxl={3} style={styles.endCol}>
                                                            <Buttons
                                                                type={"primary"}
                                                                title={"Finalizar"}
                                                                action={() => navigate('/transfers/scheduled')}
                                                                icon={<ArrowRightOutlined />}
                                                                color="#3d3dff" />
                                                        </Col>
                                                }
                                            </Row>
                                            <Divider style={styles.divider} />
                                            {
                                                currentStep === 0 ?
                                                    <>
                                                        <h2 style={styles.textTitle}>Datos de Transferencia</h2>
                                                        <Row gutter={[12, 12]}>
                                                            <Col xs={24} sm={11}>
                                                                <CardDataTransfer
                                                                    accountName={accOriginData.accountName}
                                                                    accountNumber={accOriginData.accountNumber}
                                                                    handleChangeAccount={() => setChangeOriginAcc(true)}
                                                                    backgroundColor={"#eaeaff"}
                                                                    fontColor={"#000089"}
                                                                    cardType="origin"
                                                                />
                                                            </Col>
                                                            <Col xs={24} sm={2} style={styles.colView}>
                                                                <img
                                                                    src={image.transferImmIconBlue}
                                                                    alt={"transferIcon"}
                                                                    style={styles.logoIcon}
                                                                />
                                                            </Col>
                                                            <Col xs={24} sm={11}>
                                                                <CardDataTransfer
                                                                    accountName={accDestData.accountName}
                                                                    accountNumber={accDestData.accountNumber}
                                                                    accountType={accDestData.accType}
                                                                    accountBankData={accDestData.bankDataDest}
                                                                    handleChangeAccount={() => setChangeDestAcc(true)}
                                                                    backgroundColor={"#fffeea"}
                                                                    fontColor={"#434343"}
                                                                    cardType="dest"
                                                                    isSqueduled
                                                                />
                                                            </Col>
                                                        </Row>
                                                        <h2 style={{ color: "#434343", marginTop: 30, paddingLeft: 20 }}>Tipo de Transferencia</h2>

                                                        {

                                                            <Row gutter={[16, 12]}>
                                                                <Col xs={24} sm={12} >
                                                                    {
                                                                        isLoadingData ?
                                                                            <Skeleton.Input block={true} />
                                                                            :
                                                                            <Input
                                                                                label="Nombre"
                                                                                value={nameForm}
                                                                                onChange={(element) => setNameForm(element)}
                                                                                required
                                                                                type={"text"}
                                                                                errorLabel={missingData && _.isEmpty(nameForm) ? "Este campo es obligatorio." : ""}
                                                                                withError={missingData && _.isEmpty(nameForm)}
                                                                            />
                                                                    }
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    {
                                                                        isLoadingData ?
                                                                            <Skeleton.Input block={true} />
                                                                            :
                                                                            <Input
                                                                                value={accNumberForm}
                                                                                label="CLABE / Tarjeta / Teléfono"
                                                                                onChange={(elem) => handleChangeAccNumber(elem)}
                                                                                required
                                                                                maxLength={18}
                                                                                type="text"
                                                                                errorLabel={missingData && _.isEmpty(accNumberForm) ? "Este campo es obligatorio." : ""}
                                                                                withError={missingData && _.isEmpty(accNumberForm)}
                                                                            />
                                                                    }
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    {
                                                                        isLoadingData ?
                                                                            <Skeleton.Input block={true} />
                                                                            :
                                                                            <SelectInput
                                                                                label="* Entidad"
                                                                                value={bankForm}
                                                                                options={bankList.map(data => ({
                                                                                    value: data.bankCode,
                                                                                    label: data.bankName
                                                                                }))}
                                                                                onChange={(e) => {
                                                                                    handleChangeBank(e);
                                                                                }}
                                                                            />
                                                                    }
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    {
                                                                        isLoadingData ?
                                                                            <Skeleton.Input block={true} />
                                                                            :
                                                                            <SelectInput
                                                                                label="* Tipo"
                                                                                value={typeForm}
                                                                                options={bankTypes.map(data => ({
                                                                                    value: data.value,
                                                                                    label: data.label
                                                                                }))}
                                                                                onChange={(e) => {
                                                                                    handleChangeTypeForm(e);
                                                                                }}
                                                                            />
                                                                    }
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <div style={styles.inputContainer}>
                                                                        {
                                                                            isLoadingData ?
                                                                                <Skeleton.Input block={true} />
                                                                                :
                                                                                <Input
                                                                                    value={conceptForm}
                                                                                    label="Concepto"
                                                                                    required
                                                                                    onChange={(element) => setConceptForm(element)}
                                                                                    type="text"
                                                                                    errorLabel={missingData && _.isEmpty(conceptForm) ? "Este campo es obligatorio." : ""}
                                                                                    withError={missingData && _.isEmpty(conceptForm)}
                                                                                />
                                                                        }
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <div style={styles.inputContainer}>
                                                                        {
                                                                            isLoadingData ?
                                                                                <Skeleton.Input block={true} />
                                                                                :
                                                                                <Input
                                                                                    maxLength={7}
                                                                                    value={numRefForm}
                                                                                    label="Referencia Numérica"
                                                                                    onChange={(elem) => setNumRefForm(elem.replace(/[^0-9]/g, ''))}
                                                                                    type="text"
                                                                                />
                                                                        }
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <div style={styles.inputContainer}>
                                                                        {
                                                                            isLoadingData ?
                                                                                <Skeleton.Input block={true} />
                                                                                :
                                                                                <InputAmount
                                                                                    value={formatAmount.format(Number(amountForm))}
                                                                                    label="Importe sin IVA"
                                                                                    onChange={(elem) => setAmountForm(elem)}
                                                                                    required
                                                                                    errorLabel={missingData && _.isEmpty(amountForm) ? "Este campo es obligatorio." : ""}
                                                                                    withError={missingData && _.isEmpty(amountForm)}
                                                                                    inputRef={inputRefAmount}
                                                                                />
                                                                        }
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <div style={styles.inputContainer}>
                                                                        {
                                                                            isLoadingData ?
                                                                                <Skeleton.Input block={true} />
                                                                                :
                                                                                <InputAmount
                                                                                    value={formatAmount.format(Number(ivaForm))}
                                                                                    label="IVA"
                                                                                    onChange={(elem) => setIvaForm(elem)}
                                                                                    required
                                                                                    errorLabel={missingData && _.isEmpty(ivaForm) ? "Este campo es obligatorio." : ""}
                                                                                    withError={missingData && _.isEmpty(ivaForm)}
                                                                                    inputRef={inputRefIva}
                                                                                />
                                                                        }
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <div style={styles.inputContainer}>
                                                                        {
                                                                            isLoadingData ?
                                                                                <Skeleton.Input block={true} />
                                                                                :
                                                                                <Input
                                                                                    value={dateForm}
                                                                                    label="Fecha"
                                                                                    required
                                                                                    onChange={() => undefined}
                                                                                    type="date"
                                                                                    disable
                                                                                    errorLabel={missingData && _.isEmpty(dateForm) ? "Este campo es obligatorio." : ""}
                                                                                    withError={missingData && _.isEmpty(dateForm)}
                                                                                />
                                                                        }
                                                                    </div>
                                                                </Col>
                                                                <Col xs={24} sm={12}>
                                                                    <div style={styles.inputContainer}>
                                                                        {
                                                                            isLoadingData ?
                                                                                <Skeleton.Input block={true} />
                                                                                :
                                                                                <Input
                                                                                    value={hourForm}
                                                                                    label="Hora"
                                                                                    required
                                                                                    onChange={() => undefined}
                                                                                    type="text"
                                                                                    disable
                                                                                    errorLabel={missingData && _.isEmpty(hourForm) ? "Este campo es obligatorio." : ""}
                                                                                    withError={missingData && _.isEmpty(hourForm)}
                                                                                />
                                                                        }
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </> :
                                                    currentStep === 1 ?
                                                        <TransferConfirmation
                                                            accDestDataForm={accDestDataForm}
                                                            accOriginData={accOriginData}
                                                            accDestData={accDestData}
                                                            isSqueduled={true}
                                                        />
                                                        :
                                                        currentStep === 2 ?
                                                            <div style={styles.divStep2}>
                                                                <div style={{ width: "80%" }}>
                                                                    <InfoCard
                                                                        title="¡Importante!"
                                                                        description="Le informamos que hemos recibido su solicitud y para proceder con su solicitud de
                                                        confirmación, se ha generado un token de confirmación único y seguro, que deberá
                                                        ingresar para completar el proceso."
                                                                        type="warning"
                                                                    />
                                                                    <Row gutter={[12, 12]} style={{ marginTop: 20 }}>
                                                                        <Col xs={24} sm={6} style={styles.colView}>
                                                                            <img
                                                                                src={image.tokenIcon}
                                                                                alt={"secureIcon"}
                                                                                style={styles.tokenIcon}
                                                                            />
                                                                        </Col>
                                                                        <Col xs={24} sm={16}>
                                                                            <h3 style={{ color: "#434343", paddingLeft: 10 }}>Ingrese Token</h3>
                                                                            <Input
                                                                                maxLength={6}
                                                                                value={tokenForm}
                                                                                label="Ingrese los 6 Dígitos de su Token"
                                                                                onChange={(elem) => handleChangeToken(elem)}
                                                                                type={"text"}
                                                                            />
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                            :
                                                            <TransferResume
                                                                isSqueduled={true}
                                                                accDestDataForm={accDestDataForm}
                                                                accOriginData={accOriginData}
                                                                accDestData={accDestData}
                                                                isEdit
                                                            />
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <GenericModal
                                showActionButtons
                                show={changeOriginAcc}
                                title="Cambiar Cuenta de Origen"
                                onClose={() => setChangeOriginAcc(false)}
                                onConfirm={() => {
                                    setCurrentOriginAccIndex(
                                        (ownAccountsList.findIndex((element) => element.accountNumber === accOriginTmp) > 0 ?
                                            ownAccountsList.findIndex((element) => element.accountNumber === accOriginTmp) : 0)
                                    );
                                    setChangeOriginAcc(false);
                                }}
                            >
                                <Row style={styles.viewRow}>
                                    <Col span={24}>
                                        <SelectInput
                                            label="Cuenta Origen"
                                            value={accOriginTmp}
                                            options={
                                                ownAccountsList.map((element) => ({
                                                    value: element.accountNumber,
                                                    label: `${element.accountNumber} | ${element.alias}`
                                                } as SelectOption))
                                            }
                                            onChange={(val) => setAccOriginTmp(val)}
                                        />
                                    </Col>
                                </Row>
                            </GenericModal>
                            <GenericModal
                                showActionButtons
                                show={changeDestAcc}
                                title="Cambiar Cuenta de Destino"
                                onClose={() => setChangeDestAcc(false)}
                                onConfirm={() => {
                                    setChangeDestAcc(false);
                                }}
                            >
                            </GenericModal>
                            <ModalNotification
                                title={"Editar Transferencia programada"}
                                message={message}
                                alertType={notificationType}
                                show={showNotification}
                                onClose={() => shouldNavigate ? navigateNotification() : setShowNotification(false)}
                            />
                        </>
                }
            </SubDefaultLayout >
        </>
    );
};

const styles = StyleSheet.create({
    rowRoot: {
        height: "100%",
        width: "100%",
        paddingBottom: 20
    },
    divContainer: {
        width: "100%",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        padding: "15px 20px 15px 20px",
        boxShadow: "3px 3px 5px 0px rgba(0,0,0,0.10)",
        height: "100%",
        borderRadius: 10,
    },
    inputContainer: {
        justifyContent: "flex-end",
    },
    viewRow: {
        padding: 20
    },
    logoIcon: {
        resizeMode: 'contain',
        width: 25,
    },
    tokenIcon: {
        resizeMode: 'contain',
        width: 55,
    },
    colView: {
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center'
    },
    divider: {
        borderWidth: 2,
        margin: "5px 0px 10px"
    },
    endCol: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    textTitle: {
        color: "#434343",
        marginBottom: 30,
        paddingLeft: 20
    },
    divStep2: {
        width: "100%",
        display: 'flex',
        justifyContent: 'center'
    },
    contentView: {
        width: "100%",
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    loading: {
        maxHeight: "none",
    },
});

export default EditTransferScheduler;
