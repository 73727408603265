import PerformedMain from "./historyPerformed/PerformedMain";
import { useState } from "react";
import { ComprobanteRequest, TransactionResume } from "../../../types/types";
import { printReceiptment } from "../../../methods/axiosMethods";
import SubDefaultLayout from "../../../components/SubDefaultLayout";
import { subMenusOfTransfers } from "../../../commons/subMenus";
import SelectInput, { SelectOption } from "../../../components/SelectInput";
import RejectedMain from "./historyRejected/RejectedMain";
import Buttons from "../../../components/Buttons";
import { useNavigate } from "react-router-dom";
import { image } from "../../../commons/ImageRoutes";
import { AlertTypeNew } from "../../../components/ModalNotification";

const HistoryMain = () => {
    const navigate = useNavigate();
    const [isLoadingPrint, setIsLoadingPrint] = useState(false);
    const [notificationType, setNotificationType] = useState<AlertTypeNew>("SUCCESS");
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [message, setMessage] = useState('');
    const options: SelectOption[] = [
        { label: "Todas las Transferencias", value: "all" },
        { label: "Programadas No Realizadas", value: "scheduler" },
    ];

    // Control
    const [selectedHistory, setSelectedHistory] = useState<string>("all");

    const printReceipt = (transaction: TransactionResume) => {
        setIsLoadingPrint(true)
        let receiptReq: ComprobanteRequest = {
            trackingKey: transaction.trackingKey,
            accountNumberReceiver: transaction.receiverAccountNumber,
            accountNumberSource: transaction.sourceAccountNumber
        }
        printReceiptment(receiptReq)
            .then((response) => {
                setIsLoadingPrint(false);
            })
            .catch(() => {
                setIsLoadingPrint(false);
                setNotificationType('ERROR');
                setMessage("Hubo un error al imprimir el Comprobante de Pago, intente más tarde..");
                setShowNotification(true);
            })
    };

    const selectHistoryType = () => (
        <SelectInput
            value={selectedHistory}
            label="Seleccione historial"
            onChange={(value) => setSelectedHistory(value)}
            options={options}
        />
    );

    const extraButton = () => (
        <Buttons
            title="Transferencia"
            action={() => navigate(selectedHistory === "all" ? "/transfers/immediate" : "/transfers/scheduled")}
            type="primary"
            imageIcon={image.transferImmIconSelected}
            color="#3d3dff"
        />
    );

    return (
        <SubDefaultLayout
            title="Historial"
            childrenSubMenu={subMenusOfTransfers}
            extraButton={extraButton()}
        >
            {selectedHistory === "all" ? (
                <PerformedMain
                    printReceipt={printReceipt}
                    selectHistoryType={selectHistoryType()}
                    loadingPrint={isLoadingPrint}
                    notificationMessage={message}
                    notificationType={notificationType}
                    notificationShown={showNotification}
                    closeNot={() => setShowNotification(false)}
                />
            ) : (
                <RejectedMain selectHistoryType={selectHistoryType()} />
            )}
        </SubDefaultLayout>
    );
};

export default HistoryMain;
