import { Col, Row, Spin, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CatalogBankResponse, Status, TransactionResume } from "../../../../types/types";
import { getBanks } from "../../../../methods/axiosMethods";
import { setInstitution } from "../../../../store/slices/institutions";
import _ from "lodash";
import GenericModal from "../../../../components/GenericModal";
import { StyleSheet } from "../../../../StyleSheet";
import { LoadingOutlined, PrinterOutlined } from "@ant-design/icons";
import ModalNotification, { AlertTypeNew } from "../../../../components/ModalNotification";
const Text = Typography;

//---------------------------------------------------------------------------------------------------------------------- Interfaces
interface DrawerMyAccountsProps {
    selectedRecord: TransactionResume | undefined;
    drawerVisible: boolean;
    onCloseDrawer: () => void;
    onClickPrint: Function;
    loadingPrint: boolean;
};

const formatAmount = Intl.NumberFormat('es-MX', {
    style: 'currency',
    currency: 'MXN',
    useGrouping: true,
    minimumFractionDigits: 2,
    notation: 'standard'
});

//---------------------------------------------------------------------------------------------------------------------- Functions
const labelChipcolor = (element: string | undefined) => {
    switch (element) {
        case 'DONE':
            return 'success';
        case 'REJECTED':
        case 'CANCELED':
        case 'CREATED':
            return 'error';
        case 'PROCESSING':
        case 'ACCEPTED':
            return 'processing';
        default: {
            return '';
        }
    }
};

const labelChipcolorAccountType = (element: string | undefined) => {
    switch (element) {
        case 'CLABE':
            return 'green';
        case 'CEL':
            return 'blue';
        case 'DEBIT':
            return 'magenta';
        default: {
            return '';
        }
    }
};

const labelChipAccountType = (element: string | undefined) => {
    switch (element) {
        case "CLABE":
            return "CLABE";
        case 'DEBIT':
            return "Tarjeta de Debito";
        case 'CEL':
            return "Teléfono celular";
        default: {
            return '';
        }
    }
};

const labelChip = (element: string | undefined) => {
    switch (element) {
        case 'DONE':
            return 'Realizada';
        case 'REJECTED':
        case 'CREATED':
            return 'Rechazada';
        case 'CANCELED':
            return 'Cancelada';
        case 'PROCESSING':
        case 'ACCEPTED':
            return 'En curso';
        default: {
            return '';
        }
    }
};

const labelDescription = (element: String | undefined) => {
    switch (element) {
        case 'ACCEPTED':
            return 'EN CURSO';
        case 'LIQUIDADO':
            return 'LIQUIDADO';
        default: {
            return '';
        }
    }
};

const ListItem = ({ title, value }: any) => (
    <Col span={24}>
        <Row>
            <Col span={24}>
                <span style={{ fontWeight: 'bold' }}>{`${title}`}</span>
            </Col>
            <Col span={24}>
                <span style={{ lineHeight: '25px' }}>{value}</span>
            </Col>
        </Row>
    </Col>
)

//---------------------------------------------------------------------------------------------------------------------- Component
const DrawerHistory = (props: DrawerMyAccountsProps) => {
    const { selectedRecord, drawerVisible, onCloseDrawer, onClickPrint, loadingPrint } = props;
    const dispatch = useDispatch();
    const banksList = useSelector((state: any) => state.institutions.institutionsInfo);
    const [, setBanks] = useState<CatalogBankResponse[]>([]);
    const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
    const [message, setMessage] = useState("");
    const [showNotification, setShowNotification] = useState<boolean>(false);
    const [notificationType, setNotificationType] = useState<AlertTypeNew>("SUCCESS");


    useEffect(() => {
        if (banksList && _.isEmpty(banksList)) {
            getBanks()
                .then((response) => {
                    if (response.data.status === Status.OK && response.data.data && !_.isEmpty(response.data.data)) {
                        dispatch(setInstitution({ institutionsInfo: response.data.data }))
                    }
                })
                .catch(() => {
                    setNotificationType('ERROR');
                    setMessage("Hubo un error al obtener la lista Instituciones, intente más tarde...");
                    setShowNotification(true);
                });
        } else {
            setBanks(banksList);
        }
    }, [banksList, dispatch]);

    return (
        <>
            <GenericModal
                title="Más Detalles"
                show={drawerVisible}
                onClose={onCloseDrawer}
            >
                {loadingPrint ? (
                    <div style={styles.contentView}>
                        <Spin
                            indicator={antIcon}
                            tip="Cargando..."
                            style={styles.loading} />
                    </div>
                ) : (
                    <Row style={{ padding: "40px 20px 40px 20px" }}>
                        <Col span={16}>
                            <Row gutter={[0, 10]}>
                                <ListItem title="Referencia" value={selectedRecord?.reference} />
                                <ListItem title="Fecha y Hora" value={selectedRecord?.date} />
                                <ListItem title="IFPE Emisor" value={"STP"} />
                                <ListItem title="Cuenta Emisor" value={selectedRecord?.sourceAccountNumber} />
                                <ListItem title="Entidad Receptor" value={selectedRecord?.receiverBank} />
                                <ListItem title="Cuenta Receptor" value={selectedRecord?.receiverAccountNumber} />
                                <ListItem title="Clave de Rastreo" value={selectedRecord?.trackingKey} />
                                {(selectedRecord?.incoming) ? (
                                    <ListItem title="Monto" value={formatAmount.format(selectedRecord ? selectedRecord.incoming : 0)} />
                                ) : (
                                    <ListItem title="Monto" value={formatAmount.format(selectedRecord ? selectedRecord.outcoming : 0)} />
                                )}
                                <ListItem title="IVA" value={formatAmount.format(selectedRecord ? selectedRecord.iva : 0)} />
                                {(selectedRecord?.incoming) ? (
                                    <ListItem title="Total" value={formatAmount.format(selectedRecord ? selectedRecord.incoming + selectedRecord.iva : 0)} />
                                ) : (
                                    <ListItem title="Total" value={formatAmount.format(selectedRecord ? selectedRecord.outcoming + selectedRecord.iva : 0)} />
                                )}
                                <ListItem title="Usuario" value={selectedRecord?.username} />
                                <div style={styles.divTags}>
                                    <Tag
                                        style={{ fontSize: 14 }}
                                        color={labelChipcolor(selectedRecord?.status)}
                                    >
                                        {labelChip(selectedRecord?.status)}
                                    </Tag>
                                    <Tag
                                        style={{ fontSize: 14 }}
                                        color={labelChipcolorAccountType(selectedRecord?.accountType)}
                                    >
                                        {labelChipAccountType(selectedRecord?.accountType)}
                                    </Tag>
                                </div>
                                {!_.isEmpty(selectedRecord?.description) && (
                                    <ListItem title="Descripción" value={labelDescription(selectedRecord?.description)} />
                                )}
                            </Row>
                        </Col>
                        <Col span={8}>
                            <Row>
                                <Col span={24}>
                                    <div style={styles.buttonContiner} onClick={() => onClickPrint()}>
                                        <div style={styles.iconContainer}>
                                            <PrinterOutlined style={styles.iconStyle} />
                                        </div>
                                        <Text>
                                            Imprimir
                                        </Text>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </GenericModal>
            <ModalNotification
                title={"Historial"}
                message={message}
                alertType={notificationType}
                show={showNotification}
                onClose={() => setShowNotification(false)} />
        </>
    );
};

//---------------------------------------------------------------------------------------------------------------------- Styles
const styles = StyleSheet.create({
    buttonContiner: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    iconContainer: {
        display: "flex",
        width: 80,
        height: 80,
        marginBottom: 20,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50%",
        backgroundColor: "#ebf9fc",
        cursor: "pointer",
    },
    iconStyle: {
        fontSize: 25,
        color: "#44c9e6",
    },
    divTags: {
        display: 'flex',
        flexDirection: 'row',
        flexFlow: 'wrap'
    },
    contentView: {
        width: "100%",
        height: "50vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    loading: {
        maxHeight: "none",
    },
});

//---------------------------------------------------------------------------------------------------------------------- Exports
export default DrawerHistory;
