import MyAccounts from "../views/admin/myAccounts/MyAccountsMain";
import MyAccountsEdit from "../views/admin/myAccounts/MyAccountsEdit";
import MyAccountsDetails from "../views/admin/myAccounts/MyAccountsDetails";
import AccountsThird from "../views/admin/accountsThirds/AccountsThirdMain";
import UserAdd from "../views/settings/users/UserAdd";
import UserEdit from "../views/settings/users/UserEdit";
import Login from "../views/login/Login";
import AccountsThirdAdd from "../views/admin/accountsThirds/AccountsThirdAdd";
import AccountsGroupMain from "../views/admin/accountsGroups/AccountsGroupMain";
import AccountsGroupAdd from "../views/admin/accountsGroups/AccountsGroupAdd";
import AccountsGroupEdit from "../views/admin/accountsGroups/AccountsGroupEdit";
import AccountsThirdEdit from "../views/admin/accountsThirds/AccountsThirdEdit";
import SchedulerMain from "../views/transfers/scheduler/SchedulerMain";
import HistoryMain from "../views/transfers/history/HistoryMain";
import TransferMain from "../views/transfers/immediate/TransferMain";
import TransferImportedMain from "../views/transfers/immediate/TransferImportedMain";
import SchedulerImportedMain from "../views/transfers/scheduler/SchedulerImportedMain";
import EditTransferScheduler from "../views/transfers/scheduler/Edit/EditTransferScheduler";
import NotificationsHome from "../views/notifications/NotificationsHome";
import Dashboard from "../views/home/Dashboard";
import StadisticsHome from "../views/stadistics/StadisticsHome";
import UsersTabContent from "../views/settings/users/UsersTabContent";

type route = {
  path: string;
  element: JSX.Element;
  breadcrumb: string;
};

const routes: Array<route> = [
  // Admin
  {
    path: "/login",
    element: <Login />,
    breadcrumb: "Inicio de sesion"
  },
  {
    path: "/",
    element: <Dashboard />,
    breadcrumb: ""
  },
  {
    path: "/admin",
    element: <MyAccounts />,
    breadcrumb: "Cuentas"
  },
  {
    path: "/admin/accounts",
    element: <MyAccounts />,
    breadcrumb: "Mis Cuentas"
  },
  {
    path: "/admin/accounts/:accountNumber",
    element: <MyAccountsEdit />,
    breadcrumb: "Editar"
  },
  {
    path: "/admin/accounts/details",
    element: <MyAccounts />,
    breadcrumb: "Detalle"
  },
  {
    path: "/admin/accounts/details/:accountNumber",
    element: <MyAccountsDetails />,
    breadcrumb: ""
  },
  {
    path: "/admin/accountsThird",
    element: <AccountsThird />,
    breadcrumb: "Cuentas de Terceros"
  },
  {
    path: "/admin/accountsThird/add-account",
    element: <AccountsThirdAdd />,
    breadcrumb: "Agregar Cuenta"
  },
  {
    path: "/admin/accountsThird/:accountThirdId",
    element: <AccountsThirdEdit />,
    breadcrumb: "Editar"
  },
  {
    path: "/admin/accountsGroups",
    element: <AccountsGroupMain />,
    breadcrumb: "Grupos de Cuentas"
  },
  {
    path: "/admin/accountsGroups/add-group",
    element: <AccountsGroupAdd />,
    breadcrumb: "Agregar Grupo"
  },
  {
    path: "/admin/accountsGroups/:groupId",
    element: <AccountsGroupEdit />,
    breadcrumb: "Editar"
  },
  {
    path: "/settings",
    element: <UsersTabContent />,
    breadcrumb: "Configuración"
  },
  {
    path: "/settings/add-user",
    element: <UserAdd />,
    breadcrumb: "Agregar Usuario"
  },
  {
    path: "/settings/:userId",
    element: <UserEdit />,
    breadcrumb: "Editar Usuario"
  },
  // Tranferencias
  {
    path: "/transfers",
    element: <HistoryMain />,
    breadcrumb: "Transferencias"
  },
  {
    path: "/transfers/immediate",
    element: <TransferMain />,
    breadcrumb: "Inmediatas"
  },
  {
    path: "/transfers/immediate-imported",
    element: <TransferImportedMain />,
    breadcrumb: "Importar transferencias"
  },
  {
    path: "/transfers/scheduled-imported",
    element: <SchedulerImportedMain />,
    breadcrumb: "Importar transferencias programadas"
  },
  {
    path: "/transfers/scheduled",
    element: <SchedulerMain />,
    breadcrumb: "Programadas"
  },
  {
    path: "/transfers/scheduled/:transferId",
    element: <EditTransferScheduler />,
    breadcrumb: "Editar"
  },
  {
    path: "/transfers/scheduled/new",
    element: <TransferMain />,
    breadcrumb: "Programar Nueva"
  },
  {
    path: "/transfers/history",
    element: <HistoryMain />,
    breadcrumb: "Historial"
  },
  // Notification
  {
    path: "/notifications",
    element: <NotificationsHome />,
    breadcrumb: "Notificaciones"
  },
  // Estadiísticas
  {
    path: "/stadistics",
    element: <StadisticsHome />,
    breadcrumb: "Estadísticas"
  },
];

export default routes;