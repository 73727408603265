import { Card, Col, Row, Typography } from 'antd';
import { StyleSheet } from "../StyleSheet";
import { InfoCircleOutlined } from '@ant-design/icons';
const { Text } = Typography;

interface InfoCardProps {
    title?: string;
    description?: string;
    type?: "primary" | "warning" | undefined;
}

const InfoCard = (props: InfoCardProps) => {
    const { title, description, type } = props;

    return (
        <Card style={{ ...styles.cardRoot, backgroundColor: (type === "primary" ? "#eaeaff" : "#fff3c4") }} bodyStyle={styles.cardBody}>
            <Row gutter={[12, 12]} align="middle">
                <Col xs={2} sm={1}>
                    <div style={{ ...styles.divColor, backgroundColor: (type === "primary" ? "#3d3dff" : "#ffcc00") }} />
                </Col>
                <Col xs={2} sm={1} style={{ display: 'flex', justifyContent: 'center' }}>
                    <InfoCircleOutlined style={{ fontSize: 30, color: (type === "primary" ? "#111192" : "#8c740e") }} />
                </Col>
                <Col xs={20} sm={22}>
                    <div style={{ marginLeft: 15 }}>
                        {
                            title ? (
                                <div>
                                    <Text style={{ fontSize: 16, fontWeight: 600, color: (type === "primary" ? "#111191" : "#8c740e") }}>
                                        {title}
                                    </Text>
                                </div>
                            ) : null
                        }
                        <div>
                            <Text style={{ fontSize: 15, color: (type === "primary" ? "#111191" : "#8c740e") }}>
                                {description}
                            </Text>
                        </div>
                    </div>
                </Col>
            </Row>
        </Card>
    );
};

const styles = StyleSheet.create({
    cardRoot: {
        marginTop: '15px',
        width: '100%',
        boxShadow: "0px 0px 8px #00000029",
        borderRadius: 12,
        padding: 5,
        display: 'flex',
    },
    cardBody: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0
    },
    divColor: {
        height: 65,
        width: 8,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8
    }
});

export default InfoCard;
