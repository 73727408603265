import { Row, Col, Typography, Card, Spin, Checkbox } from "antd";
import { StyleSheet } from "../../../StyleSheet";
import 'moment/locale/es';
import { image } from "../../../commons/ImageRoutes";
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { useNavigate } from "react-router-dom";
import { AccountDest, AccountOrigin, ComprobanteRequest, TransferObj } from "../../../types/types";
import CardDataTransfer from "../../../components/CardDataTransfer";
import CardDataTransferConfirm from "../../../components/CardDataTransferConfirm";
import { useCallback, useEffect, useState } from "react";
import moment from "moment";
import dayjs from 'dayjs';
import { getLinkReceiptment } from "../../../methods/axiosMethods";
import { LoadingOutlined, SaveOutlined } from "@ant-design/icons";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import _ from "lodash";
import Input from "../../../components/Input";
import Buttons from "../../../components/Buttons";
const { Text } = Typography;

interface Props {
    trackingKey?: string;
    isSqueduled?: boolean;
    accOriginData: AccountOrigin;
    accDestData: AccountDest;
    accDestDataForm: TransferObj;
    transferStatus?: string;
    saveThirdAcc?: Function;
    printReceipt?: () => void;
    thirdAccAdded?: boolean;
    isEdit?: boolean;
}

const TransferResume = (props: Props) => {
    const { trackingKey, isSqueduled, accDestData, accDestDataForm, thirdAccAdded,
        accOriginData, printReceipt, transferStatus, saveThirdAcc, isEdit
    } = props;
    const navigate = useNavigate();
    const [dateDay, setDateDay] = useState("");
    const [dateHour, setDateHour] = useState("");
    const [dateTime, setDateTime] = useState("");
    const fontColor = "#434343";
    const backGrSqued = "#f5e5fc";
    const backGrDefault = "#ebebff";
    const colorSqued = "#9900e3";
    const colorDefault = "#3d3dff";
    const [receiptLink, setReceiptLink] = useState("");
    const [checked, setChecked] = useState(false);
    const [aliasForm, setAliasForm] = useState('');
    const zoomPluginInstance = zoomPlugin();
    const [isLoading, setIsLoading] = useState(false);
    const [triedToGetReceipt, setTriedToGetReceipt] = useState(false);
    const { ZoomInButton, ZoomOutButton, ZoomPopover, /*Zoom*/ } = zoomPluginInstance;

    const getReceipt = useCallback(async () => {
        let receiptReq: ComprobanteRequest = {
            trackingKey: trackingKey ? trackingKey : "",
            accountNumberReceiver: accDestData.accountNumber,
            accountNumberSource: accDestData.accountNumber
        }
        setIsLoading(true);
        await getLinkReceiptment(receiptReq)
            .then((response) => {
                setReceiptLink(response);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            })
        setTriedToGetReceipt(true);
    }, [accDestData, trackingKey]);

    const onChange = (e: CheckboxChangeEvent) => {
        setChecked(e.target.checked);
    };

    useEffect(() => {
        let date = moment(accDestDataForm.dateTimeDest).format("LL").toString();
        let dateDay = moment(accDestDataForm.dateTimeDest).format("dddd").toString();
        let dateHour = dayjs(accDestDataForm.dateTimeDest?.toString()).format("hh:mm a");
        setDateDay(dateDay);
        setDateHour(dateHour);
        setDateTime(date);
        // triedToGetReceipt variable booleana para comprobar si ya se intentó descargar
        // una vez el recibo
        if (!isSqueduled && !triedToGetReceipt && (transferStatus === "DONE" || transferStatus === "PROCESSING")) {
            getReceipt();
        }
    }, [accDestData, getReceipt, isSqueduled, triedToGetReceipt,
        accDestDataForm.dateTimeDest, transferStatus
    ])

    return (
        <>
            <Row gutter={[16, 12]}>
                <Col xs={24} sm={18}>
                    <div style={{
                        ...styles.confirmationRowRoot,
                        backgroundColor: isSqueduled ? backGrSqued : backGrDefault,
                    }}>
                        <Row align={"middle"} gutter={[12, 12]}>
                            <Col xs={24} md={10} lg={13}>
                                <Text style={{
                                    ...styles.cardRootText,
                                    color: isSqueduled ? colorSqued : colorDefault
                                }}>
                                    {
                                        isSqueduled ?
                                            "Transferencia Programada"
                                            : "Transferencia en curso"
                                    }
                                </Text>
                            </Col>
                            {
                                !isSqueduled ?
                                    <Col xs={24} md={14} lg={11}>
                                        <div style={styles.cardSecondText}>
                                            <Text style={{ color: colorDefault }}>
                                                Clave de Rastreo
                                            </Text>
                                            <Text style={{ ...styles.cardRootText, color: colorDefault }}>
                                                {trackingKey}
                                            </Text>
                                        </div>
                                    </Col>
                                    : null
                            }
                        </Row>
                    </div>
                    {
                        isSqueduled ?
                            <Row gutter={[12, 12]} justify={"center"}>
                                <Col xs={24} sm={12}>
                                    <CardDataTransfer
                                        accountName={accOriginData.accountName}
                                        accountNumber={accOriginData.accountNumber}
                                        backgroundColor={"#eaeaff"}
                                        fontColor={"#000089"}
                                        cardType="origin"
                                    />
                                </Col>
                                <Col xs={24} sm={12} style={{ marginBottom: 15 }}>
                                    <CardDataTransferConfirm
                                        title="Cuenta Destino"
                                        backgroundColor={"#fffeea"}
                                        fontColor={fontColor}
                                        accDestDataForm={accDestDataForm}
                                        accDestData={accDestData}
                                        showFooterDate={false}
                                    />
                                </Col>
                            </Row>
                            :
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                {
                                    isLoading ?
                                        <div
                                            style={{
                                                alignItems: 'center',
                                                //border: '2px dashed rgba(0, 0, 0, .3)',
                                                display: 'flex',
                                                fontSize: '2rem',
                                                height: '100%',
                                                justifyContent: 'center',
                                                width: '100%',
                                            }}
                                        >
                                            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                                        </div>
                                        :
                                        receiptLink ?
                                            <div
                                                className="rpv-core__viewer"
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        alignItems: 'center',
                                                        borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        padding: '4px',
                                                    }}
                                                >
                                                    <div style={{ padding: '0px 2px' }}>
                                                        <ZoomOutButton />
                                                    </div>
                                                    <div style={{ padding: '0px 2px' }}>
                                                        <ZoomPopover />
                                                    </div>
                                                    <div style={{ padding: '0px 2px' }}>
                                                        <ZoomInButton />
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <Viewer fileUrl={receiptLink} plugins={[zoomPluginInstance]} />
                                                </div>
                                            </div>
                                            : null
                                }
                            </Worker>
                    }
                </Col>
                <Col xs={24} sm={6}>
                    <div style={styles.divView}>
                        {
                            isSqueduled ?
                                <>
                                    <Card style={styles.cardDate} bodyStyle={styles.cardBody}>
                                        <div style={styles.divRow}>
                                            <Text style={styles.textDateTitle}>
                                                Fecha
                                            </Text>
                                            <Text style={styles.textDateData}>
                                                {dateTime}
                                            </Text>
                                            <Text style={styles.textDateDay}>
                                                {dateDay}
                                            </Text>
                                        </div>
                                    </Card>
                                    <Card style={{ ...styles.cardDate, minHeight: 0 }} bodyStyle={styles.cardBody}>
                                        <div style={styles.divRow}>
                                            <Text style={styles.textDateTitle}>
                                                Hora
                                            </Text>
                                            <Text style={styles.textDescData}>
                                                {dateHour}
                                            </Text>
                                        </div>
                                    </Card>
                                </>
                                : null
                        }
                        <h3 style={{ ...styles.textH3, color: fontColor }}>Acciones rápidas</h3>
                        <img
                            src={image.transferCircleIcon}
                            alt={"transferCircleIcon"}
                            style={styles.icon}
                            onClick={() =>
                                isSqueduled ?
                                    navigate('/transfers/scheduled')
                                    : navigate('/transfers/history')
                            }
                        />
                        <h3 style={{ ...styles.textH3Sub, color: fontColor }}>Transferencia</h3>
                        {
                            !isSqueduled && printReceipt && (transferStatus === "DONE" || transferStatus === "PROCESSING") ?
                                <>
                                    <img
                                        src={image.printCircleIcon}
                                        alt={"printCircleIcon"}
                                        style={styles.icon}
                                        onClick={() => printReceipt()}
                                    />
                                    <h3 style={{ ...styles.textH3Sub, color: fontColor }}>Imprimir</h3>
                                </>
                                : null
                        }
                        {
                            // solo dará la opción de guardar, si la transferencia fue con la opcion de otras cuentas y si no se agregó ya la cuenta
                            accDestData.radioOpt === 3 && !thirdAccAdded && !isEdit ?
                                <>
                                    <Checkbox checked={checked} onChange={onChange} />
                                    <h3 style={{ ...styles.textH3SaveThirdAcc, color: fontColor, textAlign: 'center' }}>
                                        Guardar cuenta en Cuentas de Terceros
                                    </h3>
                                    {
                                        checked ?
                                            <>
                                                <Input
                                                    value={aliasForm}
                                                    label="Alias*"
                                                    required
                                                    onChange={(element) => setAliasForm(element)}
                                                    type="text"
                                                    errorLabel={_.isEmpty(aliasForm) ? "Este campo es obligatorio." : ""}
                                                />
                                                <Buttons
                                                    type={"primary"}
                                                    title={"Guardar"}
                                                    action={() => saveThirdAcc ? saveThirdAcc(aliasForm) : undefined}
                                                    color="#3d3dff"
                                                    icon={<SaveOutlined />}
                                                    isDisabled={_.isEmpty(aliasForm)}
                                                />
                                            </>
                                            : null
                                    }
                                </>
                                : null}
                    </div>
                </Col>
            </Row>
        </>
    );
};

const styles = StyleSheet.create({
    confirmationRowRoot: {
        background: '#ebebff',
        borderRadius: 10,
        marginBottom: 15,
        padding: 15
    },
    cardSecondText: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
    },
    cardRootText: {
        fontWeight: 'bold',
        fontSize: 17
    },
    icon: {
        resizeMode: 'contain',
        width: 55,
        cursor: 'pointer'
    },
    textH3: {
        marginBottom: 50,
        textAlign: 'center'
    },
    textH3Sub: {
        fontSize: 12,
        marginBottom: 50
    },
    textH3SaveThirdAcc: {
        fontSize: 12,
    },
    cardDate: {
        width: '100%',
        borderRadius: 12,
        padding: 5,
        minHeight: 100,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#f5e5fc',
        marginBottom: 15
    },
    divRow: {
        display: 'flex',
        flexDirection: 'column'
    },
    textDateTitle: {
        fontSize: 15,
        color: "#9900e3"
    },
    textDateData: {
        fontSize: 16,
        fontWeight: 'bold',
        color: "#9900e3"
    },
    textDateDay: {
        fontSize: 12,
        textTransform: 'capitalize',
        color: "#9900e3"
    },
    textDescData: {
        fontSize: 14,
        fontWeight: 'bold',
        color: "#9900e3"
    },
    cardBody: {
        padding: "10px 0px 10px 15px"
    },
    divView: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
});

export default TransferResume;
